import React from "react";
import { useState, forwardRef, useImperativeHandle } from "react";

import "./style.css";
import Alert_circle from "../../assets/icons/Alert_circle";
import Alert_error from "../../assets/icons/Alert_error";
import Alert_success from "../../assets/icons/Alert_success";
import Fermer_icon from "../../assets/icons/Fermer_icon";

const Alert = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen((open) => false);
  };

  useImperativeHandle(ref, () => ({
    show() {
      setOpen((open) => true);
      setTimeout(() => {
        setOpen((open) => false);
      }, props?.time ? props.time : 5000);
    },
  }));

  return (
    <div
      className="alert"
      //   id={isVisible ? "hidden" : "visible"}
      id={open ? "visible" : "hidden"}
      ref={ref}
      onClick={hide}
    >
      <div
        className="alert_container"
        style={{
          borderColor: `${
            props.type === "error"
              ? "#CE4D5D"
              : props.type === "success"
              ? "#21A779"
              : "#EFA82E"
          }`,
        }}
      >
        {props.type === "error" ? (
          <Alert_error />
        ) : props.type === "success" ? (
          <Alert_success />
        ) : (
          <Alert_circle />
        )}

        <p className="alert_message">{props.message}</p>
        <Fermer_icon hide={hide} />
      </div>
    </div>
  );
});

export default Alert;
