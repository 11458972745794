import React from "react";
import "./styles.css";
import logo from "../../assets/images/primary-logo.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="col-1">
          <img src={logo} alt="footer-logo" />
          <p className="about">
            Cars d'Heure vous propose un vaste de professionnels vous permettant
            d'effectuer les réparations et entretients sur votre véhicule, prés
            de chez vous et aux meilleurs prix ! En quelques clics, vous
            disposez de toutes les informations nécessaires sur nos
            établissements partenaires, ainsi qu'un rdv dans les plus brefs
            délais. Il vous suffit juste de choisir vos prestations et validez
            votre RDV. Nos équipes se tiennent a disposition pour répondre.
            <br></br>
            Avec Cars d'Heure c'est la proximité pour un entretient a petit prix
            mais toujours de qualité !
          </p>
        </div>

        <div className="col-2">
          <p className="title">A propos de Cars d'Heure</p>
          <ul>
            <li>
              <a href="#!">Qui sommes-nous?</a>
            </li>
            <li>
              <a href="#!">Rejoignez-nous</a>
            </li>
            <li>
              <a title="Prestations" href="/search">
                Les prestations
              </a>
            </li>
            <li>
              <a
                title="FAQ - Rendez-vous chez le Garagiste en Ligne"
                href="/faq"
                className="help"
              >
                Besoin d'aide ?
              </a>
            </li>
          </ul>
        </div>

        <div className="col-3">
          <p className="title">Contact</p>
          <ul>
            <li>
              <a href="tel:+33164328888">+33 6 68 78 45 88</a>
            </li>
            <li>
              <a href="mailto:contact@carsdheure.fr">contact@carsdheure.fr</a>
            </li>
            <li>Bezons 95, Paris, La France</li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          Copyright ©{new Date().getFullYear()}{" "}
          <a
            title="Cars d'Heure Réservez un rendez vous chez un professionnel de l'automobile"
            href="/"
          >
            CARS D'HEURE
          </a>{" "}
          - Tous droits réservés
        </p>
        <div className="links">
          <a
            href="/cgu"
            title="Mentions légales et Conditions Générales d'Utilisation"
          >
            Mentions légales et Conditions
          </a>
          <span>       </span>
          <a
            title="Politique de Protection des Données Personnelles"
            href="/politique-de-protection-des-donnees-personnelles"
          >
            Politique de confidentialité
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
