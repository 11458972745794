import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import Footer from "../../../components/Footer";

function Cgv() {
  const navigate = useNavigate();

  return (
    <div>
      <VisitorNavBar />

      <div className="cgv-container">
        <h1 className="text-center mb-40">
          Politique de Protection des Données Personnelles
        </h1>
        <h3>
          Date de la dernière mise à jour : 04/12/2023
          <br /> La présente Politique de Protection des Données Personnelles
          décrit comment Cars d'Heure (ci-après "nous", "notre", ou "nos")
          collecte, utilise et protège les données personnelles que vous
          fournissez lorsque vous utilisez nos services. Votre vie privée est
          d'une importance primordiale pour nous, et nous nous engageons à
          protéger et à respecter vos données personnelles conformément aux lois
          et réglementations applicables.
        </h3>
        <h2>Collecte des Données Personnelles</h2>
        <p>
          Nous collectons des données personnelles lorsque vous utilisez nos
          services. Ces données peuvent inclure, mais ne sont pas limitées à :
        </p>
        <div>
          <ul>
            <li>
              Informations d'identification (nom, prénom, adresse, numéro de
              téléphone, adresse e-mail, etc.).{" "}
            </li>
            <li>
              Informations de paiement (détails de carte de crédit ou de débit,
              informations de compte bancaire, etc.).{" "}
            </li>
            <li>
              Informations sur votre véhicule (modèle, année, immatriculation,
              etc.).
            </li>
            <li>
              Informations sur les réservations de rendez-vous et les services
              demandés.
            </li>
          </ul>
        </div>
        <h2>Utilisation des Données Personnelles</h2>
        <p>
          Nous utilisons les données personnelles que nous collectons dans les
          buts suivants :
        </p>
        <div>
          <ul>
            <li>
              Pour faciliter la réservation de rendez-vous chez nos garagistes
              partenaires.
            </li>
            <li>
              Pour traiter les paiements et fournir des facilités de paiement si
              vous le souhaitez.
            </li>
            <li>
              Pour communiquer avec vous, y compris pour confirmer les
              rendez-vous et vous envoyer des notifications.
            </li>
            <li>
              Pour répondre à vos demandes et vous fournir un service client de
              qualité.
            </li>
            <li>
              Pour améliorer nos services et personnaliser votre expérience.
            </li>
          </ul>
        </div>
        <h2>Partage des Données Personnelles</h2>
        <p>
          Nous ne partageons pas vos données personnelles avec des tiers, sauf
          dans les cas suivants :
        </p>
        <div>
          <ul>
            <li>
              Avec nos garagistes partenaires afin de faciliter les rendez-vous
              et les services demandés.
            </li>
            <li>
              Avec les fournisseurs de services de paiement pour le traitement
              des paiements en ligne.
            </li>
            <li>
              Lorsque requis par la loi ou lorsque nous croyons de bonne foi que
              cela est nécessaire pour protéger nos droits ou répondre à une
              demande officielle.
            </li>
          </ul>
        </div>
        <h2>Sécurité des Données Personnelles</h2>
        <p>
          Nous mettons en place des mesures de sécurité appropriées pour
          protéger vos données personnelles contre tout accès non autorisé,
          divulgation, altération ou destruction. Nous conservons vos données
          personnelles pendant la durée nécessaire pour atteindre les finalités
          pour lesquelles elles ont été collectées.
        </p>
        <h2>Vos Droits</h2>
        <p>Vous avez le droit de :</p>
        <div>
          <ul>
            <li>Accéder à vos données personnelles.</li>
            <li>Rectifier des données inexactes.</li>
            <li>Demander la suppression de vos données.</li>
            <li>Vous opposer au traitement de vos données.</li>
            <li>Demander la limitation du traitement de vos données.</li>
            <li>Exercer la portabilité de vos données.</li>
          </ul>
        </div>
        <p>
          Pour exercer vos droits ou poser des questions sur notre Politique de
          Protection des Données Personnelles, veuillez nous contacter à
          contact@carsdheure.fr.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Cgv;
