import React, { useEffect, useState, useRef } from "react";

import "./styles.css";
import DashboardHeader from "../../components/Headers/Driver";
import PictureIcon from "../../assets/icons/upload-image.svg";
import LocationIcon from "../../assets/icons/location-black.svg";
import UserIcon from "../../assets/images/portrait-homme-blanc-isole.jpg";

import api from "../../../infrastructure/services/api";

import {
  validateName,
  validateEmail,
  validatePhoneNumber,
} from "../../utils/input-verification";
import Alert from "../../components/Alert";

function Profile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [logo, setLogo] = useState(null);
  const [logoURL, setLogoURL] = useState(UserIcon);
  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  useEffect(() => {
    const response = api.getProfile();
    response.then((response) => {
      setFirstName(response.data.data.firstName);
      setLastName(response.data.data.lastName);
      setEmail(response.data.data.local.email);
      setPhoneNumber(response.data.data.phoneNumber);
      setAddress(response.data.data.address);
    });
  }, []);

  // Update Profile
  const __updateProfile = () => {
    if (
      !validateName(firstName) ||
      !validateName(lastName) ||
      !validatePhoneNumber(phoneNumber) ||
      address == ""

    ) {
      // alert('Merci de vérifier vos informations');
      setAlert_message((message) => "Merci de vérifier vos informations");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const response = api.updateProfile({
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        address: address,
      });
      response.then((response) => {
        if (response.data.success) {
          // alert('votre profil a été modifié avec succés !');
          setAlert_message(
            (message) => "votre profil a été modifié avec succés !"
          );
          setAlert_type((alert_type) => "success");
          alertRef.current.show();
        }
      });
    }
  };useEffect(() => {
    console.log(logo)
  }, [logo]);



  return (
    <div className="dashboard-content">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <DashboardHeader />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Mes infos</h2>
        </div>

        <div className="dashboard-content-body">
          <div className="form-row" style={{ marginBottom: "0px" }}>
            <div className="form-column">
              <div className="form-row">
                <p className="strong">Prénom</p>
                <input
                  type="text"
                  value={firstName}
                  placeholder="Prénom"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Nom</p>
                <input
                  type="text"
                  value={lastName}
                  placeholder="Nom de la famille"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-row" style={{ marginBottom: "0px" }}>
            <div className="form-column">
              <div className="form-row relative">
                <p className="strong">Adresse</p>
                <input
                  className="with-icon"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <img style={{ top: "49%", left: "3px" }} src={LocationIcon} />
              </div>
            </div>
          </div>

          <div className="form-row" style={{ marginBottom: "0px" }}>
            <div className="form-column">
              <div className="form-row">
                <p className="strong">Adresse e-mail</p>
                <input
                  readOnly
                  type="email"
                  value={email}
                  placeholder="Exp: john.doe@carsdheure.fr"
                  disabled
                />
              </div>

              <div className="form-row">
                <p className="strong">Numéro de téléphone</p>
                <input
                  type="text"
                  value={phoneNumber}
                  placeholder="Exp: +33 6 33 33 33 33"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="form-row center" style={{ margin: "20px 0" }}>
            <button className="btn" onClick={() => __updateProfile()}>
              Envoyer
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Profile;
