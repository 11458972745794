import React, { useState, useEffect, useMemo, useRef } from "react";

import api from "../../../../infrastructure/services/api";
import { ApiConfig } from "../../../../infrastructure/services/env";
import "./styles.css";
import DashboardHeader from "../../../components/Headers/AdminDashboardHeader";
import DataTable from "react-data-table-component";
import moment from "moment";
import styled from "styled-components";
import Alert from "../../../components/Alert";

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

function Orders() {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [alert_message, setAlert_message] = useState("");
  const [alert_type, setAlert_type] = useState("");
  const alertRef = useRef(null);

  const CustomBusiness = ({ business }) => (
    <div style={{ padding: "15px 0" }}>
      <div>{business.business.workshop_name}</div>
      <div>{business.firstName}</div>
      <div>{business.lastName}</div>
      <div>{business.business.business_infos.phoneNumber}</div>
      <div>{business.business.workshop_address.label}</div>
    </div>
  );

  const CustomPiece = ({ pieces, price }) => {
    return (
      <div>
        <div>
          {pieces.map((piece, i) => {
            return (
              <div
                style={{
                  borderBottom:
                    i + 1 < pieces.length
                      ? "2px solid rgb(213, 213, 213)"
                      : "unset",
                  padding: "20px 0",
                }}
                key={i}
              >
                <div>{piece.pieceRef + " - " + piece.pieceLib}</div>
                <div>
                  <strong>
                    {piece.status === "PROGRESS" ? (
                      <span style={{ color: "orange" }}>EN COURS</span>
                    ) : piece.status === "CONFIRMED" ? (
                      <span style={{ color: "#2cff00" }}>CONFIRMÉ</span>
                    ) : (
                      <span style={{ color: "green" }}>TERMINÉ</span>
                    )}
                  </strong>
                </div>
                {piece.status === "PROGRESS" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "15px",
                    }}
                  >
                    <div>
                      <button
                        onClick={() => {
                          __confirmPiece(piece._id);
                        }}
                        className="success"
                        style={{
                          color: "#2cff00",
                          borderColor: "#2cff00",
                          backgroundColor: "#fff",
                        }}
                      >
                        CONFIRMER
                      </button>
                    </div>
                  </div>
                ) : piece.status === "CONFIRMED" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "15px",
                    }}
                  >
                    <div>
                      <button
                        onClick={() => {
                          __donePiece(piece._id);
                        }}
                        className="success"
                        style={{
                          backgroundColor: "#fff",
                        }}
                      >
                        Terminer
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
        <div>
          <strong>Total: {price} €</strong>
        </div>
      </div>
    );
  };

  const CustomOption = ({ info }) => (
    <div style={{ padding: "20px 0" }}>
      <div>
        {info.prestations.map((prestation, index) => {
          return (
            <div key={index}>
              <strong>{index + 1} -</strong> {prestation.title}
            </div>
          );
        })}
      </div>
      <div>
        <strong>
          Total: {(parseFloat(info.service_price) / 100).toFixed(2)} €
        </strong>
      </div>
      <hr />
      <div>{info.vehicule.NAME}</div>
      <div>{info.vehicule.PLATE}</div>
      <hr />
      <div>{info.personal.firstName + " " + info.personal.lastName}</div>
      <hr />
      <div>
        <strong>Statut : </strong>
        {info.status === "UNCONFIRMED" ? (
          <span style={{ color: "orange" }}>A confirmé</span>
        ) : info.status === "CONFIRMED" ? (
          <span className="success-text">Confirmé</span>
        ) : info.status === "CANCELED" ? (
          <span className="danger-text">Annulé</span>
        ) : (
          <span className="success-text">Terminer</span>
        )}
      </div>
    </div>
  );

  const columns = [
    {
      name: "N°",
      selector: (row) => row.number,
      sortable: true,
      wrap: true,
      width: "70px",
    },
    {
      name: "Atelier",
      selector: (row) => row.business.business.workshop_name,
      cell: (row) => <CustomBusiness business={row.business} />,
      wrap: true,
      width: "150px",
      sortable: true,
    },
    {
      name: "Pièce",
      selector: (row) => row.pieceOrders.pieceRef,
      cell: (row) => (
        <CustomPiece
          pieces={row.pieceOrders}
          price={
            (parseFloat(row.price) / 100).toFixed(2) -
            (parseFloat(row.service_price) / 100).toFixed(2)
          }
        />
      ),
      wrap: true,
      width: "300px",
    },
    {
      name: "Prix TTC",
      selector: (row) => `${(row.price / 100).toFixed(2)} €`,
      width: "100px",
      wrap: true,
      sortable: true,
    },
    {
      name: "Détail",
      selector: (row) => row.status,
      cell: (row) => <CustomOption info={row} />,
      width: "240px",
      wrap: true,
      sortable: true,
    },
    {
      name: "Créé le",
      selector: (row) => moment(row.created_at).format("DD/MM/y HH:mm"),
      width: "150px",
      wrap: true,
      sortable: true,
    },
    {
      name: "Option",
      wrap: false,
      width: "150px",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>
            <a
              target="_blank"
              href={ApiConfig.PURCHASEORDER + row.number + ".pdf"}
            >
              * Bon De Commande
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href={ApiConfig.DELIVERYNOTE + row.number + ".pdf"}
            >
              * Bon De Livraison
            </a>
          </div>
          <div>
            <a target="_blank" href={ApiConfig.INVOICE + row.number + ".pdf"}>
              * Facture
            </a>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    __getOrders();
  }, []);

  useEffect(() => {
    if (filterText == "") {
      setFilteredItems(orders);
    } else {
      setFilteredItems(
        orders.filter(
          (item) =>
            item.number &&
            item.number.toString().includes(filterText.toLowerCase())
        )
      );
    }
  }, [filterText]);

  // Get Orders
  const __getOrders = () => {
    const response = api.getAllOrders();
    response
      .then((response) => {
        setOrders(response.data.data);
        setFilteredItems(response.data.data);
      })
      .catch((err) => {});
  };

  const __confirmPiece = (id) => {
    const response = api.confirmPiece(id);
    response
      .then((response) => {
        if (response.data.success) {
          setAlert_message((message) => "Statut modifié avec succès");
          setAlert_type((alert_type) => "success");
          alertRef.current.show();
          __getOrders();
        }
      })
      .catch((err) => {});
  };

  const __donePiece = (id) => {
    const response = api.donePiece(id);
    response
      .then((response) => {
        if (response.data.success) {
          setAlert_message((message) => "Statut modifié avec succès");
          setAlert_type((alert_type) => "success");
          alertRef.current.show();
          __getOrders();
        }
      })
      .catch((err) => {});
  };

  const subHeaderComponentMemo = useMemo(() => {
    return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} />;
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="dashboard-content">
      <DashboardHeader />
      <Alert type={alert_type} message={alert_message} ref={alertRef} />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Liste des commandes des pièces</h2>
        </div>

        <DataTable
          columns={columns}
          data={filteredItems}
          pagination={15}
          subHeader
          paginationResetDefaultPage={resetPaginationToggle}
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
        />
      </div>
    </div>
  );
}

export default Orders;
