import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

import Visitor from './views/pages/Visitor';
import Admin from './views/pages/Admin';
import Driver from './views/pages/Driver';
import Workshop from './views/pages/Workshop';
import Activation from './views/pages/Activation';
import RestPassword from './views/pages/RestPassword';
import ChangePassword from './views/pages/ChangePassword';

function App () {
    return(
        <Router>
            <Routes>
                <Route path='/*' element={<Visitor />} />
                <Route path='/admin*' element={<Admin />} />
                <Route path='/workshop/*' element={<Workshop />} />
                <Route path='/driver/*' element={<Driver />} />
                <Route path='/activation/:token' element={<Activation />} />
                <Route path='/password-rest' element={<RestPassword />} />
                <Route path='/password-rest/:token' element={<ChangePassword />} />
            </Routes>
        </Router>
    )
}

export default App;