import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import Dashboard from "./Dashboard";

import ProtectedRoutes from "./ProtectedRoutes";
import VisitorProtectedRoutes from "../VisitorProtectedRoutes";

function Workshop() {
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <VisitorProtectedRoutes>
              <Register />
            </VisitorProtectedRoutes>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <VisitorProtectedRoutes>
              <Login />
            </VisitorProtectedRoutes>
          }
        />
        <Route
          exact
          path="/dashboard/*"
          element={
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </div>
  );
}

export default Workshop;
