import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import Footer from "../../../components/Footer";

function Cgv() {
  const navigate = useNavigate();

  return (
    <div>
      <VisitorNavBar />

      <div className="cgv-container">
        <h1 className="text-center mb-40">
          FAQ - Rendez-vous chez le Garagiste en Ligne
        </h1>
        <h2>1. Comment fonctionne la prise de rendez-vous sur votre site ?</h2>
        <p>
          Pour prendre rendez-vous, choisissez le garage de votre choix,
          sélectionnez les services et les pièces nécessaires, puis optez pour
          l'option premium ou économique. Vous pouvez profiter de nos offres
          promotionnelles pour économiser sur les coûts, et même choisir de
          payer en plusieurs fois grâce à nos facilités de paiement.
        </p>
        <h2>2. Comment puis-je régler ma prestation sur votre site ?</h2>
        <p>
          Vous pouvez régler votre prestation en ligne en utilisant une carte de
          crédit ou de débit. De plus, si vous préférez étaler le paiement, nous
          proposons des facilités de paiement pour que vous puissiez choisir la
          méthode qui vous convient le mieux.
        </p>
        <h2>3. Comment se déroule la livraison des pièces au garagiste ?</h2>
        <p>
          Notre plateforme assure la livraison directe des pièces nécessaires au
          garage de votre choix. Vous n'avez pas à vous soucier de commander ou
          de transporter les pièces vous-même. Cela simplifie le processus pour
          vous et le garage.
        </p>
        <h2>4. Puis-je annuler mon rendez-vous ?</h2>
        <p>
          Oui, vous pouvez annuler votre rendez-vous jusqu'à 48 heures à
          l'avance. Passé ce délai, l'annulation ne sera plus possible. En cas
          d'annulation dans les 48 heures, un avoir sera émis pour un futur
          rendez-vous.
        </p>
        <h2>
          5. Comment puis-je demander un remboursement en cas d'annulation ?
        </h2>
        <p>
          En cas d'annulation dans les 48 heures, le montant sera remboursé sous
          forme d'avoir sur votre compte. Vous pourrez utiliser cet avoir pour
          un rendez-vous ultérieur ou d'autres prestations sur notre site. Les
          offres promotionnelles sont également disponibles pour vos futurs
          rendez-vous.
        </p>
        <h2>
          6. Quelle est la différence entre l'option "premium" et l'option
          "économique" ?
        </h2>
        <p>
          L'option "premium" comprend des pièces de haute qualité, tandis que
          l'option "économique" propose des pièces de qualité standard à un
          tarif plus avantageux. Vous pouvez également bénéficier de nos offres
          promotionnelles pour réduire davantage les coûts.
        </p>
        <h2>
          7. Comment puis-je profiter des offres promotionnelles sur votre site
          ?
        </h2>
        <p>
          Les offres promotionnelles sont affichées sur notre site. Lors de la
          prise de rendez-vous, vous serez informé des réductions ou des
          promotions en cours, ce qui vous permettra de réaliser des économies
          sur vos services de réparation ou d'entretien.
        </p>
        <h2>8. Quels types de garages sont disponibles sur votre site ?</h2>
        <p>
          Notre réseau de garages partenaires comprend une variété de garages,
          de l'indépendant au concessionnaire. Vous pouvez choisir le garage qui
          correspond le mieux à vos besoins et profiter de nos options de
          facilité de paiement pour rendre les prestations plus abordables.
        </p>
        <h2>
          9. Comment puis-je contacter le garage pour des questions spécifiques
          ?
        </h2>
        <p>
          Vous pouvez contacter le garage directement en utilisant les
          informations de contact fournies sur notre site. Ils seront heureux de
          répondre à vos questions et de discuter de vos besoins particuliers, y
          compris les facilités de paiement.
        </p>
        <h2>
          10. Comment puis-je suivre ma prestation une fois le rendez-vous pris
          ?
        </h2>
        <p>
          Une fois votre rendez-vous confirmé, vous pourrez suivre l'avancement
          de votre prestation sur notre site. Vous serez informé des étapes
          importantes, de la livraison des pièces à la finalisation du travail,
          en tenant compte de vos facilités de paiement.
        </p>
        <h2>
          11. Si des réparations imprévues sont nécessaires pour ma voiture, que
          se passe-t-il ?
        </h2>
        <p>
          Si, lors de l'inspection de votre véhicule, le garage découvre des
          opérations supplémentaires qui sont nécessaires pour garantir la
          sécurité et le bon fonctionnement de votre véhicule, le garage
          communiquera avec vous pour discuter des options. Vous serez informé
          des coûts supplémentaires et des travaux nécessaires avant que toute
          opération supplémentaire ne soit effectuée. Vous avez le contrôle
          total sur l'approbation de ces travaux, et vous pourrez discuter des
          modalités de paiement, y compris la possibilité d'étaler les coûts si
          nécessaire. Notre objectif est de vous fournir un service transparent
          et de vous aider à prendre des décisions éclairées concernant votre
          véhicule.
        </p>
        <h2>Quels types de garanties sont proposés à nos clients ?</h2>
        <p>
          Nous offrons à nos clients une garantie complète d'un an sur les
          pièces et la main-d'œuvre. Cela signifie que si vous rencontrez des
          problèmes liés à la prestation ou aux pièces installées dans votre
          véhicule dans l'année qui suit, nous nous engageons à résoudre ces
          problèmes sans frais supplémentaires. Votre satisfaction et la
          fiabilité de notre service sont notre priorité.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Cgv;
