import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import Footer from "../../../components/Footer";

function Cgv() {
  const navigate = useNavigate();

  return (
    <div>
      <VisitorNavBar />

      <div className="cgv-container">
        <h1 className="text-center mb-40">
          Mentions légales et Conditions Générales d'Utilisation
        </h1>
        <h3>
          Date de la dernière mise à jour : 04/12/2023 <br />
          Ces mentions légales et conditions générales d'utilisation (ci-après
          "CGU") définissent les règles et obligations qui régissent
          l'utilisation des services proposés par Cars d'Heure, une société
          [forme juridique de votre entreprise], enregistrée sous le numéro
          [Numéro d'enregistrement] et dont le siège social est situé à [Adresse
          du siège social].
        </h3>
        <h2>Article 1 - Acceptation des Conditions Générales d'Utilisation</h2>
        <p>
          En utilisant les services proposés par Cars d'Heure, vous acceptez
          pleinement et sans réserve l'ensemble des CGU décrites dans ce
          document. Si vous n'acceptez pas ces CGU, veuillez ne pas utiliser nos
          services.
        </p>
        <h2>Article 2 - Description des Services</h2>
        <p>
          Les services offerts par Cars d'Heure consistent en la réservation de
          rendez-vous chez des garagistes partenaires, incluant la possibilité
          de sélectionner des options d'offres de services, de tarification, et
          de paiement en ligne.
        </p>
        <h2>Article 3 - Utilisation des Services</h2>
        <p>
          L'utilisation de nos services est réservée aux personnes majeures et
          capables de contracter. Vous vous engagez à fournir des informations
          exactes et à jour lors de l'utilisation de nos services. Vous êtes
          responsable de la confidentialité de vos identifiants et informations
          de compte.
        </p>
        <h2>Article 4 - Réservation et Paiement</h2>
        <p>
          La réservation de rendez-vous s'effectue en ligne en sélectionnant un
          garage, des services, et des options de tarification. Le paiement peut
          être réalisé en ligne par carte de crédit ou de débit. [Nom de votre
          entreprise] offre également des facilités de paiement pour certains
          services.
        </p>
        <h2>Article 5 - Annulation et Remboursement</h2>
        <p>
          Vous avez la possibilité d'annuler un rendez-vous jusqu'à 48 heures à
          l'avance. Les annulations effectuées dans ce délai donneront lieu à un
          remboursement sous forme d'avoir. Au-delà de 48 heures, les
          annulations ne seront pas remboursables.
        </p>
        <h2>Article 6 - Garanties</h2>
        <p>
          Cars d'Heure offre une garantie d'un an sur les pièces et la
          main-d'œuvre pour les services effectués. En cas de problème, veuillez
          nous contacter pour bénéficier de cette garantie.
        </p>
        <h2>Article 7 - Responsabilités</h2>
        <p>
          Cars d'Heure ne peut être tenu responsable des retards, annulations ou
          tout autre incident pouvant survenir chez nos garagistes partenaires.
          Nous nous engageons à fournir un service fiable, mais les prestations
          directes sont de la responsabilité des garagistes.
        </p>
        <h2>Article 8 - Modifications des CGU</h2>
        <p>
          Cars d'Heure se réserve le droit de modifier ces CGU à tout moment.
          Les utilisateurs seront informés des modifications, et il leur sera
          demandé de les accepter à nouveau avant de continuer à utiliser nos
          services.
        </p>
        <h2>Article 9 - Droit applicable et juridiction compétente</h2>
        <p>
          Les présentes CGU sont soumises au droit [loi applicable] et tout
          litige sera de la compétence exclusive des tribunaux de [lieu de
          compétence]. Pour toute question ou réclamation concernant nos
          services, veuillez nous contacter à contact@carsdheure.fr .
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Cgv;
