import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./styles.css";
import api from "../../../../infrastructure/services/api";
import { ApiConfig } from "../../../../infrastructure/services/env";

import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import Footer from "../../../components/Footer";
import GoogleMapComponent from "../../../components/GoogleMap/GoogleMapComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";

function MakeAppointment() {
  let location = useLocation();

  const selectedSubCategory = location.state.selectedSubCategory;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [workshop, setWorkshop] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [bookingDates, setBookingDates] = useState([]);
  const [bookingIndex, setBookingIndex] = useState(1);
  const [currentDates, setCurrentDates] = useState([]);
  const [showMoreHours, setShowMoreHours] = useState(false);
  const [showMoreDates, setShowMoreDates] = useState(false);

  // Get Available Slots
  const __getSlots = async (selected_date) => {
    const response = await api.getAvailableSlots(
      location.state.workshop,
      selected_date.getFullYear(),
      selected_date.getMonth() + 1,
      selected_date.getDate()
    );
    return await response.data;
  };

  const __getDaysArray = (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  // Get Available Slots
  const __confirmAppointment = () => {
    if (selectedSlot) {
      let prestations = [];

      selectedSubCategory.map((subCat, i) => {
        prestations.push({
          prestation: subCat._id,
          title: subCat.title,
          duration: 90,
          prestationPrice: subCat.price_t1,
          pieceRef: subCat.selectedPiece.CODE,
          pieceLib: subCat.selectedPiece.LIBELLE,
          piece_price: subCat.selectedPiece.PRIX,
        });
      });

      let new_appointment = {
        business: location.state.workshop,
        start_date_time: selectedSlot.start,
        end_date_time: selectedSlot.end,
        prestations: prestations,
      };

      navigate("/confirm-appointment", {
        state: { workshop: workshop, appointment: new_appointment },
      });
    }
  };

  const __TimeFormat = (date) => {
    const newFormat = new Date(date);
    return (
      newFormat.getHours() +
      ":" +
      String(newFormat.getMinutes()).padStart(2, "0")
    );
  };

  useEffect(() => {
    let startDay = new Date().getHours() > 11 ? 3 : 2;
    let dates = __getDaysArray(
      new Date().getTime() + 86400000 * startDay,
      new Date(new Date().getTime() + 86400000 * 50)
    );
    setBookingDates(dates);
    let newCurrentDates = [];
    dates
      .filter((x, i) => i < bookingIndex * 4 && i >= bookingIndex * 4 - 4)
      .map(async (date, index) => {
        let dateName = {
          date: new Date(date),
          hours: await __getSlots(new Date(date)),
        };
        setCurrentDates([...newCurrentDates, dateName]);
        newCurrentDates.push(dateName);
      });
  }, []);

  useEffect(() => {
    setShowMoreDates(false);
    let newCurrentDates = [];
    bookingDates
      .filter((x, i) => i < bookingIndex * 4 && i >= bookingIndex * 4 - 4)
      .map(async (date, index) => {
        let dateName = {
          date: new Date(date),
          hours: await __getSlots(new Date(date)),
        };
        setCurrentDates([...newCurrentDates, dateName]);
        newCurrentDates.push(dateName);
      });
  }, [bookingIndex]);

  useEffect(() => {
    if (currentDates.length == 4) {
      setTimeout(() => {
        setShowMoreDates(true);
      }, 1000);
    }
  }, [currentDates]);

  useEffect(() => {
    const response = api.getBusinessProfile(location.state.workshop);
    response.then((response) => {
      setWorkshop(response.data.data);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      {/* NavBar */}
      <VisitorNavBar />

      {/* Workshop Info */}
      {!loading ? (
        <div className="workshop-profile-container">
          <div className="workshop-profile-overlay">
            {workshop.business.cover_img ? (
              <img
                src={ApiConfig.IMAGES + workshop.business.cover_img}
                alt={workshop.business.workshopName}
              />
            ) : (
              <img src="/carsdheur.webp" alt={workshop.business.workshopName} />
            )}
          </div>
          <div className="workshop-info">
            <h2 className="workshop-info-title">
              {workshop.business.workshop_name}
            </h2>
            <span className="workshop-info-subTitle">
              {workshop.business.workshop_address?.label}
            </span>
            <div className="workshop-info-btn">
              <div className="rating-btn">
                <span>{workshop.business.rate.toFixed(1)}</span>(
                {workshop.business.feedbacks.length} avis)
              </div>
              <div className="phone-btn">
                <FontAwesomeIcon icon={faPhone} style={{ color: "#ffffff" }} />
                  {workshop.business.business_infos.phoneNumber}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {selectedSubCategory !== null && workshop?.business ? (
        <div className="appointment-info-container">
          <div className="appointment-info">
            <h5>Prix détaillé de la prestation</h5>
            {selectedSubCategory.map((subCat, i) => {
              return (
                <div key={i} style={{ marginTop: "15px" }}>
                  <h6>{subCat.title}</h6>
                  <div className="detailed">
                    <p className="detailed-name">Main d'oeuvre:</p>
                    <p className="detailed-price">
                      {parseFloat(subCat.price_t1).toFixed(2)}€ TTC
                    </p>
                  </div>
                  <div className="detailed">
                    <p className="detailed-name">
                      Piéces : {subCat.selectedPiece.LIBELLE[0]}
                    </p>
                    <p className="detailed-price">
                      {parseFloat(subCat.selectedPiece.PRIX[0]).toFixed(2)}€ TTC
                    </p>
                  </div>
                  <div className="detailed">
                    <p className="detailed-name" style={{ fontWeight: 900 }}>
                      {" "}
                      Prix de la prestation
                    </p>
                    <p className="detailed-price" style={{ fontWeight: 900 }}>
                      {(
                        parseFloat(subCat.selectedPiece.PRIX[0]) +
                        subCat.price_t1
                      ).toFixed(2)}{" "}
                      € TTC
                    </p>
                  </div>
                </div>
              );
            })}

            <h5 style={{ marginTop: "20px" }}>Moyen de paiement</h5>
            <p style={{ fontWeight: 700, fontSize: "14px" }}>
              Chèques, espèces et carte bancaire
            </p>
            <h5 style={{ marginTop: "20px" }}>Carte et informations d'accès</h5>
            <p style={{ fontWeight: 500 }}>
              {workshop.business.workshop_address.label}
            </p>
            <h5 style={{ marginTop: "20px" }}>Moyens de transport</h5>
            {workshop.business.business_infos?.means_of_access.map(
              (e, index) => {
                return (
                  <p className="contact-name" key={index}>
                    {e}
                  </p>
                );
              }
            )}
            {workshop.business.workshop_address?.geolocation?.coordinates
              .length ? (
              <div className="form-row iframe">
                <GoogleMapComponent
                  latitude={
                    workshop.business.workshop_address.geolocation
                      .coordinates[1]
                  }
                  longitude={
                    workshop.business.workshop_address.geolocation
                      .coordinates[0]
                  }
                />
              </div>
            ) : (
              ""
            )}

            <h5>Horraires et contacts</h5>
            <div className="d-flex">
              <div className="contact">
                <h6>Ouverture du garage</h6>
                <div className="contact-name">
                  {workshop.business.schedule.map((e, index) => {
                    return (
                      <div key={index} style={{ margin: "15px 0" }}>
                        <p
                          style={{
                            minWidth: "80px",
                            display: "inline-block",
                            margin: 0,
                          }}
                        >
                          {e.day} :{" "}
                        </p>
                        {!e.work
                          ? "Fermé"
                          : e.slot.map((time) => {
                              return (
                                time.start_time +
                                " - " +
                                time.end_time +
                                (e.slot.length > 1 ? "," : "")
                              );
                            })}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="contact">
                <h6>Contact du garage</h6>
                <p className="contact-name">
                  {workshop.business.business_infos?.phoneNumber}
                </p>
              </div>
            </div>
            <h5 style={{ marginTop: "20px" }}>Présentation</h5>
            <div className="d-flex">
              <div className="contact">
                <p className="contact-name" style={{ marginTop: "5px" }}>
                  Gérant : {workshop.business.business_infos?.manager_name}
                </p>
                <p className="contact-name">
                  Nombre de mécaniciens :{" "}
                  {workshop.business.business_infos?.number_of_workers}
                </p>
              </div>
              <div className="contact">
                <p className="contact-name" style={{ marginTop: "5px" }}>
                  Année d'ouverture : {workshop.business.business_infos?.since}{" "}
                </p>
                <p className="contact-name">
                  Année d'expériences :{" "}
                  {workshop.business.business_infos?.work_years}
                </p>
              </div>
            </div>
          </div>

          <div className="appointment-booking">
            <div className="calendar-container">
              <div className="appointment-info-container">
                <div className="booking-card">
                  <div className="booking-card-header">
                    Prendre un rendez-vous en ligne
                  </div>
                  <div className="booking-card-body">
                    <div className="booking-card-body-text">
                      Sélectionnez votre rendez-vous
                    </div>
                    <div className="booking-dates">
                      <FontAwesomeIcon
                        onClick={() =>
                          bookingIndex > 1
                            ? (setBookingIndex(bookingIndex - 1),
                              setSelectedSlot(null))
                            : ""
                        }
                        className="fa-2x"
                        icon={faChevronLeft}
                        style={{ color: "#c283ef" }}
                      />
                      <div className="booking-card-body-dates">
                        {bookingDates
                          .filter(
                            (x, i) =>
                              i < bookingIndex * 4 && i >= bookingIndex * 4 - 4
                          )
                          .map((date, index) => {
                            return (
                              <div className="booking-date" key={index}>
                                <span className="day-name">
                                  {date.toLocaleString("FR-fr", {
                                    weekday: "short",
                                  })}
                                </span>
                                <span className="date-month">
                                  {date.getDate()}{" "}
                                  {date.toLocaleString("FR-fr", {
                                    month: "short",
                                  })}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                      <FontAwesomeIcon
                        onClick={() =>
                          bookingIndex < 10
                            ? (setBookingIndex(bookingIndex + 1),
                              setSelectedSlot(null))
                            : ""
                        }
                        className="fa-2x"
                        icon={faChevronRight}
                        style={{ color: "#c283ef" }}
                      />
                    </div>

                    <div className="booking-card-body-hours">
                      {showMoreDates &&
                        currentDates
                          .sort((a, b) => {
                            return a.date - b.date;
                          })
                          .map((date, index) => {
                            let checkClosedDay =
                              workshop?.business.schedule.filter(
                                (e) =>
                                  e.day ===
                                    date.date.toLocaleString("FR-fr", {
                                      weekday: "long",
                                    }) && !e.work
                              );
                            if (checkClosedDay.length) {
                              return (
                                <div
                                  key={index}
                                  className="booking-card-body-day-hours"
                                >
                                  <div
                                    className="booking-hour"
                                    style={{
                                      backgroundColor: "#ccc9c9",
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    -
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={index}
                                  className="booking-card-body-day-hours"
                                >
                                  {date.hours.data
                                    .filter((e, i) =>
                                      showMoreHours ? i == i : i < 6
                                    )
                                    .map((hour, i) => {
                                      if (hour.start == "no-available") {
                                        return (
                                          <div
                                            key={i}
                                            className="booking-hour"
                                            style={{
                                              backgroundColor: "#ccc9c9",
                                              cursor: "not-allowed",
                                            }}
                                          >
                                            -
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div
                                            key={i}
                                            className={
                                              selectedSlot !== null &&
                                              selectedSlot === hour
                                                ? "booking-hour-selected booking-hour"
                                                : "booking-hour"
                                            }
                                            onClick={() =>
                                              setSelectedSlot(
                                                selectedSlot === null ||
                                                  selectedSlot !== hour
                                                  ? hour
                                                  : null
                                              )
                                            }
                                          >
                                            <p>{__TimeFormat(hour.start)}</p>
                                          </div>
                                        );
                                      }
                                    })}
                                </div>
                              );
                            }
                          })}
                      {!showMoreDates ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100px",
                          }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color="#562ac6"
                            height={80}
                            width={80}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="booking-hours-views"
                      onClick={() => setShowMoreHours(!showMoreHours)}
                    >
                      {showMoreHours ? "Voir moins" : "Voir plus d'horaires"}
                    </div>
                    {selectedSlot !== null ? (
                      <button
                        className="btn btn-full"
                        onClick={() => __confirmAppointment()}
                      >
                        Confirmer
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MakeAppointment;
