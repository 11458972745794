import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "./styles.css";
import DriverNavBar from "../../../components/NavBars/DriverNavBar";
import { DataContext } from "../../../../application";
import Footer from "../../../components/Footer";

import api from "../../../../infrastructure/services/api";
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validatePassword,
} from "../../../utils/input-verification";
import Alert from "../../../components/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faUser,
  faMapMarked,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-number-input";
import {
  getData,
  getObject,
  removeData,
  storeData,
  storeObject,
} from "../../../../application/helpers/local-storage";

function Register() {
  const navigate = useNavigate();
  const { dataContext } = React.useContext(DataContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  const __register = () => {
    if (!validateName(firstName) || !validateName(lastName)) {
      // alert('Merci de vérifier votre nom et prénom.');
      setAlert_message((message) => "Merci de vérifier votre nom et prénom.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (!validateEmail(email)) {
      // alert('Merci de vérifier votre addresse mail.');
      setAlert_message((message) => "Merci de vérifier votre addresse mail.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (!validatePhoneNumber(phone)) {
      // alert('Merci de vérifier votre nom et prénom.');
      setAlert_message((message) => "Merci de vérifier votre Téléphone.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (address.length === 0) {
      // alert('Merci de vérifier votre nom et prénom.');
      setAlert_message((message) => "Merci de vérifier votre Adresse.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (password.length === 0 || !validatePassword(password)) {
      // alert('Merci de vérifier votre mot de passe.');
      setAlert_message((message) => "Merci de vérifier votre mot de passe.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const user = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        address: address,
      };
      const response = api.driverRegister(user);
      response.then((response) => {
        if (response.data.success) {
          // alert('Merci de consulter votre boîte mail !');
          setAlert_message(
            (message) => "Merci de consulter votre boîte mail !"
          );
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();

          var decoded_token = jwt_decode(response.data.token);
          if (decoded_token.role === "personal") {
            dataContext.login(response.data.token);
            let has_new_appointment = JSON.parse(getData("new_appointment"));
            if (has_new_appointment) {
              let new_appointment = {
                business: has_new_appointment?.business,
                start_date_time: has_new_appointment?.start_date_time,
                end_date_time: has_new_appointment?.end_date_time,
                prestations: has_new_appointment?.prestation,
              };
              removeData("new_appointment");
              navigate("/confirm-appointment", {
                state: {
                  workshop: has_new_appointment?.business,
                  appointment: new_appointment,
                },
              });
            } else {
              navigate("/driver/dashboard");
            }
          }
        } else if (response.status === 201) {
          // alert('Merci de vérifier votre connexion.');
          setAlert_message((message) => "Merci de vérifier votre connexion.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        } else {
          setAlert_message((message) => response.data?.message);
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
      response.catch((error) => {
        if (error.response.status === 403) {
          // alert('Merci de vérifier votre e-mail.');
          setAlert_message((message) => "Merci de vérifier votre e-mail.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        } else {
          // alert('Merci de vérifier votre connexion.');
          setAlert_message((message) => "Merci de vérifier votre connexion.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
    }
  };

  return (
    <>
      <div className="d-block min-h-1000">
        <Alert type={alert_type} message={alert_message} ref={alertRef} />
        <DriverNavBar
          text="Se connecter"
          onClick={() => navigate("/driver/login")}
        />

        <div className="login-workshop-container driver register">
          <div className="login-workshop-header">
            <h3>Créer votre compte</h3>
            <p>Créer votre compte pour gérer facilement votre rendez-vous.</p>
          </div>
          <div className="login-form">
            <div className="group">
              <FontAwesomeIcon icon={faUser} style={{ color: "#a5a5a5" }} />
              <input
                type="text"
                value={lastName}
                placeholder="Nom"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="group">
              <FontAwesomeIcon icon={faUser} style={{ color: "#a5a5a5" }} />
              <input
                type="text"
                value={firstName}
                placeholder="Prénom"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="group">
              <FontAwesomeIcon icon={faEnvelope} style={{ color: "#a5a5a5" }} />
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="group">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="FR"
                placeholder="Télèphone"
                value={phone}
                onChange={setPhone}
              />
            </div>

            <div className="group">
              <FontAwesomeIcon
                icon={faMapMarked}
                style={{ color: "#a5a5a5" }}
              />
              <input
                type="text"
                value={address}
                placeholder="Adresse"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div className="group relative">
              <FontAwesomeIcon icon={faLock} style={{ color: "#a5a5a5" }} />
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="Mot de passe"
                onChange={(e) => setPassword(e.target.value)}
              />
              {password.length ? (
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-password"
                >
                  <FontAwesomeIcon
                    className="fa-md"
                    icon={showPassword ? faEyeSlash : faEye}
                  />
                </div>
              ) : null}
            </div>
            {password.length ? (
              <span style={{ fontSize: "9px" }}>
                Le mot de passe doit comporter au moins{" "}
                <strong>8 caractères</strong> et doit comporter au moins{" "}
                <strong>une majuscule</strong>, <strong> une minuscule </strong>{" "}
                et <strong>un caractère numérique</strong>
              </span>
            ) : null}

            <span>
              En continuant , j'accepte{" "}
              <a
                href="/cgu"
                title="Mentions légales et Conditions Générales d'Utilisation"
              >
                les Conditions générales d'utilisation
              </a>{" "}
              et{" "}
              <a
                href="/politique-de-protection-des-donnees-personnelles"
                title="Politique de Protection des Données Personnelles"
              >
                la Politique de confidentialité
              </a>{" "}
              de Cars d'heure.
            </span>

            <button className="btn" onClick={__register}>
              S'inscrire
            </button>
            <button
              className="btn btn_secondary"
              onClick={() => navigate("/driver/login")}
            >
              Se connecter
            </button>
          </div>
        </div>
      </div>
      <div>
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

export default Register;
