import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "../styles.css";
import logo from "../../../assets/images/primary-logo.png";
import HelpOutline from "../../../assets/icons/help_outline.svg";
import verticalDots from "../../../assets/icons/vertical-dots.svg";

import api from "../../../../infrastructure/services/api";
import {DataContext} from '../../../../application';

function VisitorNavBar() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [decoded, setdecoded] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const {dataContext} = React.useContext(DataContext);
  const nav = useNavigate();

  useEffect(() => {
    if (api.getAuthToken() !== null) {
      setToken(api.getAuthToken());
      setdecoded(jwt_decode(api.getAuthToken()));
    }
  }, []);

  const __logout = () => {
    dataContext.logout();
    nav("/");
  };


  return (
    <div className="container">
      <div className="navbar">
        <img
          src={logo}
          className="logo"
          alt="cas-d'heure-logo"
          onClick={() => navigate("/")}
        />

        {token !== null && decoded.role === "personal" ? (
          <div
            className="dashbord-header-avatar"
            onClick={() => navigate("/driver/dashboard")}
          >
            C
          </div>
        ) : (
          <div className="links">
            <span onClick={() => navigate("/workshop")}>
              Vous êtes professionnel de l'automobile ?
            </span>
            <div className="help">
              <img src={HelpOutline} className="help-icon" alt="help-icon" />
              <span>Besoin d'aide</span>
            </div>
            <button onClick={() => navigate("/driver/login")}>
              Se connecter
            </button>
          </div>
        )}
      </div>

      <div className="responsive-navbar">
        <img src={logo} alt="logo" className="logo" onClick={() => navigate("/")} />
        <img
          src={verticalDots}
          alt="icon"
          onClick={() => setDropdown((dropdown) => !dropdown)}
          className="vertical-dots"
        />
        {dropdown && (
          <div className="dropdown">

            {token !== null && decoded.role === "personal" ? (
             <>
                <div className="help" onClick={() => navigate("/driver/login")}>
                  <span>Mon Compte</span>
                </div>
                <button onClick={() => __logout()}>
                    Déconnexion
                </button>
              </>
            ) : (

              <>
              <span onClick={() => navigate("/workshop")}>
                Cars d'Heure Pro ?
              </span>
              <div className="help">
                  <span>Besoin d'aide</span>
              </div>
              <button onClick={() => navigate("/driver/login")}>
                  Se connecter
              </button>
              </>
            )}


          </div>
        )}
      </div>
    </div>
  );
}

export default VisitorNavBar;
