import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./styles.css";
import api from "../../../../infrastructure/services/api";
import { DataContext } from "../../../../application";
import { validateRegistrationNumber } from "../../../utils/input-verification";

import Alert from "../../../components/Alert";
import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import SearchBar from "../../../components/NewSearchBar";
import {
  faCarSide,
  faLocationDot,
  faXmark,
  faCar,
} from "@fortawesome/free-solid-svg-icons";

import ServiceCard from "../../../components/Cards/ServiceCard";
import CategoryCard from "../../../components/Cards/CategoryCard";
import Footer from "../../../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SwiperCard from "../../../components/Cards/SwiperCard";
import ReactLoading from "react-loading";

function Search() {
  const location = useLocation();
  const navigate = useNavigate();

  const search = new URLSearchParams(location.search);
  const { state, dataContext } = React.useContext(DataContext);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [city, setCity] = useState(
    search.get("city") ? search.get("city") : "Paris"
  );
  const [cityCode, setCityCode] = useState(
    search.get("dp") ? search.get("dp") : "75"
  );
  const position = location?.state?.location ? location.state.location : null;

  const [alert_type, setAlert_type] = useState("");
  const [ranges, setRanges] = useState([]);
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);
  const [display, setDisplay] = useState(6);
  const [isLoading, setIsLoading] = useState(true);

  const [sort, setSort] = useState("recommended");
  const [showFilters, setShowFilters] = useState(false);
  const [carId, setCarId] = useState(search.get("carId"));
  const [typeId, setTypeId] = useState(null);

  // Search
  const __search = (category) => {
    const params = {
      category: category ?? selectedCategory,
      city: cityCode,
      lat: position != null ? position.latitude : null,
      long: position != null ? position.longitude : null,
      sort: sort,
    };
    const response = api.search(params);
    response.then((response) => {
      setServices(response.data.data);
      __getPriceRange(response.data.data);
    });
  };

  const __getCities = (category) => {
    const response = api.getCities();
    response.then((response) => {
      setCities(response.data.data);
    });
  };

  const __getBanners = () => {
    const response = api.getBanners();
    response.then((response) => {
      setBanners(response.data.data);
    });
  };

  const __handleChangeSort = (event) => {
    setSort(event.target.value);
  };

  // Change Category
  const __handleCategory = (category) => {
    setSelectedCategory(category);
    __search(category);
  };

  const __handleCity = (c) => {
    setCityCode(c.target.value);
  };

  const __getPriceRange = (services) => {
    let min = 50;
    let max = 0;
    let ranges = [];

    services.map((e, i) => {
      min =
        min < Math.ceil(e.starting_price / 10) * 10 || i !== 0
          ? min
          : Math.ceil(e.starting_price / 10) * 10;
      max =
        max > Math.ceil(e.max_price / 10) * 10 || i !== 0
          ? max
          : Math.ceil(e.max_price / 10) * 10;
    });

    for (let i = 1; i <= 4; i++) {
      if (Math.trunc(min / 25) * 25 * i < max && min / 25 > 1) {
        ranges.push(Math.trunc(min / 25) * 25 * i);
      }
    }
    ranges.push(max);
    setRanges(ranges);
  };

  const __handleDisplay = () => {
    setDisplay(display + 6);
  };

  const __plateSearch = (e) => {
    if (!validateRegistrationNumber(e.target.value)) {
      setAlert_message((message) => "Merci de vérifier votre immatriculation.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const response = api.plateSearch(e.target.value);
      response.then((response) => {
        if (
          response.data.status === 200 &&
          response.data.data.array.length &&
          response.data.data.array[0].subLinkageTargetType === "V"
        ) {
          dataContext.setVehicule({
            PLATE: e.target.value,
            ...response.data.data.array[0],
          });
          navigate(
            `/search?city=${city}&dp=${cityCode}&category=${selectedCategory.name}&im=${e.target.value}&carId=${response.data.data.array[0].carId}`
          );
        } else {
          setAlert_message(
            (message) => "Merci de vérifier votre immatriculation."
          );
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
    }
  };

  const __getCategories = async () => {
    const typeId = api.getTecRamiTypeId(carId);
    typeId.then(async (response) => {
      if (response.data.success) {
        setTypeId(response.data.typeId);
        dataContext.setTypeId(response.data.typeId);
        const categories_response = api.getWorksListName(response.data.typeId);
        let category = null;
        await categories_response.then((response) => {
          setCategories(response.data.data);
          if (search.get("category") !== null) {
            response.data.data.map((cat, i) => {
              if (search.get("category") === cat.name) category = cat;
            });
          }

          if (!category) {
            category = response.data.data[0];
          }
          setSelectedCategory(category);
          dataContext.setCategory(category);
          __search(category);
          setIsLoading(false);
        });
      }
    });
  };

  useEffect(() => {
    dataContext.setCityCode(cityCode);
    __getCities();

    if (!selectedCategory) {
      __getCategories();
    } else {
      __search(selectedCategory);
    }
  }, [sort, cityCode]);

  return (
    <div className="container">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />

      <VisitorNavBar />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "700px",
          }}
        >
          <ReactLoading type={"spin"} color="#562ac6" height={80} width={80} />
        </div>
      ) : (
        <div className="search" style={{ marginTop: "35px" }}>
          <div className="categories-container">
            {categories.length !== 0
              ? categories.map((category, index) => (
                  <CategoryCard
                    key={index}
                    category={category}
                    activeCategory={selectedCategory}
                    onClick={() => __handleCategory(category)}
                  />
                ))
              : null}
          </div>

          {/* <div>
          <SwiperCard />
        </div> */}

          <div className="services-search-container">
            <div className="search-side-bar">
              <div className="search-sort">
                <p>Immatriculation</p>
                <div className="relative d-flex">
                  <div className="icon-input">
                    <FontAwesomeIcon
                      icon={faCar}
                      size="sm"
                      style={{ color: "#5127c5" }}
                    />
                  </div>
                  <input
                    type="text"
                    className="plate"
                    name="plate"
                    defaultValue={state?.vehicule?.PLATE}
                    onChange={(e) =>
                      e.target.value.length >= 7 ? __plateSearch(e) : ""
                    }
                    placeholder="Exemple: AA123FF"
                  />
                </div>
              </div>
              <div className="search-sort">
                <div className="vihecule-label">
                  <span>
                    <FontAwesomeIcon
                      icon={faCarSide}
                      size="lg"
                      style={{ color: "#5127c5" }}
                    />
                    <strong style={{ paddingLeft: "15px", color: "#5127c5" }}>
                      Votre vèhicile :
                    </strong>
                  </span>
                </div>
                <div style={{ fontWeight: "700" }}>
                  {state.vehicule?.carName}
                </div>
              </div>
              <hr />
              <div className="search-sort">
                <p>Ville</p>
                <div className="relative">
                  <div className="icon-input">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      size="sm"
                      style={{ color: "#5127c5" }}
                    />
                  </div>
                  <select
                    onChange={__handleCity}
                    name="select_city"
                    id="select_city"
                  >
                    {cities.map((obj, index) => {
                      return (
                        <option
                          defaultValue={cityCode === obj.code ? cityCode : null}
                          key={index}
                          value={obj.code}
                        >
                          {obj.cityName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="search-sort">
                <p>TRIER</p>
                <input
                  onChange={__handleChangeSort}
                  checked={sort === "recommended"}
                  type="radio"
                  name="sort"
                  id="recommended"
                  value="recommended"
                />
                <label htmlFor="recommended">
                  Choisi pour vous (par défaut)
                </label>
                <br />

                <input
                  onChange={__handleChangeSort}
                  checked={sort === "most-popular"}
                  type="radio"
                  name="sort"
                  id="most-popular"
                  value="most-popular"
                />
                <label htmlFor="most-popular">Les plus populaires</label>
                <br />

                <input
                  onChange={__handleChangeSort}
                  checked={sort === "top-rated"}
                  type="radio"
                  name="sort"
                  id="top-rated"
                  value="top-rated"
                />
                <label htmlFor="top-rated">Note</label>
                <br />

                <input
                  onChange={__handleChangeSort}
                  checked={sort === "nearest"}
                  type="radio"
                  name="sort"
                  id="nearest"
                  value="nearest"
                />
                <label htmlFor="nearest">Le plus proche</label>
                <br />

                <input
                  onChange={__handleChangeSort}
                  checked={sort === "promo"}
                  type="radio"
                  name="sort"
                  id="promo"
                  value="promo"
                />
                <label htmlFor="promo">Promo</label>
                <br />
              </div>

              {/* {ranges.length ? (
              <div className="search-sort">
                <p>FOURCHETTE DE PRIX</p>
                <div className="price-sort-type">
                  {ranges.map((range, i) => {
                    return (
                      <button
                        key={i}
                        onClick={__handlePrice}
                        className="price-sort"
                      >
                        {range}€
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )} */}
            </div>

            <div className="search-results-section">
              <div className="search-results-container">
                <h4>Nos Favoris</h4>
                <div className="search-results-display">
                  <p>Les professionnels les mieux notés</p>
                </div>
                <div className="filtres" onClick={() => setShowFilters(true)}>
                  Filtres
                </div>

                {showFilters && (
                  <div className="filtres-card">
                    <div className="search-side-bar">
                      <div
                        className="close-icon"
                        onClick={() => setShowFilters(false)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          size="2x"
                          style={{ color: "#5127c5" }}
                        />
                      </div>

                      <div className="search-sort">
                        <p>TRIER</p>
                        <input
                          onChange={__handleChangeSort}
                          checked={sort === "recommended"}
                          type="radio"
                          name="sort"
                          id="recommended"
                          value="recommended"
                        />
                        <label htmlFor="recommended">
                          Choisi pour vous (par défaut)
                        </label>
                        <br />

                        <input
                          onChange={__handleChangeSort}
                          checked={sort === "most-popular"}
                          type="radio"
                          name="sort"
                          id="most-popular"
                          value="most-popular"
                        />
                        <label htmlFor="most-popular">
                          Les plus populaires
                        </label>
                        <br />

                        <input
                          onChange={__handleChangeSort}
                          checked={sort === "top-rated"}
                          type="radio"
                          name="sort"
                          id="top-rated"
                          value="top-rated"
                        />
                        <label htmlFor="top-rated">Note</label>
                        <br />

                        <input
                          onChange={__handleChangeSort}
                          checked={sort === "nearest"}
                          type="radio"
                          name="sort"
                          id="nearest"
                          value="nearest"
                        />
                        <label htmlFor="nearest">Le plus proche</label>
                        <br />

                        <input
                          onChange={__handleChangeSort}
                          checked={sort === "promo"}
                          type="radio"
                          name="sort"
                          id="promo"
                          value="promo"
                        />
                        <label htmlFor="promo">Promo</label>
                        <br />
                      </div>

                      {/* {ranges.length ? (
                      <div className="search-sort">
                        <p>FOURCHETTE DE PRIX</p>
                        <div className="price-sort-type">
                          {ranges.map((range, i) => {
                            return (
                              <button
                                key={i}
                                onClick={__handlePrice}
                                className="price-sort"
                              >
                                {range}€
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}

                      <div className="search-sort">
                        <p>Ville</p>
                        <div className="relative">
                          <div className="icon-input">
                            <FontAwesomeIcon
                              icon={faLocationDot}
                              size="md"
                              style={{ color: "#5127c5" }}
                            />
                          </div>
                          <select
                            onChange={__handleCity}
                            name="select_city"
                            id="select_city"
                          >
                            {cities.map((obj, index) => {
                              return (
                                <option
                                  defaultValue={
                                    cityCode === obj.code ? cityCode : null
                                  }
                                  key={index}
                                  value={obj.code}
                                >
                                  {obj.cityName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="search-sort">
                        <p>Immatriculation</p>
                        <div className="relative d-flex">
                          <div className="icon-input">
                            <FontAwesomeIcon
                              icon={faCar}
                              size="sm"
                              style={{ color: "#5127c5" }}
                            />
                          </div>
                          <input
                            type="text"
                            className="plate"
                            name="plate"
                            defaultValue={state?.vehicule?.PLATE}
                            onChange={(e) =>
                              e.target.value.length >= 7 ? __plateSearch(e) : ""
                            }
                            placeholder="Exemple: AA123FF"
                          />
                        </div>
                      </div>

                      <div className="search-sort">
                        <div className="vihecule-label">
                          <span>
                            <FontAwesomeIcon
                              icon={faCarSide}
                              size="lg"
                              style={{ color: "#5127c5" }}
                            />
                            <strong
                              style={{ paddingLeft: "15px", color: "#5127c5" }}
                            >
                              Votre vèhicile
                            </strong>
                          </span>
                        </div>
                        <h5>{state.vehicule?.NAME}</h5>
                      </div>
                    </div>
                  </div>
                )}

                {services.length !== 0 ? (
                  <>
                    <div className="search-results">
                      {services.map((service, index) => {
                        if (display > index) {
                          return (
                            <ServiceCard
                              key={index}
                              service={service}
                              typeId={typeId}
                            />
                          );
                        }
                      })}
                    </div>
                    <div
                      className="search-results-display text-center"
                      style={{
                        placeContent: "center",
                        marginTop: "30px",
                        fontSize: "25px",
                      }}
                    >
                      <span onClick={__handleDisplay}>
                        <p
                          style={{
                            fontSize: "19px",
                          }}
                        >
                          Charger plus
                        </p>
                      </span>
                    </div>
                  </>
                ) : (
                  <div>
                    <h3>Il n'y a aucune prestation</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Search;
