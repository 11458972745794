import React, { useState, useEffect } from "react";

import api from "../../../../infrastructure/services/api";
import { ApiConfig } from "../../../../infrastructure/services/env";

import "./styles.css";
import DashboardHeader from "../../../components/Headers/DashboardHeader";
import HelpCard from "../../../components/Cards/HelpCard";
import moment from "moment";
import Modal from "react-modal";

function Appointments() {
  const [appointments, setAppointments] = useState([]);
  const [modelData, setModelData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    __getAppointments();
  }, []);

  const __showModel = (data) => {
    setModelData(data);
    setIsOpen(true);
  };

  const __confirmAppointment = (appointment) => {
    appointment.appointmentId = appointment._id;
    appointment.status = "CONFIRMED";
    const response = api.updateStatusAppointment(appointment);
    response.then((response) => {
      if (response.status === 201) {
        __getAppointments();
        setIsOpen(false);
        setModelData([]);
      }
    });
  };

  const __cancelAppointment = (appointment) => {
    appointment.appointmentId = appointment._id;
    appointment.status = "CANCELED";
    const response = api.updateStatusAppointment(appointment);
    response.then((response) => {
      if (response.status === 201) {
        __getAppointments();
        setIsOpen(false);
        setModelData([]);
      }
    });
  };

  // get Appointments
  const __getAppointments = () => {
    const response = api.getMyBusinessAppointments();
    response.then((response) => {
      setAppointments(response.data.data.business.appointments);
    });
  };

  // Finish Appointments
  const __finishAppointment = (appointment) => {
    appointment.appointmentId = appointment._id;
    appointment.status = "DONE";
    const response = api.updateStatusAppointment(appointment);
    response.then((response) => {
      __getAppointments();
    });
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader />

      <HelpCard />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h3>Mes rendez-vous</h3>
        </div>

        <table>
          <thead>
            <tr>
              <th>N°</th>
              <th>Informations</th>
              <th>Date & Heure</th>
              <th>Type de prestation</th>
              <th>Pièces</th>
              <th>
                Prix Total
                <br />
                Prix service
              </th>
              <th>Paiement</th>
              <th>Status</th>
              <th>Options</th>
            </tr>
          </thead>

          <tbody>
            {appointments
              .slice(0)
              .reverse()
              .map((appointment, index) => (
                <tr key={index}>
                  <td style={{ minWidth: "100px" }}>
                    RDV N°{appointment.number}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <div className="f-500">
                      {appointment.personal?.firstName +
                        " " +
                        appointment.personal?.lastName}
                    </div>
                    <div className="f-500">
                      {appointment.personal?.phoneNumber}
                    </div>
                    <div className="f-500">{appointment.personal?.address}</div>
                    <div className="f-500">{appointment.vehicule?.PLATE}</div>
                    <div className="f-500">{appointment.vehicule?.NAME}</div>
                  </td>
                  <td>
                    {moment(appointment.start_date_time).format(
                      "DD/MM/Y à HH[h]mm"
                    )}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {appointment?.prestations?.map((prestation, index) => {
                      return (
                        <div key={index} className="f-500">
                          {prestation.title}
                        </div>
                      );
                    })}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {appointment?.pieceOrders?.map((pieceOrder, index) => {
                      return (
                        <div key={index} className="f-500">
                          {pieceOrder.pieceRef}
                          <br />
                          {pieceOrder.pieceLib}
                          <br />
                          <strong>
                            {pieceOrder.status === "PROGRESS"
                              ? "EN COURS"
                              : pieceOrder.status === "CONFIRMED"
                              ? "CONFIRMÉ"
                              : "TERMINÉ"}
                          </strong>
                          <hr />
                        </div>
                      );
                    })}
                    {appointment.payment_method === "CREDIT_CARD" ? (
                      <a
                        target="_blank"
                        href={
                          ApiConfig.PURCHASEORDER + appointment.number + ".pdf"
                        }
                      >
                        <strong>Bon De Commande</strong>
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={{ minWidth: "100px" }}>
                    {(appointment.price / 100).toFixed(2)}€ TTC <br />
                    {(appointment.service_price / 100).toFixed(2)}€ TTC
                  </td>

                  <td>
                    {appointment.payment_method === "CREDIT_CARD"
                      ? appointment.payment_status === "PAIED"
                        ? "Payé"
                        : appointment.payment_status === "WAITING"
                        ? "En attente de paiement"
                        : null
                      : ""}
                  </td>
                  <td>
                    {appointment.status === "UNCONFIRMED" ? (
                      <span style={{ color: "orange" }}>A confirmé</span>
                    ) : appointment.status === "CONFIRMED" ? (
                      <span className="success-text">Confirmé</span>
                    ) : appointment.status === "CANCELED" ? (
                      <span className="danger-text">Annulé</span>
                    ) : (
                      <span className="success-text">Terminer</span>
                    )}
                  </td>
                  <td>
                    {appointment?.invoice ? (
                      <div>
                        <a
                          target="_blank"
                          style={{ color: "red" }}
                          href={
                            "https://api.carsdheure.fr/public/factures/" +
                            appointment.invoice
                          }
                          className="driver-appointment-text-bold"
                        >
                          Facture
                        </a>
                      </div>
                    ) : (
                      ""
                    )}

                    <span
                      className="success-text"
                      onClick={() => {
                        __showModel(appointment);
                      }}
                    >
                      Gérer
                    </span>
                  </td>
                </tr>
              ))}
            {!appointments.length ? (
              <tr style={{ height: "400px" }}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
        <Modal
          isOpen={isOpen}
          ariaHideApp={false}
          onRequestClose={() => setIsOpen(false)}
          className="modal-container-30"
          overlayClassName="overlay-modal"
        >
          <h2 className="modal-title mb-60">
            Rendez-vous N°{modelData.number}
          </h2>
          <div className="dashboard-content-body">
            <div className="d-flex space-between">
              <div>
                <strong>Nom du Client :</strong>
              </div>
              <div className="f-500">
                {modelData.personal?.firstName +
                  " " +
                  modelData.personal?.lastName}
              </div>
            </div>
            <div className="d-flex space-between">
              <div>
                <strong>Immatriculation :</strong>
              </div>
              <div className="f-500">{modelData.vehicule?.PLATE}</div>
            </div>
            <div
              className="d-flex"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "50px",
                gap: "30px",
              }}
            >
              {modelData.status === "UNCONFIRMED" ? (
                <>
                  <button
                    onClick={() => __confirmAppointment(modelData)}
                    className="btn btn-success w-100"
                  >
                    Confirmer
                  </button>
                  <button
                    onClick={() => __cancelAppointment(modelData)}
                    className="btn btn-danger w-100"
                  >
                    Annuler
                  </button>
                </>
              ) : modelData.status === "CONFIRMED" ? (
                <>
                  <span
                    style={{ color: "RGB(37, 185, 104)", fontWeight: "600" }}
                  >
                    Confirmè le{" "}
                    {moment(modelData.confirmed_at).format("DD/MM/y h:mm")}
                  </span>
                  <br />
                  <br />
                  <button
                    onClick={() => __finishAppointment(modelData)}
                    className="btn btn-danger w-100"
                  >
                    Terminer
                  </button>
                </>
              ) : modelData.status === "DONE" ? (
                <span style={{ color: "RGB(37, 185, 104)", fontWeight: "600" }}>
                  Terminer le{" "}
                  {moment(modelData.finish_at).format("DD/MM/y h:mm")}
                </span>
              ) : (
                <span style={{ color: "red", fontWeight: "600" }}>
                  Annuler le{" "}
                  {moment(modelData.deleted_at).format("DD/MM/y h:mm")}
                </span>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Appointments;
