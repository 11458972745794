import React from 'react';
import { getData, getObject, removeData, storeData, storeObject } from '../helpers/local-storage';

import DataContext from './data-context';

function DataProvider ({children}) {
    const [state, dispatch] = React.useReducer(
        (prevState, action) => {
            switch (action.type) {
                case 'RESTORE_DATA':
                    return {
                        ...prevState,
                        token: action.token,
                        vehicule: action.vehicule
                    };
                case 'LOGIN':
                    return {
                        ...prevState,
                        token: action.token
                    };
                case 'LOGOUT':
                    return {
                        ...prevState,
                        token: null
                    };
                case 'STORE_VEHICULE':
                    return {
                        ...prevState,
                        vehicule: action.vehicule
                    };
                case 'STORE_CITYCODE':
                    return {
                        ...prevState,
                        cityCode: action.cityCode
                    };
                case 'STORE_CATEGORY':
                    return {
                        ...prevState,
                        category: action.category
                    };
                case 'STORE_TYPEID':
                    return {
                        ...prevState,
                        typeId: action.typeId
                    };
            }
        },
        {
            token: null,
            vehicule: null
        },
    );

    React.useEffect(() => {
        function initialize () {
            dispatch({type: 'RESTORE_DATA', token: getData('token'), vehicule: getObject('vehicule'),  cityCode: getObject('cityCode'), category: getObject('category'), typeId : getObject('typeId')} );
        }

        initialize();
    }, []);

    const dataContext = React.useMemo(() => ({
        login: async (token) => {
            storeData('token', token);
            dispatch({type: 'LOGIN', token: token});
        },
        logout: async () => {
            removeData('token');
            dispatch({type: 'LOGOUT', token: null});
        },
        setVehicule: async (vehicule) => {
            storeObject('vehicule', vehicule);
            dispatch({type: 'STORE_VEHICULE', vehicule: vehicule});
        },
        setCityCode: async (cityCode) => {
            storeObject('cityCode', cityCode);
            dispatch({type: 'STORE_CITYCODE', cityCode: cityCode});
        },
        setCategory: async (category) => {
            storeObject('category', category);
            dispatch({type: 'STORE_CATEGORY', category: category});
        },
        setTypeId: async (typeId) => {
            storeObject('typeId', typeId);
            dispatch({type: 'STORE_TYPEID', typeId: typeId});
        },
    }), [], );

    return (
        <DataContext.Provider value={{state, dataContext}}>
            {children}
        </DataContext.Provider>
    );
}

export default DataProvider;