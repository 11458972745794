import React from 'react';

import './styles.css';

function InfoCard ({count, text, path, route}) {
    return(
        <a href={route} className='card-content'>
                <div className='card-text'>
                    <span>{count}</span>
                    <p>{ text }</p>
                </div>
                <div className='card-image'>
                    <img src={path} />
                </div>
        </a>
    )
}

export default InfoCard;