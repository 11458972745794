import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import api from "../../../../infrastructure/services/api";

import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";

import SearchBar from "../../../components/SearchBar";
import CityCard from "../../../components/Cards/CityCard";
import Footer from "../../../components/Footer";
import FeedbackCard from "../../../components/Cards/FeedbackCard";
import BrandCarousel from "../../../components/Cards/BrandCarousel";
import PrestationCard from "../../../components/Cards/PrestationCard";

import dashedLigne from "../../../assets/icons/dashed-line.svg";
import SellIcon from "../../../assets/icons/sell.svg";
import StarIcon from "../../../assets/icons/star.svg";
import CalendarFillIcon from "../../../assets/icons/calendar_fill.svg";
import SearchIcon from "../../../assets/icons/search.svg";
import ValidIcon from "../../../assets/icons/valid.svg";
import CarIcon from "../../../assets/icons/car.svg";

import homeBanner from "../../../assets/images/home/home-banner.png";
import homeAbout from "../../../assets/images/home/home-about.png";
import ProInfo from "../../../assets/images/home/pro-info.png";

function Home() {
  const navigate = useNavigate();

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const cities_response = api.getCities();
    cities_response.then((response) => {
      setCities(response.data.data);
    });
    const categories_response = api.getWorksListName(72524);
    categories_response.then((response) => {
      if (response?.data?.data.length) {
        setCategories(response.data.data);
      }
    });
  }, []);

  // Select Category
  const __handleCategory = (category) => {
    navigate("/search", { state: { category: category } });
  };

  return (
    <div className="container home-page">
      {/* Home Header */}
      <div className="home-header">
        <VisitorNavBar />
        <div className="home-header-banner">
          <div>
            <h1>
              Réservez un rendez vous chez un professionnel de l'automobile
            </h1>
            <hr style={{ marginLeft: "0px" }} />
            <p>Votre entretien automobile au bout des doights !</p>
          </div>
          <img src={homeBanner} alt="home" />
        </div>
        <div className="search">
          <SearchBar categories={categories} />
        </div>
      </div>

      {/* Home Punchlines */}
      <div className="home-block-container">
        <div className="home-punchlines">
          <div>
            <h5>COMMENT ÇA FONCTIONNE</h5>
            <h2>Réserver votre RDV en suivant 3 étapes simples</h2>
          </div>
          <div className="cards">
            <div className="card">
              <div className="card-icon">
                <img src={SellIcon} className="icon" alt="sell-icon" />
              </div>
              <div>
                <h5 className="card-title">Comparez les prix</h5>
                <p>Devis instantané</p>
              </div>
            </div>
            <img
              src={dashedLigne}
              className="dachedLigne"
              alt="a dached Ligne"
            />
            <div className="card">
              <div className="card-icon middle-card-icon">
                <img src={StarIcon} className="icon" alt="star-icon" />
              </div>
              <div>
                <h5 className="card-title">Choisissez un professionnel</h5>
                <p>Vérifier la réputation</p>
              </div>
            </div>
            <img
              src={dashedLigne}
              className="dachedLigne"
              alt="a dached Ligne"
            />
            <div className="card">
              <div className="card-icon">
                <img
                  src={CalendarFillIcon}
                  className="icon"
                  alt="calendar-icon"
                />
              </div>
              <div>
                <h5 className="card-title">Réservez votre RDV</h5>
                <p>Réglez votre entretien en ligne</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About Us */}
      <div className="home-block-container">
        <div className="about-us">
          <img src={homeAbout} alt="about-us" className="about-us-img" />
          <div className="container">
            <div className="header">
              <p>S'OCCUPER DE SON VEHICULE EST DEVENU SIMPLE !</p>
              <h1>
                Pourquoi prendre rendez-vous <br /> avec Cars d'Heure
              </h1>
              <hr style={{ marginLeft: "0px" }} />
              <br />
            </div>
            <div className="cards">
              <div className="item">
                <div className="card-icon">
                  <img src={SearchIcon} className="icon" alt="search-icon" />
                </div>
                <div className="details">
                  <p className="title">Rechercher</p>
                  <p className="sub-title">
                    Accéder en temps réel aux disponibilités des ateliers.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card-icon">
                  <img
                    src={CalendarFillIcon}
                    className="icon"
                    alt="calendar-icon"
                  />
                </div>
                <div className="details">
                  <p className="title">Réserver</p>
                  <p className="sub-title">
                    Réserver a tout moment un rdv chez un professionnel.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card-icon">
                  <img src={ValidIcon} className="icon" alt="valid-icon" />
                </div>
                <div className="details">
                  <p className="title">Valider</p>
                  <p className="sub-title">
                    Réalisez votre devis instantanement en choisissiant votre
                    prestation.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="card-icon">
                  <img src={CarIcon} className="icon" alt="car-icon" />
                </div>
                <div className="details">
                  <p className="title">Déposer</p>
                  <p className="sub-title">
                    Réglez votre prestation en ligne et déposez votre véhicule
                    auprès du professionnel choisi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Categories */}
      <div className="home-block-container">
        <div className="cities">
          <div className="header">
            <h2>
              Quelles prestations
              <br />
              souhaitez-vous réaliser ?
            </h2>
            <hr />
            <p>
              Avec Cars d'Heure, faciliter les interventions sur votre véhicule
              !
            </p>
          </div>
          <div className="gallery">
            {categories.length !== 0
              ? categories
                  .slice(0, 8)
                  .map((category, index) => (
                    <PrestationCard
                      key={index}
                      prestation={category}
                      onClick={() => __handleCategory(category.category)}
                    />
                  ))
              : null}
          </div>
          <button
            className="btn"
            onClick={() =>
              navigate("/search", { state: { category: null, city: null } })
            }
          >
            Voir plus de prestations
          </button>
        </div>
      </div>

      {/* Cities */}
      <div className="home-block-container">
        <div className="cities">
          <div className="header">
            <h2>Nos professionnels prés de chez vous</h2>
            <hr />
            <p>Retrouvez nos experts partout en France à tout moment</p>
          </div>
          <div className="gallery">
            {cities.slice(0.2).map((city, index) => (
              <CityCard key={index} city={city} />
            ))}
          </div>
          <button className="btn" onClick={() => navigate("/cities")}>
            Plus de villes
          </button>
        </div>
      </div>

      {/* Cars d'Heure Pro */}
      <div className="pro-info-container">
        <div className="pro-info">
          <div className="pro-info-details">
            <h1>
              Vous êtes professionnel
              <br />
              de l'automobile?
            </h1>
            <h3>Renforcez votre confort de travail grâce à Cars d'Heure</h3>
            <ul>
              <li>
                Augmentez votre activité grâce à une meilleure visibilité en
                ligne
              </li>
              <li>
                Améliorez votre quotidien en réduisant les appels téléphoniques
              </li>
              <li>Facilitez aux automobilistes l'accès à vos prestations</li>
            </ul>
            <button
              className="btn btn-dashboard"
              onClick={() => navigate("/workshop")}
            >
              Cars d'Heure Pro
            </button>
          </div>
          <img src={ProInfo} alt="pro-info" />
        </div>
      </div>

      {/* Brands */}
      <div className="home-block-container">
        <div className="feedbacks">
          <BrandCarousel />
        </div>
      </div>

      {/* Feedbacks */}
      <div className="home-block-container">
        <div className="feedbacks">
          <FeedbackCard />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
