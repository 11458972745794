import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './styles.css';

import api from '../../../infrastructure/services/api';

function Activate () {
    let { token } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const response = api.activateAccount(token);
        response.then((response) => {
            if (response.data.success) {
                navigate(response.data.route);
            }
            else {
                setError('Activation de votre compte a été échoué !');
            }
        });
        setLoading(false);
    }, []);

    return(
        <div className='loading-content'> 
            {loading ?
                <h1 className='loading-text'>Chargement...</h1>
            :
                <h1 className='loading-text'>{error}</h1>
            }
        </div>
    )
}

export default Activate;