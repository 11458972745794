import React, { useState, useEffect } from 'react';
import SearchIcon from '../../../assets/icons/search-header.svg';
import NotificationIcon from '../../../assets/icons/notification.svg';
import Modal from "react-modal";
import './styles.css';
import PictureIcon from "../../../assets/icons/upload-image.svg";
import api from '../../../../infrastructure/services/api';
import CalendarIcon from '../../../assets/icons/calendar-default.svg';
import PlusIcon from '../../../assets/icons/plus-circle.svg';
import feedbackIcon from '../../../assets/icons/feedback-icon.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function DashboardHeader({ btnText, onClick }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        __getMyNotification();
    }, []);

    
    // Get My Notification
    const __getMyNotification = () => {
        const response = api.getMyNotification();
        response.then((response) => {
            if (response.status === 200) {
                setNotifications(response.data);
            }
        });

    };

    const __notificationIsOpened = () => {
        setIsNotificationOpen(!isNotificationOpen)
        api.notificationIsOpened();
        __getMyNotification()
    }





    return (
        <div className='dashbord-header-container'  onClick={() => isNotificationOpen && setIsNotificationOpen(!isNotificationOpen)}>
            {/* {btnText && onClick && 
                <button className='btn btn-dashboard' onClick={onClick}>{btnText}</button>
            } */}
            <div className='dashbord-header-btn'>
                <button className='btn dark md-block' onClick={()=> navigate("/")}>Prendre un rendez-vous</button>
                <button className='btn dark md-hidden' onClick={()=> navigate("/")}><img src={PlusIcon} width={20}/></button>
            </div>

            <div className='dashbord-header-option'>

                <div className='dashbord-header-notification' onClick={() => __notificationIsOpened()} >
                    <img src={NotificationIcon} />
                    <div className='notification-count-container'>
                        <span className='notification-count'>{ notifications.length }</span>
                        {
                            isNotificationOpen ?
                            <div className='notification-card' >
                            {
                                (notifications.length) ? 
                                notifications.map((e, index) => {
                                    return (
                                     <span className='notification'>
                                        <span className='notification-icon'><img src={(e.type === "appointment") ? CalendarIcon : feedbackIcon}/></span>                                      
                                        <span className='notification-content'>
                                            <span className='notification-text'>{e.content}</span>
                                            <span className='notification-date'>{moment(e.created_at).format("DD/MM/y HH:mm")}</span>
                                        </span>
                                     </span>
                                    )
                                }) : 
                                <span className='notification'>
                                <span className='notification-content'>
                                    <span className='notification-text'>vous avez 0 notifications</span>
                                </span>
                                 </span>
                            }
                        </div> : null
                        }
                    </div>
                </div>
                <div className='vertical-bar'></div>
                <div className=''>
                    <div className='dashbord-header-avatar'>C</div>
                </div>
            </div>
            

        </div>
    )
}

export default DashboardHeader;