const accordions = [
    {
        id: 1,
        title: 'Quels critères sont pris en compte pour la sélection des garages partenaires de votre site ?',
        description: "Notre processus de sélection des garages partenaires repose sur des critères rigoureux qui incluent la conformité à nos normes de transparence, d'engagement envers la comparaison équitable, et la volonté constante d'améliorer la qualité de service pour satisfaire au mieux les attentes de leurs clients. Les garages qui partagent notre vision sont choisis pour rejoindre notre réseau de partenaires."
    },
    {
        id: 2,
        title: "Utilisez-vous des pièces d'origine dans les réparations automobiles ?",
        description: "Nous privilégions l'utilisation de pièces automobiles de haute qualité, qui peuvent être des pièces d'origine ou des pièces de rechange de haute gamme. Cela nous permet de proposer des solutions adaptées à divers besoins et budgets, tout en maintenant des standards élevés de qualité et de fiabilité pour les réparations de votre véhicule."
    }
]

export default accordions;