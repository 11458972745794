import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import SideBarItem from "./SideBarItem";
import logo from "../../assets/images/white-logo.png";
import Menu from "../../assets/icons/menu.jsx";
import LogoutLogo from "../../assets/icons/logout.svg";

import { DataContext } from "../../../application";

function SideBar({ menu, active, navigate }) {
  const nav = useNavigate();
  const { dataContext } = React.useContext(DataContext);

  const __logout = () => {
    dataContext.logout();
    nav("/");
  };

  const [sidebarStatus, setSidebarStatus] = useState("false"); // false === hidden && true === shown

  const handleSidebar = () => {
    if (sidebarStatus) {
      setSidebarStatus((sidebarStatus) => false);
    } else {
      setSidebarStatus((sidebarStatus) => true);
    }
  };

  return (
    <nav
      className="sidebar"
      style={{
        transform: `${sidebarStatus ? "translateX(0px)" : "translateX(-100%)"}`,
      }}
    >
      <Menu
        color={sidebarStatus ? "#ffffff" : "#5127c5"}
        handleSidebar={handleSidebar}
      />
      <div className="sidebar-container">
        <div className="sidebar-logo-container">
          <img src={logo} alt="logo" />
        </div>
        <div className="sidebar-container">
          <div className="sidebar-items">
            {menu.map((item, index) => (
              <div key={index} onClick={() => navigate(item.id)}>
                <SideBarItem active={item.id === active} item={item} />
              </div>
            ))}
          </div>
          <div className="sidebar-footer" onClick={() => __logout()}>
            <span className="sidebar-item-label">Déconnexion</span>
            <img
              src={LogoutLogo}
              alt="icon-logout"
              className="sidebar-item-icon"
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default SideBar;
