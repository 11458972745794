import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import "./styles.css";
import workshop_menu from "../../../constants/workshop-menu";
import SideBar from "../../../components/SideBar";
import Appointments from "./Appointments";
import Services2 from "./Services2";
import Profile from "./My_Account";
import BusinessAccount from "./BusinessAccount";
import Orders from "./Orders";
import CalendarAppointments from "./Calendar_Appointments";
import Home from "./Home";
import Reviews from "./Reviews";

function Dashboard() {
  const location = useLocation();

  const [active, setActive] = useState(1);

  useEffect(() => {
    workshop_menu.forEach((element) => {
      if (location.pathname === element.path) {
        setActive(element.id);
      }
      if (element?.child) {
        let data = element.child.filter((e) => e.path === location.pathname);
        if (data.length) {
          setActive(element.id);
        }
      }
    });
  }, []);

  const __navigate = (id) => {
    setActive(id);
  };

  return (
    <div className="dashboard-container">
      <SideBar menu={workshop_menu} active={active} navigate={__navigate} />
      <div className="dashboard-body">
        <Routes>
          <Route path="*" element={<Appointments />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/calendar" element={<CalendarAppointments />} />
          <Route exact path="/appointments" element={<Appointments />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/services" element={<Services2 />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/account" element={<BusinessAccount />} />
          <Route exact path="/reviews" element={<Reviews />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
