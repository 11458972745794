// Validate Name
export function validateName (name) {
    const name_format = /^[A-Z][a-z]{2,16}$/i;
    return name_format.test(String(name));
}
// Validate Email
export function validateEmail (email) {
    const email_format = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return email_format.test(String(email).toLowerCase());
}
// Validate Phone Number
export function validatePhoneNumber (phoneNumber) {
    const phoneNumber_format = /^([\+]?33[-]?|[0])?[1-9][0-9]{8}$/i;
    return phoneNumber_format.test(String(phoneNumber));
}
// Validate Password
export function validatePassword(password) {
    if (password.length >= 8 && password.length <=20
        && password.match(/[a-z]/g) 
        && password.match(/[A-Z]/g) 
        && password.match(/[0-9]/g)) {
            return true;
    }
    else {
        return false;
    }
}
// Validate Registration Number
export function validateRegistrationNumber (registrationNumber) {
    const registrationNumber_format = /^[A-Z]{2}[0-9]{3}[A-Z]{2}$/;
    return registrationNumber_format.test(String(registrationNumber));
}// Validate Address
export function validateAddress(address){
    if (!address.geolocation.type) return false 
    if (address.geolocation.coordinates.length <= 0) return false 
    if (!address.label) return false 
    if (!address.city) return false 
    // if (!address.context) return false 
    // if (!address.post_code) return false 
    if (!address.street) return false 
    // if (!address.house_number) return false
    return true 
}