import CalendarIcon from '../assets/icons/calendar-menu.svg';
import OrderIcon from '../assets/icons/order.svg';
import StoreIcon from '../assets/icons/store.svg';
import CashIcon from '../assets/icons/cash.svg';
import InfoIcon from '../assets/icons/info.svg';
import UserIcon from '../assets/icons/user.svg';
import HomeIcon from '../assets/icons/home.svg';
import PlusIcon from '../assets/icons/plus-circle.svg';
import RepairIcon from '../assets/icons/repair.svg';

const workshop_menu = [
    {
        id: 1,
        icon: HomeIcon,
        path: '/workshop/dashboard',
        title: 'Accueil',
    },
    {
        id: 4,
        icon: PlusIcon,
        path: 'null',
        title: 'Cars d\'Heure',
        child : [
            {
                path: '/workshop/dashboard/reviews',
                title: 'Mes retours',
            },
        ] 
    },
    ,
    {
        id: 3,
        icon: CalendarIcon,
        path: '/workshop/dashboard/appointments',
        title: 'Mes RDV',
    },
    {
        id: 5,
        icon: RepairIcon,
        path: '/workshop/dashboard/Services',
        title: 'Mes Prestations',
    },
    // {
    //     id: 5,
    //     icon: StoreIcon,
    //     path: '/workshop/dashboard/services',
    //     title: 'Mes prestations',
    // },
    // {
    //     id: 6,
    //     icon: CashIcon,
    //     path: '/workshop/dashboard/checkouts',
    //     title: 'Mes demandes de retrait',
    // },
    {
        id: 7,
        icon: InfoIcon,
        path: '/workshop/dashboard/account',
        title: 'Mes infos',
    },
    {
        id: 8,
        icon: UserIcon,
        path: '/workshop/dashboard/profile',
        title: 'Mon compte',
    },
]

export default workshop_menu;