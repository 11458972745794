import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import Cities from "./Cities";
import Search from "./Search";
import WorkshopProfile from "./WorkshopProfile";
import MakeAppointment from "./MakeAppointment";
import ConfirmAppointment from "./ConfirmAppointment";
import NotFound from "../404";
import Cgu from "./Cgu";
import Rgpd from "./Rgpd";
import Faq from "./Faq";
import Contact from "./Contact";

import ProtectedRoutes from "./ProtectedRoutes";

function Visitor() {
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <ProtectedRoutes>
              <Home />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/cities"
          element={
            <ProtectedRoutes>
              <Cities />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/search"
          element={
            <ProtectedRoutes>
              <Search />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/workshop-services/:id"
          element={
            <ProtectedRoutes>
              <WorkshopProfile />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/make-appointment"
          element={
            <ProtectedRoutes>
              <MakeAppointment />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/confirm-appointment"
          element={
            <ProtectedRoutes>
              <ConfirmAppointment />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/cgu"
          element={
            <ProtectedRoutes>
              <Cgu />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/politique-de-protection-des-donnees-personnelles"
          element={
            <ProtectedRoutes>
              <Rgpd />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/faq"
          element={
            <ProtectedRoutes>
              <Faq />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <ProtectedRoutes>
              <Contact />
            </ProtectedRoutes>
          }
        />
        <Route
          exact
          path="*"
          element={
            <ProtectedRoutes>
              <NotFound />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </div>
  );
}

export default Visitor;
