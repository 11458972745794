import axios from 'axios';
import { ApiConfig } from '../env';

const axiosInstance = axios.create({
  baseURL: ApiConfig.API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default axiosInstance;