import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';
import { ApiConfig } from '../../../../infrastructure/services/env';

function CityCard ({city}) { 
    const navigate = useNavigate();
    
    return(
        <figure onClick={() => navigate(`/search?city=${city.cityName}&dp=${city.code}`)} >
            <div className="wallpaper">
                <img src={ApiConfig.IMAGES + city.cityPicture} alt={city.cityName} />
                <h2 className="title">{city.cityName}</h2>
            </div>
        </figure>
    )
}

export default CityCard;