import React from "react";

import "./styles.css";
import HelpPicture from "../../../assets/images/help.png";

function HelpCard() {
  return (
    <div className="help-card">
      <img alt="help-picture" src={HelpPicture} />
      <div className="help-details">
        <h1>Besoin de pièces ?</h1>
        <p>
          Si vous avez besoin d'aide, n'hésitez pas à nous contacter ou bien
          nous appeler !
        </p>
      </div>
      <div className="help-buttons">
        <a
          className="btn btn-dashboard"
          href="tel:+33668784588"
          style={{ textDecoration: "unset" }}
        >
          Commander des pièces
        </a>
        <a
          className="btn btn-dashboard"
          style={{ backgroundColor: "#C17FEF", textDecoration: "unset" }}
          href="tel:+33668784588"
        >
          +33 6 68 78 45 88
        </a>
      </div>
    </div>
  );
}

export default HelpCard;
