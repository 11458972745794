import React from "react";

import "./styles.css";

import slide1 from "../../../assets/images/brands/bosch.png";
import slide2 from "../../../assets/images/brands/valeo.png";
import slide3 from "../../../assets/images/brands/varta.png";
import slide4 from "../../../assets/images/brands/delphi.png";
import slide5 from "../../../assets/images/brands/brembo.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper/modules";

function BrandCarousel() {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      loop={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      modules={[Pagination, Navigation, Autoplay]}
      lazy="true"
      style={{
        "--swiper-navigation-color": "#5127c5",
        "--swiper-pagination-color": "#5127c5",
        "--swiper-navigation-size": "35px",
      }}
      breakpoints={{
        450: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      }}
    >
      <SwiperSlide>
        <div className="flex swiper-img">
          <img className="d-block w-100 " src={slide1} alt="Bosch logo" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="flex swiper-img">
          <img className="d-block w-100" src={slide2} alt="Valeo logo" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="flex swiper-img">
          <img className="d-block w-100" src={slide3} alt="Varta logo" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="flex swiper-img">
          <img className="d-block w-100" src={slide4} alt="Delphi logo" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="flex swiper-img">
          <img className="d-block w-100" src={slide5} alt="Brembo logo" />
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default BrandCarousel;
