import OrderIcon from '../assets/icons/order.svg';
import CashIcon from '../assets/icons/cash.svg';
import LocationIcon from '../assets/icons/location.svg';
import UserIcon from '../assets/icons/user.svg';

const admin_menu = [
    {
        id: 1,
        icon: OrderIcon,
        path: '/admin/dashboard/orders',
        title: 'Commandes',
    },
    {
        id: 2,
        icon: UserIcon,
        path: '/admin/dashboard/workshops',
        title: 'inscriptions des garagistes',
    },
    {
        id: 3,
        icon: CashIcon,
        path: '/admin/dashboard/checkouts',
        title: 'Demandes de retrait',
    },
    {
        id: 4,
        icon: LocationIcon,
        path: '/admin/dashboard/cities',
        title: 'Villes',
    }
]

export default admin_menu;