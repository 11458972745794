import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import "./styles.css";
import DashboardHeader from "../../../components/Headers/Driver";
import CancelIcon from "../../../assets/icons/cancel.svg";
import Loading from "../../../components/Loading";
import api from "../../../../infrastructure/services/api";
import { ApiConfig } from "../../../../infrastructure/services/env";

function Appointments() {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const response = api.getMyAppointments();
      response.then((response) => {
        setAppointments(response.data.data.personal.appointments);
        setLoading(false);
      });
    }, 2000);
  }, []);

  const __dateFormat = (date) => {
    const newFormat = new Date(date);
    let day = newFormat.toLocaleString("FR-fr", { weekday: "long" });
    let month = newFormat.toLocaleString("FR-fr", { month: "long" });
    return (
      day +
      " " +
      newFormat.getDate() +
      " " +
      month +
      " " +
      newFormat.getFullYear()
    );
  };

  const __TimeFormat = (date) => {
    const newFormat = new Date(date);
    return (
      newFormat.getHours() +
      ":" +
      String(newFormat.getMinutes()).padStart(2, "0") +
      " h"
    );
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader
        btnText="Prenez un rendez-vous"
        onClick={() => navigate("/")}
      />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Mes Rendez-vous</h2>
        </div>

        <div className="driver-appointments-container">
          {appointments
            .slice(0)
            .reverse()
            .map((appointment, i) => {
              return (
                <div
                  key={appointment._id}
                  className="driver-appointment-container"
                >
                  <div className="driver-appointment-row">
                    <div className="driver-appointment-column">
                      <p className="driver-appointment-sub-title">
                        Nom du garage
                      </p>
                      <h5 className="driver-appointment-title">
                        {appointment.business.business.workshop_name}
                      </h5>
                    </div>
                  </div>
                  <div className="driver-appointment-details">
                    <div className="driver-appointment-column">
                      <p className="driver-appointment-text-bold">Date/Heure</p>
                      <p className="driver-appointment-text">
                        {__dateFormat(appointment.start_date_time)}
                        <br />à {__TimeFormat(appointment.start_date_time)}
                      </p>
                    </div>
                    <div className="driver-appointment-column">
                      <p className="driver-appointment-text-bold">
                        Immatriculation
                      </p>
                      <p className="driver-appointment-text">
                        {appointment.vehicule.PLATE}
                        <br />
                        {appointment.vehicule.NAME}
                      </p>
                    </div>

                    <div className="driver-appointment-column">
                      <p className="driver-appointment-text-bold">
                        Type de prestation
                      </p>
                      <p className="driver-appointment-text">
                        {appointment.prestations.map((prestation, index) => {
                          return (
                            <span key={index}>
                              <span>{prestation.title}</span>
                              <br />
                            </span>
                          );
                        })}
                      </p>
                    </div>

                    <div className="driver-appointment-column">
                      <p className="driver-appointment-text-bold">Status</p>
                      {appointment.status === "CONFIRMED" ? (
                        <p className="driver-appointment-text success-text">
                          Confirmé
                        </p>
                      ) : appointment.status === "DONE" ? (
                        <p className="driver-appointment-text success-text">
                          Terminé
                        </p>
                      ) : appointment.status === "UNCONFIRMED" ? (
                        <p className="driver-appointment-text warning-text">
                          Confirmation du garagiste en cours
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <p className="driver-appointment-text-bold">Pièces</p>
                    {appointment.pieceOrders.map((pieceOrder, index) => {
                      return (
                        <span key={index}>
                          <span>{pieceOrder.pieceLib}</span>
                          <br />
                          <span>
                            Ref: {pieceOrder.pieceRef}
                            <br />
                            Prix: {pieceOrder.price}€ TTC
                          </span>
                          <br />
                        </span>
                      );
                    })}

                    {appointment.prestations.map((prestation, index) => {
                      return (
                        <span key={index}>
                          <br />
                          <span>{prestation.title}</span>
                          <br />
                          Prix: {parseFloat(prestation.price_t1).toFixed(2)}
                          € TTC
                          <br />
                        </span>
                      );
                    })}
                    <br />
                    <p className="driver-appointment-text-bold">
                      Prix Total:{" "}
                      {parseFloat(appointment.price / 100).toFixed(2)}€ TTC
                    </p>
                    <a
                      target="_blank"
                      style={{ color: "red" }}
                      href={ApiConfig.INVOICE + appointment.number + ".pdf"}
                      className="driver-appointment-text-bold"
                    >
                      Voir ma facture
                    </a>
                  </div>
                  <div className="driver-appointment-column relative">
                    {/* <img className='btn-img' src={CancelIcon} /> */}
                    {/* <button className='btn btn-full p-1'>Réclamation</button> */}
                  </div>
                </div>
              );
            })}
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "400px",
              }}
            >
              <ReactLoading
                type={"spin"}
                color="#562ac6"
                height={80}
                width={80}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Appointments;
