import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "./styles.css";
import api from "../../../../infrastructure/services/api";

import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import Checkout from "./Checkout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  getData,
  getObject,
  removeData,
  storeData,
  storeObject,
} from "../../../../application/helpers/local-storage";
import { DataContext } from "../../../../application";

const stripePromise = loadStripe(
  "pk_test_51LhWB0F7h0FgeZpm2B8diTOfplZtQq8Bu9fzQcENSuA0zJDFeibGqHd53GUgupNIBpft8OCNNIl5td1sDiPNCtY400qBGjAf41"
);

function ConfirmAppointment() {
  let location = useLocation();
  const { state } = React.useContext(DataContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [workshop, setWorkshop] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [clientSecret, setClientSecret] = React.useState(null);
  const [totalPrice, setTotalPrice] = useState(null);

  useEffect(() => {
    setWorkshop(location.state.workshop);
    setAppointment(location.state.appointment);
    let totalPrice = 0;
    let servicePrice = 0;
    location.state.appointment.prestations.map((prestation, i) => {
      servicePrice += parseFloat(prestation.prestationPrice);
      totalPrice += parseFloat(
        prestation.piece_price.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0)
      );
    });
    setTotalPrice(totalPrice + servicePrice);
    setLoading(false);
  }, []);

  // Book
  const __book = () => {
    const new_appointment = {
      business: appointment.business,
      prestation: appointment.prestations,
      start_date_time: appointment.start_date_time,
      end_date_time: appointment.end_date_time,
      payment_method: "pod",
      vehicule: state.vehicule,
    };

    if (localStorage.getItem("token") !== null) {
      const response = api.book(new_appointment);
      response.then((response) => {
        const options = {
          clientSecret: response.data.data.clientSecret,
        };
        setClientSecret(options);
        setIsOpen(true);
      });
    } else {
      new_appointment.isBack = false;
      storeObject("new_appointment", new_appointment);
      navigate("/driver/login");
    }
  };

  const __dateFormat = (date) => {
    const newFormat = new Date(date);
    let day = newFormat.toLocaleString("FR-fr", { weekday: "long" });
    let month = newFormat.toLocaleString("FR-fr", { month: "long" });
    return (
      day +
      " " +
      newFormat.getDate() +
      " " +
      month +
      " " +
      newFormat.getFullYear()
    );
  };

  const __TimeFormat = (date) => {
    const newFormat = new Date(date);
    return (
      newFormat.getHours() +
      ":" +
      String(newFormat.getMinutes()).padStart(2, "0")
    );
  };

  return (
    <div>
      {/* NavBar */}
      <VisitorNavBar />

      {!loading ? (
        <div>
          <div className="appointment-container">
            {/* <h4>Bravo !</h4>
                        <p className='sub-title'>Lorem ipsum dolor sir amet, consetetur sadipscing.</p> */}

            <div className="appointment-details">
              {/* <h5>Le {appointment.start_date_time.substring(0, 10)}</h5> */}
              <h5>{__dateFormat(appointment?.start_date_time)}</h5>
              <div className="appointment-details-block">
                <div className="appointment-details-item">
                  <p>
                    Dépôt à{" "}
                    <span className="BlueViolet">
                      {__TimeFormat(appointment?.start_date_time)}
                    </span>
                  </p>

                  {/* <p className='mt-10'>Restitution <span className='BlueViolet'>le jour même en début d'après-midi</span></p> */}
                </div>

                <div className="appointment-details-bar" />

                <div className="appointment-details-item">
                  <p className="BlueViolet">
                    {workshop?.business?.workshop_name}
                  </p>
                  <p>{workshop?.business?.workshop_address.label}</p>
                  <p className="mt-10">
                    Pour votre :{" "}
                    <span className="BlueViolet">{state?.vehicule.NAME}</span>
                  </p>
                  {appointment.prestations.map((prestation, index) => {
                    return (
                      <p key={index} className="mt-10 BlueViolet">
                        - {prestation.title}
                      </p>
                    );
                  })}
                </div>
              </div>
              {/* <div className='appointment-details-block'>
                                <div className='appointment-details-item'>
                                    <p>dépôt à <span className='BlueViolet'>{appointment.start_date_time.substring(11, 16)} H</span></p>
                                    
                                    <p className='BlueViolet'>{appointment.prestation.title}</p>
                                </div>

                                <div className='appointment-details-bar'/>

                                <div className='appointment-details-item'>
                                    <p className='BlueViolet'>{workshop?.business?.workshop_name}</p>
                                    <p>{workshop.business.workshop_address?.label}</p>
                                </div>
                            </div> */}
            </div>
            {/* <p className='text-light'>Le rendez-vous en attente de retour de la part du garagiste, lorsqu'il sera confirmé vous n'aurez plus qu'à valider et déposer le véhicule</p>
                        <div className='d-flex' style={{gap: "35px"}}>
                            <button className='btn btn-full'><FontAwesomeIcon icon={faClock} size="lg"/>Déplacer</button>
                            <button className='btn btn-full'><FontAwesomeIcon icon={faCircleXmark} size="lg"/>Annuler</button>
                        </div> */}
          </div>
          <div className="appointment-footer">
            {totalPrice ? (
              <>
                <p className="appointment-footer-date">
                  {__dateFormat(appointment?.start_date_time)} à{" "}
                  {__TimeFormat(appointment?.start_date_time)}
                </p>
                <p className="appointment-footer-price">
                  {totalPrice.toFixed(2)} €
                </p>
                <button className="btn" onClick={() => __book()}>
                  Continuer
                </button>
              </>
            ) : (
              <p>Une erreur s'est produite veuillez réessayer</p>
            )}
          </div>
        </div>
      ) : null}

      {clientSecret != null ? (
        <Modal
          isOpen={isOpen}
          ariaHideApp={false}
          onRequestClose={() => setIsOpen(false)}
          className="modal-container"
          overlayClassName="overlay-modal"
        >
          <Elements stripe={stripePromise} options={clientSecret}>
            <Checkout />
          </Elements>
        </Modal>
      ) : null}
    </div>
  );
}

export default ConfirmAppointment;
