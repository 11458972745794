import React, { useState, useEffect, useMemo } from "react";

import api from "../../../../infrastructure/services/api";

import "./styles.css";
import DashboardHeader from "../../../components/Headers/AdminDashboardHeader";
import moment from "moment";
import DataTable from "react-data-table-component";
import SearchFilter from "../../../components/Table/SearchFilter";

function Checkouts() {
  const [checkouts, setCheckouts] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const columns = [
    {
      name: "N°",
      selector: (row, index) => index + 1,
      sortable: true,
      wrap: true,
      width: "70px",
    },
    {
      name: "Nom & Prénom",
      selector: (row) =>
        `${row.business.lastName + " " + row.business.firstName}`,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Nom de l'atelier",
      selector: (row) => row.business.business.workshop_name,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "RIB",
      selector: (row) => (
        <div style={{ margin: "15px 0" }}>
          Nom: {row.business.business.paiement_infos.banck_account_name}
          <br />
          N°:
          {row.business.business.paiement_infos.banck_account_num}
          <br />
          Banque: {row.business.business.paiement_infos.bank_name}
          <br />
          IBAN: {row.business.business.paiement_infos.iban}
          <br />
          SWIFT: {row.business.business.paiement_infos.swift}
          <br />
        </div>
      ),
      sortable: true,
      wrap: true,
      width: "180px",
    },
    {
      name: "Montant",
      selector: (row) => `${parseFloat(row.amount / 100).toFixed(2) + " €"}`,
      wrap: true,
      width: "100px",
    },
    {
      name: "Date",
      selector: (row) => moment(row.created_at).format("DD/MM/y hh:mm"),
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) =>
        row.status === "WAITING"
          ? "en attente"
          : row.status === "ACCEPTED"
          ? "accepté " + moment(row.updated_at).format("DD/MM/y hh:mm")
          : "Refusé   " + moment(row.updated_at).format("DD/MM/y hh:mm"),
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Options",
      selector: (row) =>
        row.status === "WAITING" ? (
          <>
            <button
              className="action-btn success"
              onClick={() => __acceptCheckout(row._id)}
            >
              Accepter
            </button>
            <button
              className="action-btn danger"
              onClick={() => __declineCheckout(row._id)}
            >
              Refuser
            </button>
          </>
        ) : row.status === "ACCEPTED" ? (
          " - "
        ) : null,
      sortable: true,
      wrap: true,
      width: "120px",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.status === "ACCEPTED",
      style: {
        backgroundColor: "rgba(63, 195, 128, 0.9)",
        color: "white",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
    {
      when: (row) => row.status === "DENIED",
      style: {
        backgroundColor: "rgba(196, 48, 6, 0.9)",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.status === "WAITING",
      style: {
        backgroundColor: "rgba(255, 179, 0, 0.9)",
        color: "white",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
  ];

  useEffect(() => {
    __getCheckouts();
  }, []);

  useEffect(() => {
    if (filterText == "") {
      setFilteredItems(checkouts);
    } else {
      setFilteredItems(
        checkouts.filter(
          (item) =>
            (item?.business?.firstName &&
              item.business.firstName
                .toString()
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item?.business?.lastName &&
              item.business.lastName
                .toString()
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item?.business?.business.workshop_name &&
              item.business.business.workshop_name
                .toString()
                .toLowerCase()
                .includes(filterText.toLowerCase())) ||
            (item?.bank_account_number &&
              item.bank_account_number
                .toString()
                .toLowerCase()
                .includes(filterText.toLowerCase()))
        )
      );
    }
  }, [filterText]);

  // Get checkouts
  const __getCheckouts = () => {
    const response = api.getAllCheckouts();
    response.then((response) => {
      setCheckouts(response.data);
      setFilteredItems(response.data);
    });
  };

  // Accept checkouts
  const __acceptCheckout = (checkout) => {
    const response = api.acceptCheckout({ checkout: checkout });
    response.then((response) => {
      __getCheckouts();
    });
  };

  const __declineCheckout = (checkout) => {
    const response = api.declineCheckout({ checkout: checkout });
    response.then((response) => {
      __getCheckouts();
    });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return <SearchFilter onFilter={(e) => setFilterText(e.target.value)} />;
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="dashboard-content">
      <DashboardHeader />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Liste des demandes de retrait</h2>
        </div>

        <div>
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            subHeader
            paginationResetDefaultPage={resetPaginationToggle}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
    </div>
  );
}

export default Checkouts;
