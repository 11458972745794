import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function NotFound () {
    const navigate = useNavigate();

  return (
    <div className="notfound-container">
        <div className="notfound">
            <div className="notfound-404">
                <h1>4<span></span>4</h1>
            </div>
            <h2>Oups! Erreur 404</h2>
            <p>La page que vous cherchez est introuvable</p>
            <p className='notfound-btn' onClick={() => navigate('/')}>retour à la page d'accueil</p>
        </div>
    </div>
  )
}

export default NotFound;