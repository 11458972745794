import React, { useState, useRef, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import "./styles.css";
import WorkshopNavBar from "../../../components/NavBars/WorkshopNavBar";

import api from "../../../../infrastructure/services/api";
import { DataContext } from '../../../../application';
import { validateEmail } from "../../../utils/input-verification";
import Alert from "../../../components/Alert";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Login() {
  const navigate = useNavigate();
  const { dataContext } = React.useContext(DataContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);
  const [queryParameters] = useSearchParams()
 
  let isRegister = ( queryParameters.get("q") === 'email') ? true : false


  useEffect(() => {
    if(isRegister){
      setAlert_message(
        (message) => "Merci de consulter votre boîte mail !"
      );
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    }
  }, []);

  

  const __login = () => {
    if (!validateEmail(email)) {
      setAlert_message((message) => "Merci de vérifier votre addresse mail.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (password.length === 0) {
      setAlert_message((message) => "Merci de vérifier votre mot de passe.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const response = api.login(email, password);
      response.then((response) => {
        if (response.status === 200) {
          var decoded_token = jwt_decode(response.data.token);
          if (decoded_token.role === "business") {
            dataContext.login(response.data.token);
            navigate("/workshop/dashboard");
          } else {
            setAlert_message(
              (message) =>
                "Vous n'êtes pas permis de se connecter en tant que garagiste !"
            );
            setAlert_type((alert_type) => "error");
            alertRef.current.show();
          }
        } else if (response.status === 201) {
          setAlert_message(
            (message) => "Merci de vérifier votre mot de passe."
          );
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }else if (response.status === 204) {
          setAlert_message((message) => "Veuillez confirmer votre email pour valider votre inscription");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        } 
      });
      response.catch((error) => {
        if (error.response.status === 403) {
          setAlert_message((message) => "Merci de vérifier votre e-mail.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }else {
          setAlert_message((message) => "Merci de vérifier votre connexion.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
    }
  };

  return (
    <div>
      <Alert type={alert_type} message={alert_message} ref={alertRef} time="10000" />
      <WorkshopNavBar />

      <div className="login-workshop-container">
        <div className="login-workshop-header">
          <h3>Connectez-vous</h3>
          <p>Renforcez votre confort de travail grâce à Cars d'Heure</p>
        </div>
        <div className="login-form">
          <div>
            <FontAwesomeIcon icon={faEnvelope} style={{ color: "#a5a5a5", }} />
            <input
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <FontAwesomeIcon icon={faLock} style={{ color: "#a5a5a5", }} />
            <input
              type="password"
              value={password}
              placeholder="Mot de passe"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button className="btn" onClick={__login}>
            Se connecter
          </button>
          <span className="divider">Ou</span>
          <button
            className="btn btn_secondary"
            onClick={() => navigate("/workshop")}
          >
            S'inscrire
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
