import React, { useState, useEffect, useRef } from "react";
// import SearchIcon from "../../../assets/icons/search-header.svg";
import NotificationIcon from "../../../assets/icons/notification.svg";
// import Modal from "react-modal";
import "./styles.css";
// import PictureIcon from "../../../assets/icons/upload-image.svg";
import api from "../../../../infrastructure/services/api";
// import { faL } from "@fortawesome/free-solid-svg-icons";
import { ApiConfig } from "../../../../infrastructure/services/env";
import moment from "moment";
import CalendarIcon from "../../../assets/icons/calendar-default.svg";
import feedbackIcon from "../../../assets/icons/feedback-icon.svg";
import Alert from "../../../components/Alert";

function DashboardHeader({ btnText, onClick }) {
  // const [isOpen, setIsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  // const [categories, setCategories] = useState([]);
  // const [subCategories, setSubCategories] = useState([]);
  // const [prestationTitle, setPrestationTitle] = useState("");
  // const [category, setCategory] = useState(null);
  // const [description, setDescription] = useState("");
  // const [price_t1, setPriceT1] = useState(null);
  // const [price_t2, setPriceT2] = useState(null);
  // const [subCategory, setSubCategory] = useState(null);
  // const [criteria, setCriteria] = useState(null);
  // const [price_t3, setPriceT3] = useState(null);
  const [image, setImage] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  useEffect(() => {
    __getMyBusinessProfile();
    __getMyNotification();
  }, []);

  // Get My Notification
  const __getMyNotification = () => {
    const response = api.getMyNotification();
    response.then((response) => {
      if (response.status === 200) {
        setNotifications(response.data);
      }
    });
  };

  // Get My Business Profile
  const __getMyBusinessProfile = () => {
    const response = api.getMyBusinessProfile();
    response.then((response) => {
      if (response.data.data.business.logo) {
        setLogoURL(response.data.data.business.logo);
      }
      if (
        response.data.data.business.schedule.length == 0 ||
        !response.data.data.business.business_infos?.work_years ||
        !response.data.data.business.business_infos?.since ||
        !response.data.data.business.business_infos?.phoneNumber ||
        !response.data.data.business.business_infos?.number_of_workers ||
        !response.data.data.business.business_infos?.manager_name
      ) {
        setAlert_message(
          (message) => "Merci de bien vouloir compléter votre profil."
        );
        setAlert_type((alert_type) => "error");
        alertRef.current.show();
      } else if (response.data.data.business.itemsMpId.length == 0) {
        setAlert_message(
          (message) => "Merci de bien vouloir ajouter des prestations."
        );
        setAlert_type((alert_type) => "error");
        alertRef.current.show();
      }
    });
  };

  // const __handleCategory = async (category) => {
  //   setCategory(category);
  //   const cat = await categories.find((cat) => cat.category === category);
  //   setTimeout(() => {
  //     if (cat) {
  //       setSubCategories(cat.sub_categories);
  //     }
  //   }, 1000);
  // };

  // const __handleSubCategory = (subCategory) => {
  //   const subCat = JSON.parse(subCategory);
  //   setCriteria(subCat.cicerone_criteria_code);
  //   setSubCategory(subCat.cicerone_family_code);
  // };

  // Update Image
  const __updatePrestationImage = (id) => {
    if (image !== null) {
      var formData = new FormData();
      formData.append("image", image);
      formData.append("prestationId", id);
      const response = api.updatePrestationImage(formData);
      response.then((response) => {
        return;
      });
    }
  };

  // Create Prestation
  // const __createPrestation = () => {
  //   if (
  //     price_t1 === null ||
  //     price_t2 === null ||
  //     price_t3 === null ||
  //     category === null ||
  //     subCategory === null ||
  //     criteria === null ||
  //     prestationTitle === null
  //   ) {
  //     setAlert_message((message) => "Merci de vérifier vos informations.");
  //     setAlert_type((alert_type) => "warning");
  //     alertRef.current.show();
  //   } else {
  //     const service = {
  //       category: category,
  //       prestation_title: prestationTitle,
  //       description: description,
  //       price_t1: price_t1,
  //       price_t2: price_t2,
  //       price_t3: price_t3,
  //       subCategory: subCategory,
  //       criteria: criteria,
  //     };
  //     const response = api.addPrestation(service);
  //     response.then((response) => {
  //       if (image !== "" && response?.data?.id) {
  //         __updatePrestationImage(response.data.id);
  //       }
  //       setIsOpen(false);
  //       window.location.reload();
  //     });
  //   }
  // };

  const __notificationIsOpened = () => {
    setIsNotificationOpen(!isNotificationOpen);
    api.notificationIsOpened();
    __getMyNotification();
  };

  return (
    <div className="container-header-nav">
      <div className="header-nav">
        <div className="dashbord-header-container">
          <Alert type={alert_type} message={alert_message} ref={alertRef} />

          {/* {btnText && onClick && 
                <button className='btn btn-dashboard' onClick={onClick}>{btnText}</button>
            } */}
          <div className="dashbord-header-btn">
            <a
              className="btn btn-dashboard"
              href="/workshop/dashboard/Services"
            >
              {" "}
              Ajouter Prestation
            </a>
          </div>

          <div className="dashbord-header-option">
            <div className="dashbord-header-notification">
              <img
                onClick={() => __notificationIsOpened()}
                src={NotificationIcon}
              />
              <div className="notification-count-container">
                <span className="notification-count">
                  {notifications.length}
                </span>
                {isNotificationOpen ? (
                  <div className="notification-card">
                    {notifications.length ? (
                      notifications.map((e, index) => {
                        return (
                          <span className="notification">
                            <span className="notification-icon">
                              <img
                                src={
                                  e.type === "appointment"
                                    ? CalendarIcon
                                    : feedbackIcon
                                }
                              />
                            </span>
                            <span className="notification-content">
                              <span className="notification-text">
                                {e.content}
                              </span>
                              <span className="notification-date">
                                {moment(e.created_at).format("DD/MM/y HH:mm")}
                              </span>
                            </span>
                          </span>
                        );
                      })
                    ) : (
                      <span className="notification">
                        <span className="notification-content">
                          <span className="notification-text">
                            vous avez 0 notifications
                          </span>
                        </span>
                      </span>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="vertical-bar"></div>
            <div className="">
              {logoURL !== null ? (
                <div className="dashbord-header-avatar">
                  {" "}
                  <img
                    src={ApiConfig.IMAGES + logoURL}
                    className="logo-picture"
                    alt="logo"
                    width={40}
                    height={40}
                    style={{ height: "50px", width: "50px" }}
                  />
                </div>
              ) : (
                <div className="dashbord-header-avatar">C</div>
              )}
            </div>
          </div>

          {/* <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            onRequestClose={() => setIsOpen(false)}
            className="modal-container-35"
            overlayClassName="overlay-modal"
          >
            <h2 className="modal-title">Ajouter une prestation</h2>
            <div className="dashboard-content-body">
              <div className="form-row">
                <p className="strong">Titre de prestation</p>
                <input
                  type="text"
                  value={prestationTitle}
                  onChange={(e) => setPrestationTitle(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Catégorie</p>
                <select
                  className="modal-input"
                  onChange={(e) => __handleCategory(e.target.value)}
                >
                  <option value="" disabled selected>
                    Catégorie
                  </option>
                  {categories.map((categ, index) => (
                    <option value={categ.category} key={index}>
                      {categ.category_name}
                    </option>
                  ))}
                </select>
              </div>
              {subCategories.length ? (
                <div className="form-row">
                  <p className="strong">Sous-Catégorie</p>
                  <select
                    className="modal-input"
                    onChange={(e) => __handleSubCategory(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Sous-Catégorie
                    </option>
                    {subCategories.map((subCateg, index) => (
                      <option value={JSON.stringify(subCateg)} key={index}>
                        {subCateg.sub_category_name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                ""
              )}

              <div className="form-row">
                <p className="strong">Description</p>
                <textarea
                  rows={3}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="form-row">
                <p className="strong">Prix T1(TTC)</p>
                <input
                  type="number"
                  value={price_t1}
                  onChange={(e) => setPriceT1(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Prix T2(TTC)</p>
                <input
                  type="number"
                  value={price_t2}
                  onChange={(e) => setPriceT2(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Prix T3 (TTC)</p>
                <input
                  type="number"
                  required
                  value={price_t3}
                  onChange={(e) => setPriceT3(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Photo</p>
                <div className="card-upload">
                  <p>
                    Choisissez une photo qui représente votre projet. Max 8Mo
                  </p>
                  {image !== null ? (
                    <img
                      src={URL.createObjectURL(image)}
                      className="image-picture"
                      style={{ width: "70px", height: "70px" }}
                      alt="image"
                    />
                  ) : (
                    ""
                  )}
                  <div className="upload-btn">
                    <label htmlFor="upload-image" className="upload-label">
                      <img
                        src={PictureIcon}
                        alt="upload-icon"
                        className="upload-icon"
                      />
                      <p>Séléctionner une image</p>
                    </label>
                    <input
                      type="file"
                      id="upload-image"
                      accept="image/*"
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="form-row center"
              style={{ marginTop: "20px", marginBottom: 0 }}
            >
              <button className="btn" onClick={() => __createPrestation()}>
                Publier la prestation
              </button>
            </div>
          </Modal> */}
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
