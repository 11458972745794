import React, { useState, useEffect, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import Select from "react-select";

import api from "../../../../infrastructure/services/api";
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validatePassword,
} from "../../../utils/input-verification";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import Footer from "../../../components/Footer";

import Logo from "../../../assets/images/white-logo.png";
import ProLogo from "../../../assets/images/pro-logo.png";
import ProLogoWhite from "../../../assets/images/pro-logo-white.png";
import PlusLogo from "../../../assets/images/plus-logo.png";
import FirstCard from "../../../assets/images/register-card-1.png";
import SecondCard from "../../../assets/images/register-card-2.png";
import ThirdCard from "../../../assets/images/register-card-3.png";
import AccordionCard from "../../../components/Cards/AccordionCard";
import accordions from "../../../constants/accordions";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Alert from "../../../components/Alert";

function Register() {
  const navigate = useNavigate();

  const [cities, setCities] = useState([]);
  const [workshopName, setWorkshopName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyType, setCompanyType] = useState({
    value: "EURL",
    label: "EURL",
  });
  const [workshopAddress, setWorkshopAddress] = useState({
    label: "",
  });
  const [isValidateAddress, setIsValidateAddress] = useState(true);
  const [kbis, setKbis] = useState("");
  const [file, setFile] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [citiesOptions, setCitiesOptions] = useState([]);
  const [companyTypeOptions, setCompanyTypeOptionss] = useState([
    { value: "EURL", label: "EURL" },
    { value: "SARL", label: "SARL" },
    { value: "SASU", label: "SASU" },
    { value: "SAS", label: "SAS" },
    { value: "SA", label: "SA" },
  ]);

  const [activeAccordion, setActiveAccordion] = useState(1);
  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  const searchCitie = async (value) => {
    let result = await fetch(
      `https://api-adresse.data.gouv.fr/search/?q=${value}`
    );
    return result.json();
  };

  const __register = () => {
    if (!validateName(firstName) || !validateName(lastName)) {
      // alert('Merci de vérifier votre nom et prénom.');
      setAlert_message((message) => "Merci de vérifier votre nom et prénom.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (!validateEmail(email)) {
      setAlert_message((message) => "Merci de vérifier vos informations");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (!validatePhoneNumber(phoneNumber)) {
      setAlert_message((message) => "Merci de vérifier votre Téléphone.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (!workshopAddress?.context) {
      // alert('Merci de vérifier votre nom et prénom.');
      setAlert_message((message) => "Merci de vérifier votre Adresse.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (workshopName === "") {
      // alert('Merci de vérifier votre nom et prénom.');
      setAlert_message(
        (message) => "Merci de vérifier l'adresse de l'établissement."
      );
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (kbis === "") {
      // alert('Merci de vérifier votre nom et prénom.');
      setAlert_message((message) => "Merci de vérifier votre kbis");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (password.length === 0 || !validatePassword(password)) {
      // alert('Merci de vérifier votre mot de passe.');
      setAlert_message((message) => "Merci de vérifier votre mot de passe.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const user = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        phoneNumber: phoneNumber,
        workshopName: workshopName,
        workshopAddress: workshopAddress,
        companyType: companyType.value,
        kbis: kbis,
      };
      const response = api.workshopRegister(user);
      response
        .then((response) => {
          if (response.data.success) {
            navigate("/workshop/login?q=email");
          } else {
            setAlert_message(
              (message) => "Merci de vérifier vos informations."
            );
            setAlert_type((alert_type) => "warning");
            alertRef.current.show();
          }
        })
        .catch((error) => {
          if (
            JSON.parse(error?.request?.response)?.message ===
            "Duplicate email address"
          ) {
            setAlert_message((message) => "Address email déjà utiliser.");
            setAlert_type((alert_type) => "warning");
            alertRef.current.show();
          } else if (error.response.status === 403) {
            setAlert_message((message) => "Merci de vérifier votre e-mail.");
            setAlert_type((alert_type) => "warning");
            alertRef.current.show();
          } else {
            setAlert_message((message) => "Merci de vérifier votre connexion.");
            setAlert_type((alert_type) => "warning");
            alertRef.current.show();
          }
        });
    }
  };

  return (
    <div className="workshop-register-container">
      {/* Header */}
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <div className="workshop-register-header">
        <div className="workshop-register-left">
          <div className="workshop-register-left-container">
            <img src={Logo} alt="logo" onClick={() => navigate("/")} />
            <div className="text-container">
              <div className="big-logo-container">
                <img src={ProLogoWhite} alt="pro-white-logo" />
              </div>
              <ul>
                <p>Equipez-vous de notre solution pour:</p>
                <li>Développez votre chiffre d'affaires</li>
                <li>Améliorez la prise en charge de vos clients</li>
                <li>Gagnez en confort de travail</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="workshop-register-right">
          <div className="workshop-register-right-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button className="btn left-btn" onClick={() => navigate("/")}>
                <div style={{ display: "flex" }}>
                  <div>
                    <span style={{ paddingRight: "10px" }}>
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 10L3.29289 10.7071L2.58579 10L3.29289 9.29289L4 10ZM21 18C21 18.5523 20.5523 19 20 19C19.4477 19 19 18.5523 19 18L21 18ZM8.29289 15.7071L3.29289 10.7071L4.70711 9.29289L9.70711 14.2929L8.29289 15.7071ZM3.29289 9.29289L8.29289 4.29289L9.70711 5.70711L4.70711 10.7071L3.29289 9.29289ZM4 9L14 9L14 11L4 11L4 9ZM21 16L21 18L19 18L19 16L21 16ZM14 9C17.866 9 21 12.134 21 16L19 16C19 13.2386 16.7614 11 14 11L14 9Z"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                  </div>
                  <div>Page d'accueil</div>
                </div>
              </button>
              <button
                className="btn right-btn"
                style={{ height: "50px" }}
                onClick={() => navigate("/workshop/login")}
              >
                Se connecter
              </button>
            </div>
            <div className="workshop-register-form">
              <input
                type="text"
                value={workshopName}
                placeholder="Nom de l'établissement"
                onChange={(e) => setWorkshopName(e.target.value)}
              />

              <input
                autoComplete="none"
                type="text"
                placeholder="Adresse de l'établissement"
                value={workshopAddress.label}
                onChange={async (e) => {
                  if (workshopAddress.label === 0) {
                    setCitiesOptions((citiesOptions) => []);
                    return;
                  }
                  setWorkshopAddress({
                    ...workshopAddress,
                    label: e.target.value,
                  });
                  if (e.target.value.length > 3) {
                    let result = await searchCitie(e.target.value);
                    setCitiesOptions((citiesOptions) => result?.features);
                  }
                }}
                style={{ borderColor: !isValidateAddress ? "red" : "" }}
              />
              {citiesOptions.length > 0 && (
                <div className="citiesOptions">
                  {citiesOptions.map((option, index) => (
                    <p
                      key={index}
                      onClick={() => {
                        let data = {
                          city: option.properties.city,
                          context: option.properties.context,
                          street: option.properties.street,
                          post_code: option.properties.postcode,
                          house_number: option.properties.housenumber,
                          label: option.properties.label,
                          geolocation: {
                            coordinates: option.geometry.coordinates,
                            type: option.geometry.type,
                          },
                        };
                        setWorkshopAddress(data);
                        setCitiesOptions((citiesOptions) => []);
                      }}
                    >
                      {option.properties.label}
                    </p>
                  ))}
                </div>
              )}

              <div className="form-column">
                <input
                  type="text"
                  value={firstName}
                  placeholder="Prénom"
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                  type="text"
                  value={lastName}
                  placeholder="Nom de la famille"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <input
                type="email"
                value={email}
                placeholder="E-mail"
                onChange={(e) => setEmail(e.target.value)}
              />
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="FR"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />

              <Select
                isMulti={false}
                isSearchable={false}
                placeholder="Type d'entrprise"
                value={{ value: companyType.value, label: companyType.value }}
                options={companyTypeOptions}
                className="react-select"
                onChange={(e) => setCompanyType(e)}
              />
              <input
                type="text"
                value={kbis}
                placeholder="Numéro kbis"
                onChange={(e) => setKbis(e.target.value)}
              />
              <div className="relative">
                <FontAwesomeIcon icon={faLock} style={{ color: "#a5a5a5" }} />
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  placeholder="Mot de passe"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ paddingLeft: "40px" }}
                />
                {password.length ? (
                  <div
                    onClick={() => setShowPassword(!showPassword)}
                    className="show-password"
                  >
                    <FontAwesomeIcon
                      className="fa-md"
                      icon={showPassword ? faEyeSlash : faEye}
                    />
                  </div>
                ) : null}
              </div>
              {password.length ? (
                <span style={{ fontSize: "9px" }}>
                  Le mot de passe doit comporter au moins{" "}
                  <strong>8 caractères</strong> et doit comporter au moins{" "}
                  <strong>une majuscule</strong>,{" "}
                  <strong> une minuscule </strong> et{" "}
                  <strong>un caractère numérique</strong>
                </span>
              ) : null}
              {/* <div className="uploadFileCard">
                  <span>Télécharger vos données pro. Max 8 Mo.</span>
                  <input 
                    type="file"
                    id="upload"
                    accept="image/*"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                  <label htmlFor="upload">Télécharger L'image</label>
                </div> */}
            </div>
            <p>
              En cliquant sur Envoyer, vous accepter{" "}
              <a
                href="/cgu"
                title="Mentions légales et Conditions Générales d'Utilisation"
                className="conditions"
              >
                les Conditions générales d'utilisations
              </a>{" "}
              de Cas d'Heure.
            </p>
            <button className="btn" onClick={() => __register()}>
              Envoyer
            </button>
          </div>
        </div>
      </div>

      {/* Body */}
      <div className="body-container">
        {/* About */}
        <div className="workshop-register-about">
          <h2>Pourquoi Cars d'Heure ?</h2>
          <hr />
          <div className="cards-container">
            <div className="card">
              <img src={FirstCard} alt="card-1" />
              <h4>Réduisez mes RDV non honorés</h4>
              <p>
                Grâce a votre présence en ligne, finissez les appels inutiles et
                réduisez les rdv non honorés. Facilitez ainsi votre quotidien en
                économisant du temps et gagnez en rentabilité.
              </p>
            </div>
            <div className="card">
              <img src={SecondCard} alt="card-2" />
              <h4>Développez votre activité </h4>
              <p>
                Gagnez de nouveaux clients grace a une meilleure visibilité. Des
                milliers d'automobilistes sont susceptibles de rechercher un
                expert auto dans votre secteur. En acceuillant vottre
                établissement sur notre plateforme L'automobiliste, nous aidons
                a répondre.
              </p>
            </div>
            <div className="card">
              <img src={ThirdCard} alt="card-3" />
              <h4>Gardez la main </h4>
              <p>
                Les devis en ligne sont validés sur la base de vos tarifs. Vous
                gardez le contrôle sur les tarifs ou promotions mises en place.
                Gardez la main sur vos disponibilités. Encaissez à tout moment
                votre chiffre d'affaires réalisé
              </p>
            </div>
          </div>
        </div>

        {/* Solutions */}
        <div className="workshop-register-solutions">
          <h2>Découvrez nos solutions pour améliorer votre quotidien</h2>
          <hr />
          <div className="card-container">
            <div className="solution-item">
              <div className="solution-card">
                <h4>
                  La plateforme de gestion
                  <br /> des
                </h4>
                <ul style={{ height: "260px" }}>
                  <li>
                    <span>- </span>Prise de RDV en ligne pour vos automobilistes
                  </li>
                  <li>
                    <span>- </span>Outil de réduction des RDV non honorés
                  </li>
                  <li>
                    <span>- </span>Agenda flexible et sur-mesure
                  </li>
                  <li>
                    <span>- </span>Partage en ligne des devis
                  </li>
                  <li>
                    <span>- </span>Compte pro sécurisé en ligne
                  </li>
                  <li>
                    <span>- </span>Marketing de votre établissement
                  </li>
                </ul>
                <a title="Contactez nous" href="/contact" className="btn">
                  Contactez nous
                </a>
              </div>
            </div>
            <div className="solution-item">
              <div className="solution-card">
                <h4>
                  Le service supplémentaire <br />
                  pour
                </h4>
                <ul style={{ height: "260px" }}>
                  <li>
                    <span>- </span>Un large choix de piéces auto en stock
                  </li>
                  <li>
                    <span>- </span>Des professionnels a votre écoute
                  </li>
                  <li>
                    <span>- </span>Des prix compétitif
                  </li>
                  <li>
                    <span>- </span>3/4 livraisons par jour
                  </li>
                  <li>
                    <span>- </span>Facilite de paiement
                  </li>
                  <br />
                </ul>
                <a title="Contactez nous" href="/contact" className="btn">
                  Contactez nous
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Accordions */}
        <div className="accordions">
          <h2>Des question ?</h2>
          <hr />
          <div className="accordions-container">
            {accordions.map((item, index) => (
              <AccordionCard
                item={item}
                key={index}
                active={activeAccordion}
                onClick={setActiveAccordion}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Register;
