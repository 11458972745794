import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";

import "./styles.css";
import DashboardHeader from "../../../components/Headers/DashboardHeader";
import DeleteIcon from "../../../assets/icons/delete-red.svg";

import calendarIcon from "../../../assets/icons/calendar-dark.svg";
import phoneIcon from "../../../assets/icons/phone.svg";

import api from "../../../../infrastructure/services/api";
import Alert from "../../../components/Alert";
import HelpCard from "../../../components/Cards/HelpCard";
import { validateAddress } from "../../../utils/input-verification";

function BusinessAccount() {
  const [cities, setCities] = useState([]);
  const [logo, setLogo] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [cover, setCover] = useState(null);
  const [coverURL, setCoverURL] = useState(null);
  const [workshopName, setWorkshopName] = useState("");
  const [workshopAddress, setWorkshopAddress] = useState({
    label: "",
  });
  const [city, setCity] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const [numberOfWorkers, setNumberOfWorkers] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [since, setSince] = useState("");
  const [workYears, setWorkYears] = useState("");
  const [managerName, setManagerName] = useState("");
  const [access, setAccess] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [isValidateAddress, setIsValidateAddress] = useState(true);

  const alertRef = useRef(null);

  useEffect(() => {
    __getMyBusinessProfile();
  }, []);

  // Get My Business Profile
  const __getMyBusinessProfile = () => {
    const response = api.getMyBusinessInfos();
    response.then((response) => {
      let data = response.data.data.business;
      if (data.logo) {
        setLogoURL(data.logo);
      }
      if (data.cover_img) {
        setCoverURL(data.cover_img);
      }
      setWorkshopAddress({
        label: data.workshop_address.label,
        city: data.workshop_address.city,
        context: data.workshop_address.context,
        street: data.workshop_address.street,
        post_code: data.workshop_address.post_code,
        code: data.workshop_address.code,
        house_number: data.workshop_address.house_number,
        geolocation: {
          coordinates: data.workshop_address.geolocation.coordinates,
          type: data.workshop_address.geolocation.type,
        },
      });
      setManagerName(data.business_infos.manager_name);
      setCity(data.business_infos.workshop_city);
      setNumberOfWorkers(data.business_infos.number_of_workers);
      setPhoneNumber(data.business_infos.phoneNumber);
      setSince(data.business_infos.since);
      setWorkYears(data.business_infos.work_years);
      setAccess(data.business_infos.means_of_access);

      if (data.schedule.length !== 0) {
        setSchedule(data.schedule);
      } else {
        setSchedule([
          {
            day: "dimanche",
            work: false,
            slot: [{ start_time: "08:00", end_time: "18:00" }],
          },
          {
            day: "lundi",
            work: false,
            slot: [{ start_time: "08:00", end_time: "18:00" }],
          },
          {
            day: "mardi",
            work: false,
            slot: [{ start_time: "08:00", end_time: "18:00" }],
          },
          {
            day: "mercredi",
            work: false,
            slot: [{ start_time: "08:00", end_time: "18:00" }],
          },
          {
            day: "jeudi",
            work: false,
            slot: [{ start_time: "08:00", end_time: "18:00" }],
          },
          {
            day: "vendredi",
            work: false,
            slot: [{ start_time: "08:00", end_time: "18:00" }],
          },
          {
            day: "samedi",
            work: false,
            slot: [{ start_time: "08:00", end_time: "18:00" }],
          },
        ]);
      }
    });
  };

  // Update Logo
  const __updateLogo = () => {
    if (logo !== null) {
      var formData = new FormData();
      formData.append("logo", logo);
      const response = api.updateBusinessProfileLogo(formData);
      response.then((response) => {
        return;
      });
    }
  };

  // Update Cover
  const __updateCover = () => {
    if (cover !== null) {
      var formData = new FormData();
      formData.append("cover", cover);
      const response = api.updateBusinessProfileCover(formData);
      response.then((response) => {
        return;
      });
    }
  };

  // Update
  const __updateProfile = () => {
    if (workshopAddress !== "" && city !== null) {
      console.log(workshopAddress);
      const profile = {
        workshop_city: city,
        manager_name: managerName,
        workshop_address: workshopAddress,
        work_years: workYears,
        since: since,
        phoneNumber: phoneNumber,
        number_of_workers: numberOfWorkers,
        means_of_access: access,
        schedule: schedule,
      };
      const response = api.updateMyBusinessInfos(profile);
      response.then((response) => {
        return;
      });
    }
  };
  // Handle Checkbox
  const __hanldeCheckboxChange = (value) => {
    const status = !value.work;
    setSchedule((prevState) =>
      prevState.map((item) =>
        item.day === value.day ? { ...item, work: status } : item
      )
    );
  };

  // Change Start Time
  const __changeStartText = (elem, value) => {
    setSchedule((prevState) =>
      prevState.map((item) =>
        item.day === elem.day
          ? { ...item, slot: [{ ...item.slot[0], start_time: value }] }
          : item
      )
    );
  };

  // Change End Time
  const __changeEndText = (elem, value) => {
    setSchedule((prevState) =>
      prevState.map((item) =>
        item.day === elem.day
          ? { ...item, slot: [{ ...item.slot[0], end_time: value }] }
          : item
      )
    );
  };

  const __send = () => {
    if (!validateAddress(workshopAddress)) {
      setIsValidateAddress(false);
      setAlert_message((message) => "Veuillez entrer une adresse valide !");
      setAlert_type((alert_type) => "error");
      alertRef.current.show();
    } else {
      setIsValidateAddress(true);
      try {
        __updateLogo();
        __updateCover();
        __updateProfile();
        // alert('Votre profile a été bien modifié !');
        setAlert_message((message) => "Votre profile a été bien modifié !");
        setAlert_type((alert_type) => "success");
        alertRef.current.show();
      } catch (error) {}
    }
  };

  const searchCitie = async (value) => {
    let result = await fetch(
      `https://api-adresse.data.gouv.fr/search/?q=${value}`
    );
    return result.json();
  };

  return (
    <div className="dashboard-content">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <DashboardHeader />
      <HelpCard />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Mes infos</h2>
        </div>

        <div className="dashboard-content-body">
          <div className="form-row">
            <p className="strong">Adresse</p>
            <input
              autoComplete="none"
              type="text"
              placeholder="Adresse de l'établissement"
              value={workshopAddress.label}
              onChange={async (e) => {
                if (workshopAddress.label === 0) {
                  setCitiesOptions((citiesOptions) => []);
                  return;
                }

                setWorkshopAddress({
                  ...workshopAddress,
                  label: e.target.value,
                });
                if (e.target.value.length > 3) {
                  let result = await searchCitie(e.target.value);
                  setCitiesOptions((citiesOptions) => result?.features);
                }
              }}
              style={{ borderColor: !isValidateAddress ? "red" : "" }}
            />
            {citiesOptions.length > 0 && (
              <div className="citiesOptions">
                {citiesOptions.map((option, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      let data = {
                        city: option.properties.city,
                        context: option.properties.context,
                        street: option.properties.street,
                        post_code: option.properties.postcode,
                        code: option.properties.context.split(",")[0],
                        house_number: option.properties.housenumber,
                        label: option.properties.label,
                        geolocation: {
                          coordinates: option.geometry.coordinates,
                          type: option.geometry.type,
                        },
                      };
                      setWorkshopAddress(data);
                      setCitiesOptions((citiesOptions) => []);
                    }}
                  >
                    {option.properties.label}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className="form-row">
            <div className="form-row relative">
              <p className="strong">Horaires</p>
              <input
                className="with-icon"
                readOnly
                type="text"
                placeholder="Entrez vos horaires"
                onClick={() => setIsOpen(true)}
              />
              <img src={calendarIcon} />
            </div>
          </div>
          <div className="form-row relative">
            <p className="strong">Contact du garage</p>
            <input
              className="with-icon"
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <img src={phoneIcon} />
          </div>

          <div className="form-row" style={{ marginBottom: "0px" }}>
            <div className="form-column">
              <div className="form-row">
                <p className="strong">Nom du Gérant</p>
                <input
                  type="text"
                  value={managerName}
                  onChange={(e) => setManagerName(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Nombre de mécaniciens</p>
                <input
                  type="text"
                  value={numberOfWorkers}
                  onChange={(e) => setNumberOfWorkers(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="form-row" style={{ marginBottom: "0px" }}>
            <div className="form-column">
              <div className="form-row">
                <p className="strong">Année d'ouverture</p>
                <input
                  type="text"
                  value={since}
                  onChange={(e) => setSince(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Années d'expériences</p>
                <input
                  type="text"
                  value={workYears}
                  onChange={(e) => setWorkYears(e.target.value)}
                />
              </div>
            </div>
          </div>
          <p className="strong">moyens d’accès</p>

          {access.map((item, index) => (
            <div className="form-row" key={index}>
              <div
                className="form-column"
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <div className="form-row">
                  <input
                    type="text"
                    onChange={(e) => {
                      let data = [...access];
                      data[index] = e.target.value;
                      setAccess(data);
                    }}
                    value={item}
                  />
                </div>
                <div className="form-row">
                  <a
                    onClick={() => {
                      let data = [...access];
                      data = data.filter((item, i) => {
                        return i !== index;
                      });
                      setAccess(data);
                    }}
                  >
                    <img src={DeleteIcon} width={28} />
                  </a>
                </div>
              </div>
            </div>
          ))}
          <div className="form-row">
            <div className="form-column">
              <button
                className={
                  access.length && access[access.length - 1] === ""
                    ? "btn btn-dashboard disabled"
                    : "btn btn-dashboard"
                }
                disabled={access.length && access[access.length - 1] === ""}
                onClick={() => {
                  let data = [...access];
                  data.push("");
                  setAccess(data);
                }}
              >
                Ajouter un autre moyen d’accès
              </button>
            </div>
          </div>
          <div className="form-row center" style={{ marginTop: "20px" }}>
            <button
              disabled={!isValidateAddress}
              className={!isValidateAddress ? "btn disabled" : "btn"}
              onClick={() => __send()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsOpen(false)}
        className="modal-container"
        overlayClassName="overlay-modal"
      >
        <h2 className="modal-title">Entrez vos horaires</h2>
        {schedule.map((item, index) => (
          <div className="schedule-container" key={index}>
            <div>
              <input
                type="checkbox"
                checked={item.work}
                onChange={() => __hanldeCheckboxChange(item)}
              />
              <span>{item.day}: </span>
            </div>
            {item.work ? (
              <div>
                <span>de </span>
                <input
                  type="time"
                  value={item.slot[0].start_time}
                  onChange={(e) => __changeStartText(item, e.target.value)}
                />
                <span> à </span>
                <input
                  type="time"
                  value={item.slot[0].end_time}
                  onChange={(e) => __changeEndText(item, e.target.value)}
                />
              </div>
            ) : (
              <span>Fermé</span>
            )}
          </div>
        ))}
        <button className="btn btn-full" onClick={() => setIsOpen(false)}>
          Terminer
        </button>
      </Modal>
    </div>
  );
}

export default BusinessAccount;
