import React, { useState, useEffect } from "react";

import api from "../../../../infrastructure/services/api";

import "./styles.css";
import DashboardHeader from "../../../components/Headers/DashboardHeader";
import HelpCard from '../../../components/Cards/HelpCard';

import ImageBanner from '../../../../views/assets/images/montage-pneus-atelier-carter-cash.jpg';
import ImageBanner2 from '../../../../views/assets/images/mecanicien-automobile-changeant-roues-voiture.jpg';
function Orders() {
  const [orders, setOrders] = useState([
    {
      "piece_order": {
        "pieceLib": "2 PNEUS",
        "price": "79.00Є",
        "status": "payed"
      },
      "rdv": "24",
      "registration": "EE-416-BM",
      "type": "Clio 3",
      "start_date_time": "22/02/202211h30",
      "date": "31/01/23"
    },
    {
      "piece_order": {
        "pieceLib": "3 FILTRES & HUILE",
        "price": "179.00Є",
        "status": "payed"
      },
      "rdv": "24",
      "registration": "EE-416-BM",
      "type": "Clio 3",
      "start_date_time": "22/02/202211h30",
      "date": "31/01/23"
    },
    {
      "piece_order": {
        "pieceLib": "3 PNEUS",
        "price": "110.00Є",
        "status": "not_payed"
      },
      "rdv": "24",
      "registration": "EE-416-BM",
      "type": "Clio 3",
      "start_date_time": "22/02/202211h30",
      "date": "31/01/23"
    },
    {
      "piece_order": {
        "pieceLib": "3 PNEUS",
        "price": "110.00Є",
        "status": "not_payed"
      },
      "rdv": "24",
      "registration": "EE-416-BM",
      "type": "Clio 3",
      "start_date_time": "22/02/202211h30",
      "date": "31/01/23"
    },
    {
      "piece_order": {
        "pieceLib": "5 PNEUS",
        "price": "140.00Є",
        "status": "payed"
      },
      "rdv": "24",
      "registration": "EE-416-BM",
      "type": "Clio 3",
      "start_date_time": "22/02/202211h30",
      "date": "31/01/23"
    }
  ]);

  useEffect(() => {
    // const response = api.getMyOrders();
    // response.then((response) => {
    //   setOrders(response.data.data);
    // });
  }, []);

  return (
    <div className="dashboard-content">
      <DashboardHeader />
      <HelpCard />
      {/* <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h3>Mes commandes</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>N° commande</th>
              <th>Date et Heure</th>
              <th>Immatriculation</th>
              <th>Pièces</th>
              <th>Prix</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>

          <tbody>
            {orders
              .slice(0)
              .reverse()
              .map((order, index) => (
                <tr key={index}>
                  <td>RDV N°{order.rdv}</td>
                  <td>
                    {order.start_date_time.substring(0, 10)} :{" "}
                    {order.start_date_time.substring(11, 16)}
                  </td>
                  <td>{order.type + ' - ' + order.registration}</td>
                  <td>{order.piece_order.pieceLib}</td>
                  <td>{order.piece_order.price}</td>
                  <td>
                    {order.piece_order.status === "payed" ?
                      <span className="success">Payer</span>
                      : <span className="warning">A payer</span>
                    }
                  </td>
                  <td className="date" >{order.date}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
}

export default Orders;
