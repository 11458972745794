import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import DashboardHeader from "../../../components/Headers/DashboardHeader";

function Reviews() {
  const [reviews, setReviews] = useState([]);

  const columns = [
    {
      name: "N°",
      selector: (row, i) => i + 1,
      sortable: true,
      wrap: true,
    },
    {
      name: "Texte",
      selector: (row) => row.feedback_content,
      sortable: true,
      wrap: true,
    },
    {
      name: "Note / 5",
      selector: (row) => row.rate,
      sortable: true,
      wrap: true,
    },
    {
      name: "Utilisateur",
      selector: (row) => row.lastName + " " + row.firstName,
      sortable: true,
      wrap: true,
    },
    {
      name: "RDV N°",
      selector: (row) => row.appointment,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      wrap: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        color: "blueviolet",
        fontSize: "14px",
        fontWeight: "700",
        backgroundColor: "rgb(247, 238, 255)",
      },
    },
  };

  useEffect(() => {
    setReviews([
      {
        firstName: "mohamed",
        lastName: "machta",
        appointment: "35",
        feedback_content: "Test",
        rate: "5",
        created_at: "10/12/2023",
      },
    ]);
  }, []);

  return (
    <div className="dashboard-content">
      <DashboardHeader />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Mes Avis</h2>
        </div>
        <DataTable
          columns={columns}
          data={reviews}
          pagination={15}
          subHeader
          persistTableHead
          customStyles={customStyles}
        />
      </div>
    </div>
  );
}

export default Reviews;
