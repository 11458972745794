import React from "react";
import { useNavigate } from "react-router-dom";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { ApiConfig } from "../../../../infrastructure/services/env";

import "./styles.css";

const Checkout = () => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const __pay = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    stripe
      .confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: ApiConfig.API + "/driver/dashboard",
        },
      })
      .then((res) => {
        if (res.error) {
          console.log(res.error);
        } else {
          navigate("/driver/dashboard");
        }
      });
  };

  return (
    <form className="checkout-container" onSubmit={__pay}>
      <PaymentElement />
      <button className="btn btn-full" disabled={!stripe}>
        Submit
      </button>
    </form>
  );
};

export default Checkout;
