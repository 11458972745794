import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import api from "../../../../infrastructure/services/api";

import Footer from "../../../components/Footer";
import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import Alert from "../../../components/Alert";
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
} from "../../../utils/input-verification";

function Contact() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const alertRef = useRef(null);
  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");

  const __sendMessage = () => {
    if (name === "") {
      setAlert_message((message) => "Merci de vérifier votre nom et prénom.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (!validateEmail(email)) {
      setAlert_message((message) => "Merci de vérifier vos informations");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (phoneNumber === "") {
      setAlert_message((message) => "Merci de vérifier votre Téléphone.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (message === "") {
      setAlert_message(
        (message) =>
          "Veuillez vérifier vos informations et compléter celles manquantes"
      );
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const body = {
        name: name,
        phoneNumber: phoneNumber,
        email: email,
        message: message,
      };
      const response = api.sendMesaage(body);
      response.then((response) => {
        if (response.data.success) {
          setAlert_message((message) => response.data.message);
          setAlert_type((alert_type) => "success");
          alertRef.current.show();
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      });
    }
  };

  return (
    <div className="container contact-page">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      {/* Home Header */}
      <div className="home-header">
        <VisitorNavBar />
      </div>
      <div className="workshop-register-header">
        <div className="workshop-register-left">
          <div className="text-container">
            <h1>Contactez Nous!</h1>
          </div>
        </div>
        <div className="workshop-register-right">
          <div className="workshop-register-right-container">
            <h2>Notre équipe est à votre disposition</h2>
            <div className="workshop-register-form">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Nom et Prénom"
              />
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Téléphone"
              />
              <input
                type="email"
                value={email}
                placeholder="E-mail"
                onChange={(e) => setEmail(e.target.value)}
              />
              <textarea
                rows={10}
                placeholder="Écrivez un message ici"
                defaultValue=""
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>

            <button className="btn mt-10" onClick={() => __sendMessage()}>
              Envoyer
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
