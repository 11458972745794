import CalendarIcon from '../assets/icons/calendar-menu.svg';
import InfoIcon from '../assets/icons/info.svg';
import PaymentCardIcon from '../assets/icons/payment-card.svg';

const driver_menu = [
    {
        id: 1,
        icon: CalendarIcon,
        path: '/driver/dashboard/appointments',
        title: 'Mes rendez-vous',
    },
    {
        id: 2,
        icon: InfoIcon,
        path: '/driver/dashboard/profile',
        title: 'Mes infos',
    }
]

export default driver_menu;