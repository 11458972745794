import React , {useState, useEffect} from "react";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import api from '../../../infrastructure/services/api';

const ProtectedRoutes = ({children}) => {
    const [token, setToken] = useState(null);
    const [decoded, setdecoded] = useState({});

    useEffect(() => {
        if (api.getAuthToken() !== null) {
            setToken(api.getAuthToken());
            setdecoded(jwt_decode(api.getAuthToken()));
        }
    }, []);

    if (token === null || decoded.role === "personal") 
        return children;
    else if (token !== null && decoded.role === "admin")
        return (
            <Navigate to="/admin/dashboard" replace />
        );
    else  if (token !== null && decoded.role === "business")
        return (
            <Navigate to="/workshop/dashboard" replace />
        );
    else
        return (
            <Navigate to="/" replace />
        );
}

export default ProtectedRoutes;