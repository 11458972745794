import React, { useState } from "react";
import Modal from "react-modal";

import Alert_error from "../../assets/icons/Alert_error";
import TrashIcon from "../../assets/icons/TrashIcon";

export default function Rdv({ half, appointment, index }) {
  const [isOpen, setIsOpen] = useState(false);

  const __closeModal = () => {
    setIsOpen((isOpen) => false);
  };
  console.log({ appointment });
  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={__closeModal}
        onClick={() => setIsOpen((isOpen) => false)}
        className="modal-container"
        overlayClassName="overlay-modal"
      >
        <div className="prestation_info">
          <h2>Rendez-vous N°{index}</h2>
          <p>
            Nom Client:
            <span>
              {appointment.business.lastName +
                " " +
                appointment.business.firstName}
            </span>
          </p>
          <p>
            Immatriculation:<span>AD-450-EF</span>
          </p>
          <p>
            Prestation:<span>{appointment.prestation.title}</span>
          </p>
          <p>
            Prix:<span>{appointment.price}</span>
          </p>
          <p>
            Date et Heure:
            <span>
              {new Date(appointment.start_date_time).toLocaleDateString() +
                " - " +
                appointment.duration}
              min
            </span>
          </p>
        </div>
      </Modal>
      <div
        key={index}
        className={"rdv" + `${half ? " rdv_half" : ""}`}
        onClick={() => setIsOpen((isOpen) => true)}
        style={{ zIndex: isOpen ? 0 : 1 }}
      >
        <p> RDV N°{index}</p>
        <span>
          <Alert_error /> <TrashIcon />
        </span>
      </div>
    </>
  );
}
