import React, { useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import "./styles.css";
import logo from "../../../assets/images/primary-logo.png";
import api from "../../../../infrastructure/services/api";
import {DataContext} from '../../../../application';

import {
  validateEmail,
  validatePassword,
} from "../../../utils/input-verification";
import Alert from "../../../components/Alert";

const Login = () => {
  const navigate = useNavigate();
  const {dataContext} = React.useContext(DataContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  // Login
  const __login = () => {
    if (!validateEmail(email)) {
      setAlert_message((message) => "Merci de vérifier votre addresse mail.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    }
    else {
      const response = api.login(email, password);
      response.then((response) => {
        if (response.status === 200) {
          var decoded_token = jwt_decode(response.data.token);
          if (decoded_token.role === "admin") {
            dataContext.login(response.data.token);
            navigate("/admin/dashboard");
          } else {
            setAlert_message(
              (message) =>
                "Vous n'êtes pas permis de se connecter comme admin !"
            );
            setAlert_type((alert_type) => "error");
            alertRef.current.show();
          }
        } else if (response.status === 201) {
          setAlert_message(
            (message) => "Merci de vérifier votre mot de passe."
          );
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
      response.catch((error) => {
        if (error.response.status === 403) {
          setAlert_message((message) => "Merci de vérifier votre e-mail.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        } else {
          setAlert_message((message) => "Merci de vérifier votre connexion.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
    }
  };

  return (
    <div>
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <div className="login">
        <img src={logo} alt="logo" className="logo" />

        <div className="login-form">
          <div>
            <MailOutlined />
            <input
              type="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <LockOutlined />
            <input
              type="password"
              value={password}
              placeholder="Mot de passe"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button className="btn" onClick={__login}>
            Se connecter
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
