import React, { useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";

import "./styles.css";
import DriverNavBar from "../../components/NavBars/DriverNavBar";
import Alert from "../../components/Alert";

import api from "../../../infrastructure/services/api";
import { DataContext } from "../../../application";
import { validateEmail } from "../../utils/input-verification";
import Footer from "../../components/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  getData,
  getObject,
  removeData,
  storeData,
  storeObject,
} from "../../../application/helpers/local-storage";

function RestPassword() {
  const navigate = useNavigate();
  const { dataContext } = React.useContext(DataContext);
  let location = useLocation();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  const __recover = () => {
    if (!validateEmail(email)) {
      setAlert_message((message) => "Merci de vérifier votre addresse mail.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      setLoading(true);
      const response = api.recover(email);
      response.then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setAlert_message((message) => (
            <strong>
              Un email de modification de mot de passe a été envoyé à votre
              adresse email, <br />
              Veuillez consulter votre boîte de réception.
            </strong>
          ));
          setAlert_type((alert_type) => "success");
          alertRef.current.show();
          setTimeout(() => {
            navigate("/");
          }, 1600);
        }
      });
      response.catch((error) => {
        setLoading(false);
        if (error.response.status === 404) {
          setAlert_message((message) => <strong>Compte inexistant!</strong>);
          setAlert_type((alert_type) => "error");
          alertRef.current.show();
        } else {
          setAlert_message((message) => "Merci de vérifier votre connexion.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
    }
  };

  return (
    <>
      <div className="d-block min-h-700">
        <Alert type={alert_type} message={alert_message} ref={alertRef} />
        <DriverNavBar text="S'inscrire" onClick={() => navigate("/driver")} />

        <div className="login-workshop-container driver">
          <div className="login-workshop-header">
            <h3>Recherchez votre compte Cars D'heure</h3>
            <p>
              Entrez l'adresse email associé à votre compte pour modifier votre
              mot de passe.
            </p>
          </div>
          <div className="login-form">
            <div className="group">
              <FontAwesomeIcon icon={faEnvelope} style={{ color: "#a5a5a5" }} />
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              className="btn"
              onClick={__recover}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "16px",
                whiteSpace: "normal",
                placeContent: "space-around",
              }}
            >
              Envoyer un e-mail de réinitialisation
              {loading ? (
                <ReactLoading
                  type={"spin"}
                  color="#fff"
                  backen
                  height={20}
                  width={20}
                />
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </div>
      <div>
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

export default RestPassword;
