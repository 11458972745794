import React, {useState, useEffect} from 'react';

import api from '../../../../infrastructure/services/api';

import '../Home/styles.css';
import VisitorNavBar from '../../../components/NavBars/VisitorNavBar';
import CityCard from '../../../components/Cards/CityCard';
import Footer from '../../../components/Footer';

function Cities () {
    const [cities, setCities] = useState([]);    

    useEffect(() => {
        const cities_response = api.getCities();
        cities_response.then(response => {
        setCities(response.data.data);
        });
    }, []);

    return (
        <div>   
            <VisitorNavBar /> 
            <div className='cities'>
                <div className='header'>
                    <h2>Nos professionnels prés de chez vous</h2>
                    <hr />
                    <p>Retrouvez nos experts partout en France à tout moment</p>
                </div>
                <div className='CityForVisitor'>
                    <div className='gallery'>
                        {cities.map((city, index) => (
                            <CityCard key={index} city={city} />
                        ))}
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default Cities;