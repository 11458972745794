import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import "./styles.css";
import admin_menu from "../../../constants/admin-menu";
import SideBar from "../../../components/SideBar";

import Orders from "./Orders";
import Checkouts from "./Checkouts";
import Cities from "./Cities";
import Profile from "../../Profile";
import WorkshopList from "./WorkshopList";

function Dashboard() {
  const location = useLocation();

  const [active, setActive] = useState(1);

  useEffect(() => {
    admin_menu.forEach((element) => {
      if (location.pathname === element.path) {
        setActive(element.id);
      }
    });
  }, []);

  const __navigate = (id) => {
    setActive(id);
  };

  return (
    <div className="dashboard-container">
      <SideBar menu={admin_menu} active={active} navigate={__navigate} />

      <div className="dashboard-body">
        <Routes>
          <Route path="*" element={<Orders />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/checkouts" element={<Checkouts />} />
          <Route exact path="/cities" element={<Cities />} />
          <Route exact path="/workshops" element={<WorkshopList />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
