import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Login from './Login';
import Dashboard from './Dashboard';

import ProtectedRoutes from './ProtectedRoutes';
import VisitorProtectedRoutes from '../VisitorProtectedRoutes';

function Admin () {
    return (
        <div>
            <Routes>
                <Route exact path="/" element={
                    <VisitorProtectedRoutes>
                        <Login />
                    </VisitorProtectedRoutes>
                } />
                <Route path="/dashboard/*" element={
                    <ProtectedRoutes>
                        <Dashboard />
                    </ProtectedRoutes>
                } />
            </Routes>
        </div>
    )
}

export default Admin;