import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";

import "./styles.css";
import DashboardHeader from "../../../components/Headers/DashboardHeader";
import PictureIcon from "../../../assets/icons/upload-image.svg";
import CheckMarkIcon from "../../../assets/icons/done.svg";

import PaymentImage from "../../../assets/images/paiement-en-ligne.png";
import EuroImage from "../../../assets/images/euro.png";

import api from "../../../../infrastructure/services/api";
import { ApiConfig } from "../../../../infrastructure/services/env";
import Alert from "../../../components/Alert";
import HelpCard from "../../../components/Cards/HelpCard";
import moment from "moment";

function BusinessAccount() {
  const [logo, setLogo] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [cover, setCover] = useState(null);
  const [coverURL, setCoverURL] = useState(null);
  const [workshopName, setWorkshopName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenMethodOfPayment, setIsOpenMethodOfPayment] = useState(false);
  const [isAble, setIsAble] = useState(false);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [description, setDescription] = useState("");
  const [balance, setBlanace] = useState("");
  const [paiementRequestValue, setPaiementRequestValue] = useState(0);

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const [isLimit, setIsLimit] = useState(false);

  const [paiementMethod, setPaiementMethod] = useState("");
  const [defaultPaiementMethod, setDefaultPaiementMethod] = useState("");

  const [paymentRequests, setPaymentRequests] = useState([]);
  const [isDisabledPaymentButton, setIsDisabledPaymentButton] = useState(false);

  const [banckAccount, setBanckAccount] = useState({
    banckAccountName: "",
    banckAccountNum: "",
    bankName: "",
    iban: "",
    swift: "",
  });

  const alertRef = useRef(null);

  useEffect(() => {
    __getMyBusinessProfile();
    __getIsAble();
  }, []);

  // Get My Business Profile
  const __getMyBusinessProfile = () => {
    const response = api.getMyBusinessProfile();
    response.then((response) => {
      if (response.data.data.business.logo) {
        setLogoURL(response.data.data.business.logo);
      }
      if (response.data.data.business.cover_img) {
        setCoverURL(response.data.data.business.cover_img);
      }
      setWorkshopName(response.data.data.business.workshop_name);
      setFirstName(response.data.data.firstName);
      setLastName(response.data.data.lastName);
      setDescription(response.data.data.business.description);
      setBlanace(response.data.data.business.balance);
      setDefaultPaiementMethod(
        response.data.data.business.paiement_infos?.method_of_payment ?? ""
      );
      setBanckAccount({
        banckAccountName:
          response.data.data.business.paiement_infos?.banck_account_name ?? "",
        banckAccountNum:
          response.data.data.business.paiement_infos?.banck_account_num ?? "",
        bankName: response.data.data.business.paiement_infos?.bank_name ?? "",
        iban: response.data.data.business.paiement_infos?.iban ?? "",
        swift: response.data.data.business.paiement_infos?.swift ?? "",
      });
    });
    const paymentResponse = api.myCheckoutRequests();
    paymentResponse.then((response) => {
      setPaymentRequests(response.data);
      if (response.data.find((e) => e.status === "WAITING")) {
        setIsDisabledPaymentButton(true);
      }
    });
  };

  // Update Logo
  const __updateLogo = () => {
    if (logo !== null) {
      var formData = new FormData();
      formData.append("logo", logo);
      const response = api.updateBusinessProfileLogo(formData);
      response.then((response) => {
        return;
      });
    }
  };

  // Update Cover
  const __updateCover = () => {
    if (cover !== null) {
      var formData = new FormData();
      formData.append("cover", cover);
      const response = api.updateBusinessProfileCover(formData);
      response.then((response) => {
        return;
      });
    }
  };

  // Update Cover
  const __updateProfile = () => {
    if (workshopName !== "") {
      const profile = {
        workshop_name: workshopName,
        description: description,
        lastName: lastName,
        firstName: firstName,
      };
      const response = api.updateMyBusinessProfile(profile);
      response.then((response) => {
        return;
      });
    }
  };

  const __send = () => {
    try {
      __updateLogo();
      __updateCover();
      __updateProfile();
      __getMyBusinessProfile();
      // alert('Votre profile a été bien modifié !');
      setAlert_message((message) => "Votre profile a été bien modifié !");
      setAlert_type((alert_type) => "success");
      alertRef.current.show();
    } catch (error) {
      console.log(error);
    }
  };

  const __changePaiementMethod = () => {
    try {
      __updatePaiementMethod();
      setIsOpenMethodOfPayment(false);
      setAlert_message((message) => "Le mode de paiement modifié avec succès!");
      setAlert_type((alert_type) => "success");
      alertRef.current.show();
    } catch (error) {
      console.log(error);
    }
  };

  // Update Paiement method
  const __updatePaiementMethod = () => {
    if (paiementMethod !== "") {
      console.log(paiementMethod);
      setDefaultPaiementMethod(paiementMethod);
      const data = {
        paiement_method: paiementMethod,
      };
      const response = api.updateMyBusinessPaiementMethod(data);
      response.then((response) => {
        return;
      });
    }
  };

  // Update Banck Info
  const __updateBankInfo = () => {
    const info = {
      banck_account_name: banckAccount.banckAccountName,
      banck_account_num: banckAccount.banckAccountNum,
      bank_name: banckAccount.bankName,
      iban: banckAccount.iban,
      swift: banckAccount.swift,
    };
    api.updateMyBusinessBankInfo(info);
    setIsOpenConfirm(false);
    setIsOpen(true);
  };

  // isAble
  const __getIsAble = () => {
    const response = api.isAbleCheckout();
    response.then((response) => {
      setIsAble(response.data.data.isAble);
    });
  };

  const __requestCheckout = () => {
    if (!isAble) {
      // alert("Vous n'avez pas atteint le montant minimal !");
      setAlert_message(
        (message) => "Vous n'avez pas atteint le montant minimal !"
      );
      setAlert_type((alert_type) => "error");
      alertRef.current.show();
    } else {
      const data = {
        amount: paiementRequestValue,
      };
      const response = api.requestCheckout(data);
      response.then((response) => {
        if (response.status === 200) {
          setAlert_message(
            (message) => "Votre demande de retrait a été envoyé avec succès!"
          );
          setAlert_type((alert_type) => "success");
          alertRef.current.show();
          setIsOpen(false);
          __getMyBusinessProfile();
        } else {
          setAlert_message(
            (message) =>
              "une erreur est survenue veuillez réessayer ultérieurement!"
          );
          setAlert_type((alert_type) => "danger");
          alertRef.current.show();
        }
      });
    }
  };

  //

  const __checkLimit = (value) => {
    setIsLimit(value > parseFloat((balance / 100).toFixed(2)));
  };

  return (
    <div className="dashboard-content">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <DashboardHeader />
      <HelpCard />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Mon compte</h2>
        </div>

        <div className="dashboard-content-body">
          {/* Logo */}
          <div className="form-row">
            <p className="strong">
              Image de l'utilisateur <span>(Recommandé 800x800)</span>
            </p>
            <div className="logo-upload">
              {logo !== null ? (
                <img
                  src={URL.createObjectURL(logo)}
                  className="logo-picture"
                  alt="logo"
                />
              ) : logoURL !== null ? (
                <img
                  src={ApiConfig.IMAGES + logoURL}
                  className="logo-picture"
                  alt="logo"
                />
              ) : null}
              <div className="upload-btn">
                <label htmlFor="upload-logo" className="upload-label">
                  <img
                    src={PictureIcon}
                    alt="upload-icon"
                    className="upload-icon"
                  />
                  <p>Séléctionner une image</p>
                </label>
                <input
                  type="file"
                  id="upload-logo"
                  accept="image/*"
                  onChange={(e) => setLogo(e.target.files[0])}
                />
              </div>
            </div>
          </div>

          {/* Cover */}
          <div className="form-row">
            <p className="strong">
              Image de couverture <span>(Recommandé 1080x720)</span>
            </p>
            <div className="cover-upload">
              {cover !== null ? (
                <img
                  src={URL.createObjectURL(cover)}
                  className="cover-picture"
                  alt="cover"
                />
              ) : coverURL !== null ? (
                <img
                  src={ApiConfig.IMAGES + coverURL}
                  className="cover-picture"
                  alt="cover"
                />
              ) : null}
              <div className="upload-btn">
                <label htmlFor="upload-cover" className="upload-label">
                  <img
                    src={PictureIcon}
                    alt="upload-icon"
                    className="upload-icon"
                  />
                  <p>Séléctionner une image</p>
                </label>
                <input
                  type="file"
                  id="upload-cover"
                  accept="image/*"
                  onChange={(e) => setCover(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="hr"></div>

          <div className="form-row">
            <p className="strong">Nom du garage</p>
            <input
              type="text"
              value={workshopName}
              placeholder="Exp: Cars d'Heure"
              onChange={(e) => setWorkshopName(e.target.value)}
            />
          </div>
          <div className="form-row" style={{ marginBottom: "0px" }}>
            <div className="form-column">
              <div className="form-row">
                <p className="strong">Prénom</p>
                <input
                  type="text"
                  value={firstName}
                  placeholder="Prénom"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="form-row">
                <p className="strong">Nom</p>
                <input
                  type="text"
                  value={lastName}
                  placeholder="Nom de la famille"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="form-row">
            <p className="strong">A propos</p>
            <textarea
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="hr"></div>

          <div className="form-row center">
            <button className="btn" onClick={() => __send()}>
              Enregistrer
            </button>
          </div>

          <div className="hr"></div>
          <div className="form-row">
            <h3>Retrait</h3>
            <div className="payment-card">
              <div className="card-body">
                <h2>Solde actuel</h2>
                <p>
                  Actuellement, vous avez{" "}
                  <span>{(balance / 100).toFixed(2)} Є</span> en total
                  disponibles pour le retrait.
                </p>
                {isDisabledPaymentButton ? (
                  <p
                    className="danger"
                    style={{ margin: "20px 0", padding: "10px" }}
                  >
                    vous avez une demande de retrait en cours!
                  </p>
                ) : (
                  ""
                )}

                <button
                  disabled={isDisabledPaymentButton}
                  className="btn"
                  onClick={() => setIsOpenConfirm(true)}
                >
                  Demande de retrait{" "}
                </button>
              </div>
              <div className="card-image">
                <img src={PaymentImage}></img>
              </div>
            </div>
          </div>
          <div className="form-row">
            <p>
              Le mode de paiement préfère sélectionné est{" "}
              {defaultPaiementMethod === "bank_transfer"
                ? "Virement bancaire"
                : defaultPaiementMethod === "paypal"
                ? "PayPal"
                : "Chèque bancaire"}
              . Vous pouvez modifier votre{" "}
              <a onClick={() => setIsOpenMethodOfPayment(true)}>
                préférence de retrait
              </a>
            </p>
          </div>
          <div className="form-row">
            <h3>Historique des retraits</h3>
            {paymentRequests.length ? (
              paymentRequests.map((e, index) => {
                return (
                  <p key={index}>
                    {moment(e.created_at).format("DD/MM/y") +
                      " Montant: " +
                      parseFloat(e.amount / 100).toFixed(2) +
                      " Є Statut: " +
                      (e.status === "ACCEPTED"
                        ? "CONFIRMÉ"
                        : e.status === "DENIED"
                        ? "ANNULÉ"
                        : "EN COURS")}
                  </p>
                );
              })
            ) : (
              <p>Aucun retrait pour le moment</p>
            )}
          </div>
        </div>
        <Modal
          isOpen={isOpen}
          ariaHideApp={false}
          onRequestClose={() => setIsOpen(false)}
          className="modal-container modal-container payment-form"
          overlayClassName="overlay-modal"
        >
          <h2 className="modal-title">Demande de retrait</h2>
          <div className="dashboard-content-body">
            <div className="form-row">
              <p className="strong">
                Veuillez saisir le montant du retait et cliquer sur le bouton «
                Envoyer une demande »
              </p>
            </div>
            <div className="form-row">
              <div className="form-column">
                <div className="form-row flex-1">
                  <p className="strong">Solde actuel</p>
                  <p className="strong">{(balance / 100).toFixed(2)} Є</p>
                </div>
                <div className="form-row">
                  <p className="strong">Mode de paiement sélectionné</p>
                  <p className="strong">Virement bancaire</p>
                </div>
              </div>
            </div>
            <div className="hr"></div>
            <div className="form-row relative">
              <p className="strong">Montant</p>
              <input
                type="number"
                onChange={(e) => {
                  setPaiementRequestValue(e.target.value);
                  __checkLimit(e.target.value);
                }}
              />
              <img className="image-input" src={EuroImage} />
              <p className="strong">
                Le montant minimum de retrait est de 100.00 Є
              </p>
            </div>
            {isLimit && (
              <p className="danger">
                Le montant du retrait ne peut pas être supérieur à votre solde
                actuel
              </p>
            )}
            <div className="form-row">
              <div className="form-column" style={{ gap: "110px" }}>
                <div className="form-row">
                  <button
                    className="btn btn-light btn-full"
                    onClick={() => setIsOpen(false)}
                  >
                    Annuler
                  </button>
                </div>
                <div className="form-row">
                  <button
                    disabled={
                      paiementRequestValue < 100 ||
                      paiementRequestValue === 0 ||
                      isLimit
                    }
                    className="btn btn-full"
                    onClick={() => __requestCheckout()}
                  >
                    Envoyer une demande
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={isOpenConfirm}
          ariaHideApp={false}
          onRequestClose={() => setIsOpenConfirm(false)}
          className="modal-container modal-container"
          overlayClassName="overlay-modal"
        >
          <h2 className="modal-title">Sélectionner la méthode de retrait</h2>
          <div className="dashboard-content-body" style={{ padding: "0 20px" }}>
            <div
              className="card relative"
              style={{
                alignItems: "flex-start",
                border: "2px solid #5127c5",
                borderRadius: "4px",
              }}
            >
              <p
                style={{
                  color: "#5127c5",
                  fontweight: 900,
                  fontSize: "17px",
                  marginBottom: 0,
                }}
              >
                Virement bancaire
              </p>
              <span style={{ fontweight: 600, fontSize: "12px" }}>
                Retrait minimum 100.00 Є
              </span>
              <img
                style={{
                  width: "20px",
                  top: "0",
                  right: "0",
                  position: "absolute",
                  height: "unset",
                  background: "#5127c5",
                }}
                src={CheckMarkIcon}
              />
            </div>
            <div className="hr"></div>
            <div className="form-row">
              <div className="form-column">
                <div className="form-row">
                  <p className="strong">Nom du compte</p>
                  <input
                    type="text"
                    value={banckAccount.banckAccountName}
                    onChange={(e) =>
                      setBanckAccount({
                        ...banckAccount,
                        banckAccountName: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="form-row">
                  <p className="strong">Numéro du compte</p>
                  <input
                    type="text"
                    value={banckAccount.banckAccountNum}
                    onChange={(e) =>
                      setBanckAccount({
                        ...banckAccount,
                        banckAccountNum: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <div className="form-row">
                  <p className="strong">Nom de la banque</p>
                  <input
                    type="text"
                    value={banckAccount.bankName}
                    onChange={(e) =>
                      setBanckAccount({
                        ...banckAccount,
                        bankName: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="form-row">
                  <p className="strong">IBAN</p>
                  <input
                    type="text"
                    value={banckAccount.iban}
                    onChange={(e) =>
                      setBanckAccount({
                        ...banckAccount,
                        iban: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <div className="form-row">
                  <p className="strong">BIC / SWIFT</p>
                  <input
                    type="text"
                    value={banckAccount.swift}
                    onChange={(e) =>
                      setBanckAccount({
                        ...banckAccount,
                        swift: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-row"></div>
              </div>
            </div>
            <div className="form-row center" style={{ marginTop: "40px" }}>
              <button className="btn " onClick={() => __updateBankInfo()}>
                Mettre à jour et continuer
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={isOpenMethodOfPayment}
          ariaHideApp={false}
          onRequestClose={() => setIsOpenMethodOfPayment(false)}
          className="modal-container modal-container-small"
          overlayClassName="overlay-modal"
        >
          <h2 className="modal-title">Sélectionner le mode de paiement</h2>
          <div className="dashboard-content-body" style={{ padding: "0 20px" }}>
            <div className="form-row">
              <input
                type="radio"
                name="methods"
                value="bank_transfer"
                onChange={(e) => setPaiementMethod(e.target.value)}
              ></input>
              <span className="text-strong pl-10">Virement bancaire</span>
            </div>
            <div className="form-row">
              <input
                type="radio"
                name="methods"
                value="paypal"
                onChange={(e) => setPaiementMethod(e.target.value)}
              ></input>
              <span className="text-strong pl-10">PayPal</span>
            </div>
            <div className="form-row">
              <input
                type="radio"
                name="methods"
                value="bank_check"
                onChange={(e) => setPaiementMethod(e.target.value)}
              ></input>
              <span className="text-strong pl-10">Chèque bancaire</span>
            </div>
            <div className="form-row">
              <div className="form-column" style={{ gap: "110px" }}>
                <div className="form-row">
                  <button
                    className="btn btn-light btn-full"
                    onClick={() => setIsOpenMethodOfPayment(false)}
                  >
                    Annuler
                  </button>
                </div>
                <div className="form-row">
                  <button
                    className="btn btn-full"
                    onClick={() => __changePaiementMethod()}
                  >
                    Mise à jour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default BusinessAccount;
