import React, { useState, useRef } from "react";
import "./styles.css";
import Alert from "../../Alert";

function DashboardHeader({ btnText, onClick }) {
  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  return (
    <div className="dashbord-header-container">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />

      {/* {btnText && onClick && 
                <button className='btn btn-dashboard' onClick={onClick}>{btnText}</button>
            } */}
      <div className="dashbord-header-btn"></div>

      <div className="dashbord-header-option">
        <div className="dashbord-header-notification"></div>
        <div className="vertical-bar"></div>
        <div className="">
          <div className="dashbord-header-avatar">C</div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
