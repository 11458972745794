import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import "./styles.css";
import { DataContext } from "../../../../application";
import api from "../../../../infrastructure/services/api";
import { ApiConfig } from "../../../../infrastructure/services/env";

import VisitorNavBar from "../../../components/NavBars/VisitorNavBar";
import CategoryCard from "../../../components/Cards/CategoryCard";
import Footer from "../../../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPlus, faClock } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";

function WorkshopProfile() {
  let { id } = useParams();
  let [params] = useSearchParams();
  const navigate = useNavigate();
  const { state } = React.useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [workshop, setWorkshop] = useState({});
  const [mainCategories, setMainCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);

  const subCategoryRef = useRef(null);
  const itemMpRefs = useRef([]);

  const toggleVisibility = () => {
    if (subCategoryRef.current) {
      const currentVisibility = subCategoryRef.current.style.display;
      subCategoryRef.current.style.display =
        currentVisibility === "none" ? "block" : "none";
    }
  };

  const getWorkSteps = (itemMp) => {
    const work_steps = api.getWorkSteps(
      id,
      params.get("carId"),
      itemMp.KorId,
      itemMp.ItemMpId
    );
    work_steps.then((response) => {
      console.log(response.data);
    });
  };

  const convertDecimalToTime = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    let minutes = Math.round((decimalHours - hours) * 60);
    minutes = (minutes < 10 ? "0" : "") + minutes;
    return `${hours}H ${minutes}min`;
  };

  useEffect(() => {
    const response = api.getBusinessProfile(id);

    response.then((response) => {
      setWorkshop(response.data.data);
      setLoading(false);
    });

    const categories_response = api.getCategoriesByBusiness(
      id,
      params.get("carId")
    );
    categories_response.then((response) => {
      if (response.data.success) {
        setMainCategories(response.data.mainCategories);
        setCategories(response.data.categories);
        if (response.data.mainCategories.length) {
          setSelectedCategory(
            state?.category ? state.category : response.data.mainCategories[0]
          );
        }
        setIsLoading(false);
      }
    });
  }, []);

  // Change Category
  const __changeCategory = (category) => {
    setSelectedCategory(category);
  };

  // Get Providers
  const __getProviders = async (subCategory) => {
    const found = selectedSubCategory.find(
      (cat) => cat?._id === subCategory?._id
    );
    if (!found) {
      const response = api.getFournisseurs(
        subCategory.sub_category,
        subCategory.criteria,
        state.vehicule?.CODMARQ,
        state.vehicule?.CODTYPE
      );
      await response.then((response) => {
        if (response.data.data[0].FOURNISSEUR) {
          subCategory.providers = response.data.data[0].FOURNISSEUR;
          setSelectedSubCategory([...selectedSubCategory, subCategory]);
        }
      });
    } else {
      setSelectedSubCategory(
        selectedSubCategory.filter((cat) => cat._id !== subCategory?._id)
      );
    }
  };

  // Get Pieces
  const __getPieces = (subCatId, provider) => {
    let subCats = [...selectedSubCategory];

    subCats.map((subCat) => {
      if (subCat._id === subCatId) {
        const response = api.getPieces(
          provider.CODEFOURNIS[0],
          subCat.sub_category,
          subCat.criteria,
          state.vehicule?.CODMARQ,
          state.vehicule?.CODTYPE,
          state.vehicule?.DEBUT,
          state.vehicule?.ALIM
        );
        response.then(async (response) => {
          setSelectedSubCategory([
            ...subCats.filter((cat) => cat._id !== subCatId),
            {
              ...subCat,
              pieces: response.data[0].PIECE,
            },
          ]);
        });
      }
    });
  };

  // Make appointment
  const makeAppointment = () => {
    navigate("/make-appointment", {
      state: { workshop: id, selectedSubCategory: selectedSubCategory },
    });

    // if (selectedCategory !== null && selectedSubCategory !== null && selectedProvider != null) {
    //
    // }
  };

  const setPiece = (subCatId, piece) => {
    let subCat = selectedSubCategory.filter((cat) => cat._id === subCatId);
    if (subCat.length) {
      setSelectedSubCategory([
        ...selectedSubCategory.filter((cat) => cat._id !== subCatId),
        {
          ...subCat[0],
          selectedPiece: piece,
        },
      ]);
    }
  };

  return (
    <div>
      {/* NavBar */}
      <VisitorNavBar />

      {/* Workshop Info */}
      {!loading ? (
        <div className="workshop-profile-container">
          <div className="workshop-profile-overlay">
            {workshop.business.cover_img ? (
              <img
                src={ApiConfig.IMAGES + workshop.business.cover_img}
                alt={workshop.business.workshopName}
              />
            ) : (
              <img src="/carsdheur.webp" alt={workshop.business.workshopName} />
            )}
          </div>
          <div className="workshop-info">
            <h2 className="workshop-info-title">
              {workshop.business.workshop_name}
            </h2>
            <span className="workshop-info-subTitle">
              {workshop.business.workshop_address?.label}
            </span>
            <div className="workshop-info-btn">
              <div className="rating-btn">
                <span>{workshop.business.rate.toFixed(1)} </span>(
                {workshop.business.feedbacks.length} avis)
              </div>
              <a
                className="phone-btn"
                style={{ textDecoration: "none" }}
                href={`tel:${workshop.business.business_infos.phoneNumber}`}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ color: "#ffffff", marginRight: "5px" }}
                />
                {workshop.business.business_infos.phoneNumber}
              </a>
            </div>
          </div>
        </div>
      ) : null}

      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <ReactLoading type={"spin"} color="#562ac6" height={80} width={80} />
        </div>
      ) : (
        <>
          <div className="services-container">
            {/* Categories */}
            <div className="workshop-categories-container">
              {mainCategories.map((mainCategory, index) => (
                <CategoryCard
                  key={index}
                  category={mainCategory}
                  activeCategory={selectedCategory}
                  onClick={() => __changeCategory(mainCategory)}
                />
              ))}
            </div>

            {categories.length !== 0 ? (
              <div className="sub-categories-sections">
                <div className="sub-categories-section">
                  <h2>Selection {selectedCategory[0]}</h2>
                  <h1>Que voulez-vous faire?</h1>
                  <div className="sub-categories-container">
                    {categories.map((category, index) => {
                      return (
                        <div key={index}>
                          {category.MainGroupId === selectedCategory.mainId ? (
                            category.SubGroups.map((subGroup, index) => {
                              if (subGroup.SubGroupId === selectedCategory.id) {
                                return (
                                  <>
                                    <label key={index} className="category">
                                      {subGroup.SubGroupName}
                                    </label>
                                    {subGroup.ItemMps.map((itemMp, i) => {
                                      return (
                                        <div key={i} className="provider-item">
                                          <label className="sub-category">
                                            <input
                                              type="checkbox"
                                              name="provider"
                                              onClick={() =>
                                                getWorkSteps(itemMp)
                                              }
                                            />
                                            {itemMp.KorText +
                                              " " +
                                              itemMp.ItemMpText}
                                          </label>
                                          <div
                                            key={index}
                                            style={{ display: "none" }}
                                            ref={(el) =>
                                              (itemMpRefs.current[i] = el)
                                            }
                                          >
                                            {itemMp.Works.length > 1
                                              ? itemMp.Works.map(
                                                  (work, index) => {
                                                    return (
                                                      <label
                                                        key={index}
                                                        className="sub-category-work"
                                                      >
                                                        <input
                                                          type="radio"
                                                          name="provider"
                                                          defaultChecked={
                                                            index === 0
                                                          }
                                                          onChange={() =>
                                                            // __getProviders()
                                                            ""
                                                          }
                                                        />
                                                        {work.KorText +
                                                          " " +
                                                          work.ItemMpText +
                                                          " " +
                                                          work.QualColText +
                                                          " "}

                                                        <FontAwesomeIcon
                                                          style={{
                                                            marginLeft: "10px",
                                                            marginRight: "10px",
                                                            fontSize: "13px",
                                                            color: "#5a32c8",
                                                          }}
                                                          icon={faClock}
                                                        />

                                                        <span
                                                          style={{
                                                            fontSize: "13px",
                                                          }}
                                                        >
                                                          {convertDecimalToTime(
                                                            work.WorkTime
                                                          )}
                                                        </span>
                                                      </label>
                                                    );
                                                  }
                                                )
                                              : ""}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                );
                              }
                            })
                          ) : category.MainGroupId === selectedCategory.id ? (
                            <>
                              <label className="category" key={index}>
                                {category.MainGroupName}
                              </label>
                              {category.SubGroups.map((subGroup, index) => {
                                return (
                                  <>
                                    <label
                                      key={index}
                                      onClick={toggleVisibility}
                                      className="sub-group"
                                    >
                                      <FontAwesomeIcon icon={faPlus} />
                                      {subGroup.SubGroupName}
                                    </label>
                                    <div
                                      id="myElement"
                                      style={{ display: "none" }}
                                      ref={subCategoryRef}
                                    >
                                      {subGroup.ItemMps.map((itemMp, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="provider-item"
                                          >
                                            {itemMp.Works.length > 1 ? (
                                              ""
                                            ) : (
                                              <label className="sub-category">
                                                <input
                                                  type="checkbox"
                                                  name="provider"
                                                  onChange={() => ""}
                                                />
                                                {itemMp.KorText +
                                                  " " +
                                                  itemMp.ItemMpText}
                                              </label>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    minWidth: "300px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#5127C5",
                      padding: 10,
                      textAlign: "center",
                      borderRadius: "10px 10px 0 0",
                      color: "#fff",
                      fontWeight: 600,
                    }}
                  >
                    Ma commande
                  </div>
                  <div
                    style={{
                      padding: "10px 20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span>
                        <strong
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          - démonter et monter Moteur
                        </strong>
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;{" "}
                        <FontAwesomeIcon
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontSize: "16px",
                            color: "#5a32c8",
                          }}
                          icon={faClock}
                        />
                        {convertDecimalToTime(2.3)}
                      </span>
                      <span>
                        &nbsp;&nbsp;&nbsp;<strong>Prix:</strong> 24EURO
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            ) : null}

            {/* {subCategories.length !== 0 ? (
      <div className="sub-categories-sections">
        <div className="sub-categories-section">
          <h2>Selection {selectedCategory[0]}</h2>
          <h1>Que voulez-vous faire?</h1>
          <div className="sub-categories-container">
            {subCategories.map((subCategory, index) => (
              <>
                <label key={index} className="sub-category">
                  <input
                    type="checkbox"
                    name="provider"
                    onChange={() => __getProviders(subCategory)}
                  />
                  {subCategory.title}
                </label>
                {selectedSubCategory.map((subCat, i) => {
                  if (subCat._id === subCategory._id && subCat?.providers) {
                    return (
                      <div className="providers-container" key={i}>
                        {subCat?.providers.map((provider, key) => (
                          <div key={key} className="provider-item">
                            <label>
                              <input
                                type="radio"
                                name="provider"
                                onChange={() =>
                                  __getPieces(subCat._id, provider)
                                }
                              />
                              {provider.LIBFOURNIS[0]}
                            </label>
                          </div>
                        ))}
                      </div>
                    );
                  }
                })}
                {selectedSubCategory.map((subCat, i) => {
                  if (subCat._id === subCategory._id && subCat?.pieces) {
                    return (
                      <div className="selected-section workshop-services-container">
                        <h2>Pièces conseillées pour votre vehicule</h2>
                        {subCat.pieces.map((service, index) => (
                          <div className="selected-container" key={index}>
                            <div className="selected-info workshop-service-infos">
                              <h3>{service.LIBELLE[0]}</h3>
                              <span>
                                <span className="bold-info">
                                  Main d’œuvre:{" "}
                                </span>
                                {parseFloat(subCat.price_t1).toFixed(2)}
                                € TTC
                                <br />
                                <span className="bold-info">Pièce: </span>
                                {parseFloat(service.PRIX[0]).toFixed(2)}€
                                TTC
                              </span>
                              <p>
                                Nous vous conseillons de changer vos disques
                                tous les 80 00 km
                              </p>
                            </div>
                            <div
                              className={
                                subCat.selectedPiece === service
                                  ? "btn-price selected-price"
                                  : "btn-price"
                              }
                              onClick={() => setPiece(subCat._id, service)}
                            >
                              {(
                                parseFloat(service.PRIX[0]) +
                                parseFloat(subCat.price_t1)
                              ).toFixed(2)}{" "}
                              € TTC
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  }
                })}
              </>
            ))}
          </div>
          { <div className='price-info-container'>
                            <h2>Prix moyens :</h2>
                            <p>
                                En moyenne le prix d'un changement de plaquettes de frien dans le Grarage <strong>{workshop.business.workshop_name} est de 117 €</strong>
                                <br />Toutefois, le prix d'un changement de plaquettes de frien est variable selon votre modèle de voiture. Vérifiez donc quel est le meilleur garage auto pour votre voiture.
                            </p>
                        </div> }
        </div>
      </div>
    ) : null} */}
          </div>

          <div className="workshop-profile-footer">
            {selectedSubCategory.map((subCat, i) => {
              if (subCat.selectedPiece) {
                return (
                  <>
                    {/* <button className='btn btn-light' onClick={() => makeAppointment()}><FontAwesomeIcon icon={faPlus} style={{ color: "#5127c5", }} />Ajouter une autre prestation</button> */}
                    <button className="btn" onClick={() => makeAppointment()}>
                      Prendre RDV
                    </button>
                  </>
                );
                return;
              }
            })}
            <button className="btn" onClick={() => makeAppointment()}>
              Prendre RDV
            </button>
          </div>
        </>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default WorkshopProfile;
