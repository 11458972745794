import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";

import api from "../../../../infrastructure/services/api";
import { ApiConfig } from "../../../../infrastructure/services/env";

import "./styles.css";
import PictureIcon from "../../../assets/icons/picture.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import DashboardHeader from "../../../components/Headers/AdminDashboardHeader";
import Alert from "../../../components/Alert";

function Cities() {
  const [cities, setCities] = useState([]);
  const [city, setCity] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [cityName, setCityName] = useState("");
  const [cityPicture, setCityPicture] = useState(null);

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  const __closeModal = () => {
    setCity(null);
    setCityName("");
    setCityPicture(null);
    setIsOpen(false);
  };

  useEffect(() => {
    __getCities();
  }, []);

  // get Cities
  const __getCities = () => {
    const cities_response = api.getAllCities();
    cities_response.then((response) => {
      setCities(response.data.data);
    });
  };

  // Delete City
  const __deleteCity = (city) => {
    // const response = api.deleteCity(city);
    // response.then(response => {
    //     __getCities();
    // });
  };

  // Create or Update City
  const __send = () => {
    if (cityName.length !== 0 && (cityPicture !== null || city.cityPicture)) {
      var formData = new FormData();
      formData.append("cityName", cityName);
      if (cityPicture !== null) {
        formData.append("image", cityPicture);
      }
      if (city) {
        formData.append("city", city._id);
        const response = api.updateCity(formData);
        response.then((response) => {
          __getCities();
          __closeModal();
        });
      } else {
        const response = api.createCity(formData);
        response.then((response) => {
          __getCities();
          __closeModal();
        });
      }
    } else {
      // alert('Merci de vérifier vos informations.')
      setAlert_message((message) => "Merci de vérifier vos informations.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    }
  };

  return (
    <div className="dashboard-content">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <DashboardHeader
        btnText="Ajouter une ville"
        onClick={() => setIsOpen(true)}
      />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Liste des villes</h2>
        </div>

        <table>
          <thead>
            <tr>
              <th>N°</th>
              <th>Nom Ville</th>
              <th>Image Ville</th>
              <th>Nombre d'ateliers</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {cities.map((city, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{city.cityName}</td>
                <td>
                  <a target="blank" href={ApiConfig.IMAGES + city.cityPicture}>
                    <img
                      src={ApiConfig.IMAGES + city.cityPicture}
                      width={50}
                      alt="upload-icon"
                    />
                  </a>
                </td>
                <td>{city.workshop_nb}</td>
                <td>
                  <img
                    src={EditIcon}
                    className="action-icon"
                    alt="edit-icon"
                    onClick={() => {
                      setCity(city);
                      setCityName(city.cityName);
                      setIsOpen(true);
                    }}
                  />
                  {/* <img src={DeleteIcon} className='action-icon' alt="edit-icon" onClick={() => __deleteCity(city._id)} /> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={() => __closeModal()}
        className="modal-container"
        overlayClassName="overlay-modal"
      >
        {city !== null ? (
          <h2 className="modal-title">Modifier ville</h2>
        ) : (
          <h2 className="modal-title">Ajouter une ville</h2>
        )}
        <p className="modal-label">Nom de la ville</p>
        <input
          type="text"
          value={cityName}
          className="modal-input"
          placeholder="Exp: Paris"
          onChange={(e) => setCityName(e.target.value)}
        />

        <p className="modal-label">Image de la ville</p>
        <div className="modal-upload">
          {cityPicture !== null ? (
            <img
              src={URL.createObjectURL(cityPicture)}
              className="modal-picture"
              alt="city-pic"
            />
          ) : city !== null ? (
            <img
              src={ApiConfig.IMAGES + city.cityPicture}
              className="modal-picture"
              alt={city.cityPicture}
            />
          ) : null}
          <label htmlFor="upload-file" className="modal-upload-label">
            <img src={PictureIcon} alt="upload-icon" />
            <p>Séléctionner une image</p>
          </label>
          <input
            className="modal-upload-label"
            type="file"
            id="upload-file"
            accept="image/*"
            onChange={(e) => setCityPicture(e.target.files[0])}
          />
        </div>
        <button className="btn modal-btn" onClick={() => __send()}>
          Envoyer
        </button>
      </Modal>
    </div>
  );
}

export default Cities;
