import React from "react";

import { ApiConfig } from "../../../../infrastructure/services/env";
import IconLike from "../../../../views/assets/icons/like.svg";
import IconCalander from "../../../../views/assets/icons/calendar-dark.svg";
import IconTimer from "../../../../views/assets/icons/timer.svg";
import IconLocation from "../../../../views/assets/icons/location-black.svg";

import "../ServiceCard/styles.css";

function PrestationCard({ prestation, onClick }) {
  return (
    <div className="service-card">
      {prestation.category_cover ? (
        <img
          src={ApiConfig.IMAGES + prestation.category_cover}
          alt="prestation"
        />
      ) : (
        <img
          src={ApiConfig.IMAGES + "categories/cover/allumage.webp"}
          alt="prestation"
        />
      )}

      {/* <span className='service-card-title'>{prestation.category_name}</span>
            <span className='service-card-subTitle'>Sous-catégories : <span className='service-card-active-subTitle'>{prestation.sub_categories.length}</span></span> */}

      <button className="service-rate">
        <strong>8.6</strong>
        <span className="total-rate"> ( 350 avis)</span>
      </button>
      <span className="service-card-title">FREINAGE</span>
      <span className="service-card-subTitle">
        A partir de :{" "}
        <span className="service-card-active-subTitle">150 €</span>
      </span>
      <div className="service-infos">
        {/* <p><EnvironmentOutlined />{service.address}</p> */}
        <div className="service-info">
          <img src={IconTimer} alt="timer" />
          1h30
        </div>
        <div className="service-info">
          <img src={IconLocation} alt="location" />
          75004 Paris
        </div>
        <div className="service-info">
          <img src={IconCalander} alt="calander" />
          Ven 11 Févr
        </div>
      </div>

      <button className="btn" onClick={() => onClick()}>
        Prenez rendez-vous
      </button>
    </div>
  );
}

export default PrestationCard;
