import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import "./styles.css";
import Modal from "react-modal";
import moment from "moment";

import DashboardHeader from "../../../components/Headers/DashboardHeader";
import InfoCard from "../../../components/Cards/InfoCard";
import Alert from "../../../components/Alert";

import backImage from "../../../assets/images/back-image.png";
import minusIcon from "../../../assets//icons/minus.svg";
import plusIcon from "../../../assets//icons/plus.svg";
import calendarIcon from "../../../assets/icons/calendar-default.svg";

import api from "../../../../infrastructure/services/api";
import Cookies from "universal-cookie";

import weeks_calander from "../../../constants/weeks";

import jwt_decode from "jwt-decode";

const time = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

const initialiseForm = {
  registrationNumber: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  category: "",
  vehicule: {},
  prestations: "",
};

function Home() {
  const [decoded, setdecoded] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddModel, setIsOpenAddModel] = useState(false);
  const [isCardOpen, setCardIsOpen] = useState(true);
  const [weeks, setWeeks] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [confirmed, setConfirmed] = useState(0);
  const [canceled, setCanceled] = useState(0);
  const [done, setDone] = useState(0);
  const [progressing, setProgressing] = useState(0);
  const [week, setWeek] = useState({
    dates: [],
  });

  const [form, setForm] = useState(initialiseForm);

  const [modelData, setModelData] = useState({});
  const [balance, setBlanace] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [criteria, setCriteria] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedDate, setSlelectedDate] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const [alert_type, setAlert_type] = useState("");
  const alertRef = useRef(null);

  useEffect(() => {
    if (api.getAuthToken() !== null) {
      setdecoded(jwt_decode(api.getAuthToken()));
    }
  }, []);

  useEffect(() => {
    __getMyBusinessAppointments();
    __getMyBusinessProfile();
    const cookies = new Cookies();
    if (cookies.get("isCalanderOpen") === undefined) {
      cookies.set("isCalanderOpen", "true");
    } else if (cookies.get("isCalanderOpen") == "false") {
      setCardIsOpen(!isCardOpen);
    }
    setWeeks(weeks_calander);
    __getWeeks();
  }, []);

  useEffect(() => {
    const categories_response = api.getCategories();
    categories_response.then((response) => {
      setCategories(response.data);
    });
  }, []);

  // Get My Business Profile

  const __getMyBusinessProfile = () => {
    const response = api.getMyBusinessProfile();
    response.then((response) => {
      setBlanace((response.data.data.business.balance / 100).toFixed(2));
      setTotalBalance((response.data.data.business.balance / 100).toFixed(2));
    });
  };

  // Get My Business Appointments

  const __getMyBusinessAppointments = () => {
    const response = api.getMyBusinessAppointments();
    response.then((response) => {
      if (response.status === 200) {
        setAppointments(response.data.data.business.appointments);
        setConfirmed(
          response.data.data.business.appointments.filter(
            (e) => e.status == "CONFIRMED"
          ).length
        );
        setProgressing(
          response.data.data.business.appointments.filter(
            (e) => e.status == "UNCONFIRMED"
          ).length
        );
        setCanceled(
          response.data.data.business.appointments.filter(
            (e) => e.status == "CANCELED"
          ).length
        );
        setDone(
          response.data.data.business.appointments.filter(
            (e) => e.status == "DONE"
          ).length
        );
      }
    });
  };

  const __getWeeks = () => {
    const toDay = new moment().format("MM/DD/y");
    weeks_calander.map((e) => {
      if (
        moment(e.start).valueOf() <= moment(toDay).valueOf() &&
        moment(e.end).valueOf() >= moment(toDay).valueOf()
      ) {
        setWeek(e);
      }
    });
  };

  const nextWeek = () => {
    if (weeks.length >= week.id + 1) setWeek(weeks[week.id + 1]);
  };
  const prevWeek = () => {
    if (week.id + 1 >= 0) setWeek(weeks[week.id - 1]);
  };

  const __showModel = (data) => {
    setModelData(data);
    setIsOpen(true);
  };

  const __confirmAppointment = (appointment) => {
    appointment.appointmentId = appointment._id;
    appointment.status = "CONFIRMED";
    const response = api.updateStatusAppointment(appointment);
    response.then((response) => {
      if (response.status === 201) {
        __getMyBusinessAppointments();
        setIsOpen(false);
        setModelData([]);
      }
    });
  };

  const __cancelAppointment = (appointment) => {
    appointment.appointmentId = appointment._id;
    appointment.status = "CANCELED";
    const response = api.updateStatusAppointment(appointment);
    response.then((response) => {
      if (response.status === 201) {
        __getMyBusinessAppointments();
        setIsOpen(false);
        setModelData([]);
      }
    });
  };

  // const __deleteAppointment = (appointment) => {
  //     appointment.appointmentId = appointment._id
  //     appointment.status = "deleted"
  //     const response = api.updateStatusAppointment(appointment)
  //     response.then((response) => {
  //         if(response.status === 201){
  //             __getMyBusinessAppointments()
  //             setIsOpen(false)
  //             setModelData([])
  //         }
  //     });
  // }

  const __setCardIsOpen = () => {
    const cookies = new Cookies();
    cookies.set("isCalanderOpen", !isCardOpen);
    setCardIsOpen(!isCardOpen);
  };

  const __showAddModel = (date, time) => {
    setSlelectedDate(moment(date).format("y-MM-DD") + "T" + time + ":00.000Z");
    setIsOpenAddModel(true);
  };

  const __create = () => {
    if (form.registrationNumber == "" && form.registrationNumber.length > 12) {
      setAlert_message((message) => "Merci de vérifier l'immatriculation");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (form.prestations == "") {
      setAlert_message((message) => "Merci de choisir une prestation");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (
      form.firstName == "" ||
      form.lastName == "" ||
      form.firstName.length > 40 ||
      form.lastName.length > 40
    ) {
      setAlert_message((message) => "Merci de vérifier votre nom et prénom.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      // get vehicule info
      const response = api.plateSearch(form.registrationNumber);

      response.then((response) => {
        if (response.data.success && response.data.data[0]?.VEHICULE) {
          const newForm = {
            ...form,
            vehicule: {
              PLATE: form.registrationNumber,
              CODMARQ: response.data.data[0]?.VEHICULE[0]?.CODMARQ[0],
              CODTYPE: response.data.data[0]?.VEHICULE[0]?.CODTYPE[0],
              DEBUT: response.data.data[0]?.VEHICULE[0]?.DEBUT[0].substring(
                0,
                4
              ),
              ALIM: response.data.data[0]?.VEHICULE[0]?.ALIM[0],
              NAME:
                response.data.data[0]?.VEHICULE[0]?.LIBMARQ[0] +
                " " +
                response.data.data[0]?.VEHICULE[0]?.LIBMODELEETAI[0],
            },
            start_date_time: selectedDate,
          };
          setForm(newForm);
          const res = api.createAppointemntNotAuth(newForm);
          res.then((res) => {
            if (res.data.success) {
              setIsOpenAddModel(false);
              setForm(initialiseForm);
              setAlert_message((message) => "Rendez-vous ajouté avec succès");
              setAlert_type((alert_type) => "success");
              alertRef.current.show();
              __getMyBusinessAppointments();
            } else {
              setAlert_message(
                (message) => "Une erreur s'est produite veuillez réessayer"
              );
              setAlert_type((alert_type) => "danger");
              alertRef.current.show();
            }
          });
        } else {
          setAlert_message((message) => "Merci de vérifier l'immatriculation.");
          setAlert_type((alert_type) => "warning");
        }
      });
    }
  };

  const __handleCategory = async (category) => {
    setCategory(category);
    const response = api.getSubCategoriesByBusiness(decoded._id, category);
    response.then((response) => {
      setSubCategories(response.data.data);
    });
    setForm({
      ...form,
      prestations: [],
    });
  };

  const __handleSubCategory = (subCategory) => {
    setForm({
      ...form,
      prestations: [JSON.parse(subCategory)],
    });
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader />
      <Alert type={alert_type} message={alert_message} ref={alertRef} />

      <div className="card-info">
        <InfoCard
          route="/workshop/dashboard/appointments"
          count={appointments ? appointments.length : 0}
          text="RENDEZ-VOUS"
          path={backImage}
        />
        <InfoCard
          route="/workshop/dashboard/orders"
          count={totalBalance + "€"}
          text="CHIFFRE D'AFFAIRES"
          path={backImage}
        />
        <InfoCard
          route="/workshop/dashboard/orders"
          count={balance + "€"}
          text="EN COURS"
          path={backImage}
        />
      </div>
      <div className="calander-card">
        <img
          onClick={() => __setCardIsOpen()}
          style={{ width: isCardOpen ? "28px" : "32px" }}
          className="card-icon"
          src={isCardOpen ? minusIcon : plusIcon}
          alt=""
        />
        <div className="calander">
          <div className="calander-header">
            <div className="week-option">
              <span className="week-previous">
                <button
                  onClick={() => {
                    prevWeek();
                  }}
                >
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="ChevronLeftIcon"
                  >
                    <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                  </svg>
                </button>
              </span>
              <span className="week-next">
                <button
                  onClick={() => {
                    nextWeek();
                  }}
                >
                  <svg
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="ChevronRightIcon"
                  >
                    <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                  </svg>
                </button>
              </span>
              <span className="week">
                {moment(week.start).format("DD MMM") +
                  " - " +
                  moment(week.end).format("DD MMM Y")}
              </span>
            </div>
            <h2 className="calander-title">Mon Agenda</h2>
            <span className="calander-week-number">Semaine {week.count}</span>
          </div>

          {isCardOpen ? (
            <div className="calander-contain">
              <div className="calander-date">
                <div className="date-icon">
                  <img src={calendarIcon} />
                </div>
                {week.dates
                  ? week.dates.map((e, index) => {
                      return (
                        <div className="date" key={index}>
                          <strong>{moment(e).format("dddd")}</strong>
                          <br />
                          <span className="date-number">
                            {moment(e).format("DD/MM")}
                          </span>
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div className="calander-body">
                <table>
                  {time.map((e, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <th>{e}</th>
                          {week.dates.map((date, i) => {
                            let data = appointments.find(
                              (t) =>
                                moment(t.start_date_time).format("MM/DD/y") ==
                                  date &&
                                moment(t.start_date_time).format("HH:mm") == e
                            );
                            if (data?.start_date_time) {
                              return (
                                <th key={i}>
                                  <div
                                    onClick={() => {
                                      __showModel(data);
                                    }}
                                    className="calander-item"
                                    style={{
                                      background:
                                        data.status === "CONFIRMED"
                                          ? "RGB(37, 185, 104)"
                                          : data.status == "UNCONFIRMED"
                                          ? "RGB(251, 188, 5)"
                                          : data.status == "DONE"
                                          ? "RGB(0, 152, 79)"
                                          : "RGB(255, 83, 73)",
                                    }}
                                  >
                                    <span className="item-title">
                                      RDV N° {data.number}
                                    </span>
                                  </div>
                                </th>
                              );
                            } else {
                              return (
                                <th
                                  onClick={() => {
                                    __showAddModel(date, e);
                                  }}
                                  key={i}
                                  className="calander-item"
                                >
                                  <div className="calander-item">
                                    <span className="item-title">
                                      <button className="btn-add-appointment">
                                        +
                                      </button>
                                    </span>
                                  </div>
                                </th>
                              );
                            }
                          })}
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="rendez_vous_counter">
          <p>
            <span></span>
            {confirmed} RDV Confirmé
          </p>
          <p>
            <span></span>
            {progressing} RDV à Confirmé
          </p>
          <p>
            <span></span>
            {canceled} RDV Annulé
          </p>
          <p>
            <span></span>
            {done} RDV Terminé
          </p>
        </div>
      </div>

      <Modal
        isOpen={isOpenAddModel}
        ariaHideApp={false}
        onRequestClose={() => setIsOpenAddModel(false)}
        className="modal-container-30"
        overlayClassName="overlay-modal"
      >
        <h2 className="modal-title mb-60">Ajouter un rendez-vous</h2>
        <div className="dashboard-content-body">
          <input
            type="text"
            className="input uppercase"
            value={form.registrationNumber}
            placeholder="Immatriculation"
            onInput={(e) =>
              setForm({
                ...form,
                registrationNumber: e.target.value.toUpperCase(),
              })
            }
          />
          <select
            className="modal-input"
            onChange={(e) => {
              __handleCategory(e.target.value);
            }}
            defaultValue=""
          >
            <option value="" disabled selected>
              Catégorie
            </option>
            {categories.map((categ, index) => (
              <option value={categ.category} key={index}>
                {categ.category_name}
              </option>
            ))}
          </select>

          {subCategories.length ? (
            <select
              className="modal-input"
              onChange={(e) => __handleSubCategory(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled selected>
                Sous-Catégorie
              </option>
              {subCategories.map((subCateg, index) => (
                <option value={JSON.stringify(subCateg)} key={index}>
                  {subCateg.title}
                </option>
              ))}
            </select>
          ) : (
            ""
          )}
          <input
            type="text"
            className="input"
            value={form.lastName}
            placeholder="Nom"
            onInput={(e) =>
              setForm({
                ...form,
                lastName: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="input"
            value={form.firstName}
            placeholder="Prénom"
            onInput={(e) =>
              setForm({
                ...form,
                firstName: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="input"
            value={form.phone}
            placeholder="Téléphone"
            onInput={(e) =>
              setForm({
                ...form,
                phone: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="input"
            value={form.address}
            placeholder="Adresse"
            onInput={(e) =>
              setForm({
                ...form,
                address: e.target.value,
              })
            }
          />
          <div
            style={{
              marginTop: "50px",
              textAlign: "right",
            }}
          >
            <button className="btn btn-dashboard" onClick={() => __create()}>
              Ajouter
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={() => setIsOpen(false)}
        className="modal-container-30"
        overlayClassName="overlay-modal"
      >
        <h2 className="modal-title mb-60">Rendez-vous N°{modelData.number}</h2>
        <div className="dashboard-content-body">
          <div className="d-flex space-between">
            <div>
              <strong>Nom du Client :</strong>
            </div>
            <div className="f-500">
              {modelData.personal?.firstName +
                " " +
                modelData.personal?.lastName}
            </div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Téléphone :</strong>
            </div>
            <div className="f-500">{modelData.personal?.phoneNumber}</div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Adresse :</strong>
            </div>
            <div className="f-500">{modelData.personal?.address}</div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Immatriculation :</strong>
            </div>
            <div className="f-500">{modelData.vehicule?.PLATE}</div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Véhicule :</strong>
            </div>
            <div className="f-500">{modelData.vehicule?.NAME}</div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Prestation :</strong>
            </div>
            {modelData?.prestations?.map((prestation, index) => {
              return (
                <div key={index} className="f-500">
                  {prestation.title}
                </div>
              );
            })}
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Prix :</strong>
            </div>
            <div className="f-500">
              {(modelData.price / 100).toFixed(2)}€ TTC
            </div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Prix de service :</strong>
            </div>
            <div className="f-500">
              {(modelData.service_price / 100).toFixed(2)}€ TTC
            </div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Date et Heure :</strong>
            </div>
            <div className="f-500">
              {moment(modelData.start_date_time).format("DD/MM/y") +
                "  à " +
                moment(modelData.start_date_time).format("HH:mm")}
            </div>
          </div>
          <div className="d-flex space-between">
            <div>
              <strong>Pièces :</strong>
            </div>
            {modelData?.pieceOrders?.map((pieceOrder, index) => {
              return (
                <div key={index} className="f-500">
                  {pieceOrder.pieceRef}
                  <br />
                  {pieceOrder.pieceLib}
                  <br />
                  <strong>
                    {pieceOrder.status === "PROGRESS"
                      ? "EN COURS"
                      : pieceOrder.status === "CONFIRMED"
                      ? "CONFIRMÉ"
                      : "TERMINÉ"}
                  </strong>
                  <hr />
                </div>
              );
            })}
          </div>

          <div
            className="d-flex"
            style={{ justifyContent: "center", marginTop: "50px", gap: "30px" }}
          >
            {modelData.status === "UNCONFIRMED" ? (
              <>
                <button
                  onClick={() => __confirmAppointment(modelData)}
                  className="btn btn-success w-100"
                >
                  Confirmer
                </button>
                <button
                  onClick={() => __cancelAppointment(modelData)}
                  className="btn btn-danger w-100"
                >
                  Annuler
                </button>
              </>
            ) : modelData.status === "CONFIRMED" ? (
              <span style={{ color: "RGB(37, 185, 104)", fontWeight: "600" }}>
                Confirmè le {moment(modelData.confirmed_at).format("DD/MM/y")}
              </span>
            ) : modelData.status === "DONE" ? (
              <span style={{ color: "rgb(0 152 79)", fontWeight: "600" }}>
                Terminer
              </span>
            ) : (
              <span style={{ color: "red", fontWeight: "600" }}>Annuler</span>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default Home;
