import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import api from "../../../../infrastructure/services/api";

import "./styles.css";
import EditIcon from "../../../assets/icons/edit.svg";
import DashboardHeader from "../../../components/Headers/DashboardHeader";
import { ApiConfig } from "../../../../infrastructure/services/env";
import PictureIcon from "../../../assets/icons/upload-image.svg";
import DeleteIcon from "../../../assets/icons/delete-red.svg";
import Alert from "../../../components/Alert";
import plusIcon from "../../../assets/icons/plus.svg";
import minusIcon from "../../../assets/icons/minus.svg";
import { Category, Padding } from "@mui/icons-material";

function Services2() {
  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    __getCategories();
    __getWorkShopPrice();
  }, []);

  const __getCategories = () => {
    const response = api.getCategories();
    response.then((response) => {
      setCategories(response.data);
      __getWorks(response.data);
    });
  };
  const __getWorkShopPrice = () => {
    const response = api.getWorkShopPrice();
    response.then((response) => {
      if (response.data.success) {
        setPrice(response.data.data);
      }
    });
  };

  const __getWorks = (cats) => {
    const response = api.getWorks();
    response.then((response) => {
      if (response.data.data.length) {
        let Categories = [];
        cats.map((category) => {
          let SubGroups = [];
          category.SubGroups.map((subGroup) => {
            let ItemMps = [];

            subGroup.ItemMps.map((itemMp) => {
              let findItem = response.data.data.find((workId) => {
                let workIdSplit = workId
                  .split("-")
                  .map((item) => parseInt(item, 10));
                let ItemId = workIdSplit[2];
                let subCatId = workIdSplit[1];
                let catId = workIdSplit[0];

                return (
                  ItemId == itemMp.ItemMpId &&
                  subCatId == subGroup.SubGroupId &&
                  catId == category.MainGroupId
                );
              });
              if (findItem) {
                ItemMps.push({
                  ItemMpId: findItem
                    .split("-")
                    .map((item) => parseInt(item, 10))[2],
                });
              }
              return;
            });

            if (ItemMps.length) {
              SubGroups.push({
                SubGroupId: subGroup.SubGroupId,
                ItemMps: ItemMps,
              });
            }
          });
          if (SubGroups.length) {
            Categories.push({
              MainGroupId: category.MainGroupId,
              SubGroups: SubGroups,
            });
          }
        });
        setCheckedItems(Categories);
        // setCheckedItems([
        //   {
        //     MainGroupId: 1,
        //     SubGroups: [
        //       {
        //         SubGroupId: 1,
        //         ItemMps: [
        //           {
        //             ItemMpId: 1,
        //           },
        //           {
        //             ItemMpId: 2,
        //           },
        //           {
        //             ItemMpId: 3,
        //           },
        //           {
        //             ItemMpId: 6,
        //           },
        //           {
        //             ItemMpId: 6941,
        //           },
        //           {
        //             ItemMpId: 9981,
        //           },
        //           {
        //             ItemMpId: 9982,
        //           },
        //           {
        //             ItemMpId: 9355,
        //           },
        //           {
        //             ItemMpId: 9362,
        //           },
        //           {
        //             ItemMpId: 17,
        //           },
        //           {
        //             ItemMpId: 20,
        //           },
        //           {
        //             ItemMpId: 8961,
        //           },
        //           {
        //             ItemMpId: 25,
        //           },
        //           {
        //             ItemMpId: 4544,
        //           },
        //           {
        //             ItemMpId: 30,
        //           },
        //           {
        //             ItemMpId: 8295,
        //           },
        //           {
        //             ItemMpId: 35,
        //           },
        //           {
        //             ItemMpId: 11987,
        //           },
        //           {
        //             ItemMpId: 47,
        //           },
        //           {
        //             ItemMpId: 12765,
        //           },
        //           {
        //             ItemMpId: 12795,
        //           },
        //           {
        //             ItemMpId: 12796,
        //           },
        //           {
        //             ItemMpId: 12797,
        //           },
        //           {
        //             ItemMpId: 12764,
        //           },
        //           {
        //             ItemMpId: 12802,
        //           },
        //           {
        //             ItemMpId: 12803,
        //           },
        //           {
        //             ItemMpId: 12804,
        //           },
        //           {
        //             ItemMpId: 10513,
        //           },
        //           {
        //             ItemMpId: 10514,
        //           },
        //           {
        //             ItemMpId: 10515,
        //           },
        //           {
        //             ItemMpId: 10516,
        //           },
        //           {
        //             ItemMpId: 10519,
        //           },
        //           {
        //             ItemMpId: 10520,
        //           },
        //           {
        //             ItemMpId: 10521,
        //           },
        //           {
        //             ItemMpId: 10522,
        //           },
        //           {
        //             ItemMpId: 12744,
        //           },
        //           {
        //             ItemMpId: 12794,
        //           },
        //           {
        //             ItemMpId: 12745,
        //           },
        //           {
        //             ItemMpId: 63,
        //           },
        //           {
        //             ItemMpId: 63,
        //           },
        //           {
        //             ItemMpId: 66,
        //           },
        //           {
        //             ItemMpId: 6940,
        //           },
        //           {
        //             ItemMpId: 5092,
        //           },
        //           {
        //             ItemMpId: 69,
        //           },
        //           {
        //             ItemMpId: 15305,
        //           },
        //           {
        //             ItemMpId: 73,
        //           },
        //           {
        //             ItemMpId: 72,
        //           },
        //           {
        //             ItemMpId: 76,
        //           },
        //           {
        //             ItemMpId: 4526,
        //           },
        //           {
        //             ItemMpId: 17418,
        //           },
        //           {
        //             ItemMpId: 81,
        //           },
        //           {
        //             ItemMpId: 84,
        //           },
        //           {
        //             ItemMpId: 96,
        //           },
        //           {
        //             ItemMpId: 10481,
        //           },
        //           {
        //             ItemMpId: 10482,
        //           },
        //           {
        //             ItemMpId: 10483,
        //           },
        //           {
        //             ItemMpId: 10484,
        //           },
        //           {
        //             ItemMpId: 98,
        //           },
        //           {
        //             ItemMpId: 100,
        //           },
        //           {
        //             ItemMpId: 9647,
        //           },
        //           {
        //             ItemMpId: 9648,
        //           },
        //           {
        //             ItemMpId: 9649,
        //           },
        //           {
        //             ItemMpId: 9650,
        //           },
        //           {
        //             ItemMpId: 9651,
        //           },
        //           {
        //             ItemMpId: 9652,
        //           },
        //           {
        //             ItemMpId: 9653,
        //           },
        //           {
        //             ItemMpId: 9654,
        //           },
        //           {
        //             ItemMpId: 9655,
        //           },
        //           {
        //             ItemMpId: 9656,
        //           },
        //           {
        //             ItemMpId: 105,
        //           },
        //           {
        //             ItemMpId: 107,
        //           },
        //           {
        //             ItemMpId: 108,
        //           },
        //           {
        //             ItemMpId: 109,
        //           },
        //           {
        //             ItemMpId: 111,
        //           },
        //           {
        //             ItemMpId: 113,
        //           },
        //           {
        //             ItemMpId: 4640,
        //           },
        //           {
        //             ItemMpId: 12740,
        //           },
        //           {
        //             ItemMpId: 8974,
        //           },
        //           {
        //             ItemMpId: 11153,
        //           },
        //           {
        //             ItemMpId: 9664,
        //           },
        //           {
        //             ItemMpId: 120,
        //           },
        //           {
        //             ItemMpId: 121,
        //           },
        //           {
        //             ItemMpId: 15480,
        //           },
        //           {
        //             ItemMpId: 122,
        //           },
        //           {
        //             ItemMpId: 124,
        //           },
        //           {
        //             ItemMpId: 8955,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 2,
        //         ItemMps: [
        //           {
        //             ItemMpId: 127,
        //           },
        //           {
        //             ItemMpId: 128,
        //           },
        //           {
        //             ItemMpId: 129,
        //           },
        //           {
        //             ItemMpId: 6773,
        //           },
        //           {
        //             ItemMpId: 130,
        //           },
        //           {
        //             ItemMpId: 17479,
        //           },
        //           {
        //             ItemMpId: 131,
        //           },
        //           {
        //             ItemMpId: 5689,
        //           },
        //           {
        //             ItemMpId: 133,
        //           },
        //           {
        //             ItemMpId: 12752,
        //           },
        //           {
        //             ItemMpId: 135,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 3,
        //         ItemMps: [
        //           {
        //             ItemMpId: 9678,
        //           },
        //           {
        //             ItemMpId: 9877,
        //           },
        //           {
        //             ItemMpId: 137,
        //           },
        //           {
        //             ItemMpId: 137,
        //           },
        //           {
        //             ItemMpId: 147,
        //           },
        //           {
        //             ItemMpId: 156,
        //           },
        //           {
        //             ItemMpId: 156,
        //           },
        //           {
        //             ItemMpId: 143,
        //           },
        //           {
        //             ItemMpId: 144,
        //           },
        //           {
        //             ItemMpId: 145,
        //           },
        //           {
        //             ItemMpId: 158,
        //           },
        //           {
        //             ItemMpId: 160,
        //           },
        //           {
        //             ItemMpId: 160,
        //           },
        //           {
        //             ItemMpId: 8979,
        //           },
        //           {
        //             ItemMpId: 138,
        //           },
        //           {
        //             ItemMpId: 163,
        //           },
        //           {
        //             ItemMpId: 165,
        //           },
        //           {
        //             ItemMpId: 9677,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 8,
        //         ItemMps: [
        //           {
        //             ItemMpId: 8933,
        //           },
        //           {
        //             ItemMpId: 8934,
        //           },
        //           {
        //             ItemMpId: 9688,
        //           },
        //           {
        //             ItemMpId: 187,
        //           },
        //           {
        //             ItemMpId: 187,
        //           },
        //           {
        //             ItemMpId: 11104,
        //           },
        //           {
        //             ItemMpId: 190,
        //           },
        //           {
        //             ItemMpId: 193,
        //           },
        //           {
        //             ItemMpId: 9704,
        //           },
        //           {
        //             ItemMpId: 6946,
        //           },
        //           {
        //             ItemMpId: 196,
        //           },
        //           {
        //             ItemMpId: 197,
        //           },
        //           {
        //             ItemMpId: 203,
        //           },
        //           {
        //             ItemMpId: 6554,
        //           },
        //           {
        //             ItemMpId: 6975,
        //           },
        //           {
        //             ItemMpId: 6555,
        //           },
        //           {
        //             ItemMpId: 7075,
        //           },
        //           {
        //             ItemMpId: 6556,
        //           },
        //           {
        //             ItemMpId: 6557,
        //           },
        //           {
        //             ItemMpId: 9017,
        //           },
        //           {
        //             ItemMpId: 9018,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 9,
        //         ItemMps: [
        //           {
        //             ItemMpId: 218,
        //           },
        //           {
        //             ItemMpId: 227,
        //           },
        //           {
        //             ItemMpId: 233,
        //           },
        //           {
        //             ItemMpId: 241,
        //           },
        //           {
        //             ItemMpId: 241,
        //           },
        //           {
        //             ItemMpId: 2470,
        //           },
        //           {
        //             ItemMpId: 2470,
        //           },
        //           {
        //             ItemMpId: 14630,
        //           },
        //           {
        //             ItemMpId: 4729,
        //           },
        //           {
        //             ItemMpId: 4455,
        //           },
        //           {
        //             ItemMpId: 249,
        //           },
        //           {
        //             ItemMpId: 11514,
        //           },
        //           {
        //             ItemMpId: 263,
        //           },
        //           {
        //             ItemMpId: 9003,
        //           },
        //           {
        //             ItemMpId: 10249,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 10,
        //         ItemMps: [
        //           {
        //             ItemMpId: 293,
        //           },
        //           {
        //             ItemMpId: 293,
        //           },
        //           {
        //             ItemMpId: 8985,
        //           },
        //           {
        //             ItemMpId: 296,
        //           },
        //           {
        //             ItemMpId: 4792,
        //           },
        //           {
        //             ItemMpId: 299,
        //           },
        //           {
        //             ItemMpId: 300,
        //           },
        //           {
        //             ItemMpId: 301,
        //           },
        //           {
        //             ItemMpId: 305,
        //           },
        //           {
        //             ItemMpId: 305,
        //           },
        //           {
        //             ItemMpId: 306,
        //           },
        //           {
        //             ItemMpId: 307,
        //           },
        //           {
        //             ItemMpId: 307,
        //           },
        //           {
        //             ItemMpId: 307,
        //           },
        //           {
        //             ItemMpId: 307,
        //           },
        //           {
        //             ItemMpId: 11508,
        //           },
        //           {
        //             ItemMpId: 321,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 11,
        //         ItemMps: [
        //           {
        //             ItemMpId: 335,
        //           },
        //           {
        //             ItemMpId: 6552,
        //           },
        //           {
        //             ItemMpId: 205,
        //           },
        //           {
        //             ItemMpId: 344,
        //           },
        //           {
        //             ItemMpId: 3027,
        //           },
        //           {
        //             ItemMpId: 3028,
        //           },
        //           {
        //             ItemMpId: 3029,
        //           },
        //           {
        //             ItemMpId: 3030,
        //           },
        //           {
        //             ItemMpId: 355,
        //           },
        //           {
        //             ItemMpId: 5425,
        //           },
        //           {
        //             ItemMpId: 5426,
        //           },
        //           {
        //             ItemMpId: 5427,
        //           },
        //           {
        //             ItemMpId: 5428,
        //           },
        //           {
        //             ItemMpId: 356,
        //           },
        //           {
        //             ItemMpId: 357,
        //           },
        //           {
        //             ItemMpId: 4944,
        //           },
        //           {
        //             ItemMpId: 347,
        //           },
        //           {
        //             ItemMpId: 364,
        //           },
        //           {
        //             ItemMpId: 6937,
        //           },
        //           {
        //             ItemMpId: 6595,
        //           },
        //           {
        //             ItemMpId: 324,
        //           },
        //           {
        //             ItemMpId: 323,
        //           },
        //           {
        //             ItemMpId: 330,
        //           },
        //           {
        //             ItemMpId: 330,
        //           },
        //           {
        //             ItemMpId: 367,
        //           },
        //           {
        //             ItemMpId: 9014,
        //           },
        //           {
        //             ItemMpId: 7049,
        //           },
        //           {
        //             ItemMpId: 5532,
        //           },
        //           {
        //             ItemMpId: 5533,
        //           },
        //           {
        //             ItemMpId: 5534,
        //           },
        //           {
        //             ItemMpId: 5535,
        //           },
        //           {
        //             ItemMpId: 370,
        //           },
        //           {
        //             ItemMpId: 10398,
        //           },
        //           {
        //             ItemMpId: 371,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 12,
        //         ItemMps: [
        //           {
        //             ItemMpId: 374,
        //           },
        //           {
        //             ItemMpId: 374,
        //           },
        //           {
        //             ItemMpId: 374,
        //           },
        //           {
        //             ItemMpId: 381,
        //           },
        //           {
        //             ItemMpId: 382,
        //           },
        //           {
        //             ItemMpId: 4251,
        //           },
        //           {
        //             ItemMpId: 389,
        //           },
        //           {
        //             ItemMpId: 4270,
        //           },
        //           {
        //             ItemMpId: 4129,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 13,
        //         ItemMps: [
        //           {
        //             ItemMpId: 390,
        //           },
        //           {
        //             ItemMpId: 9708,
        //           },
        //           {
        //             ItemMpId: 394,
        //           },
        //           {
        //             ItemMpId: 397,
        //           },
        //           {
        //             ItemMpId: 8967,
        //           },
        //           {
        //             ItemMpId: 399,
        //           },
        //           {
        //             ItemMpId: 400,
        //           },
        //           {
        //             ItemMpId: 8788,
        //           },
        //           {
        //             ItemMpId: 11249,
        //           },
        //           {
        //             ItemMpId: 11249,
        //           },
        //           {
        //             ItemMpId: 4390,
        //           },
        //           {
        //             ItemMpId: 414,
        //           },
        //           {
        //             ItemMpId: 415,
        //           },
        //           {
        //             ItemMpId: 418,
        //           },
        //           {
        //             ItemMpId: 3900,
        //           },
        //         ],
        //       },
        //       {
        //         SubGroupId: 14,
        //         ItemMps: [
        //           {
        //             ItemMpId: 421,
        //           },
        //           {
        //             ItemMpId: 421,
        //           },
        //           {
        //             ItemMpId: 9717,
        //           },
        //           {
        //             ItemMpId: 10416,
        //           },
        //           {
        //             ItemMpId: 426,
        //           },
        //           {
        //             ItemMpId: 427,
        //           },
        //           {
        //             ItemMpId: 429,
        //           },
        //           {
        //             ItemMpId: 3166,
        //           },
        //           {
        //             ItemMpId: 9711,
        //           },
        //           {
        //             ItemMpId: 9712,
        //           },
        //           {
        //             ItemMpId: 5688,
        //           },
        //           {
        //             ItemMpId: 435,
        //           },
        //           {
        //             ItemMpId: 438,
        //           },
        //           {
        //             ItemMpId: 438,
        //           },
        //           {
        //             ItemMpId: 13244,
        //           },
        //           {
        //             ItemMpId: 13245,
        //           },
        //           {
        //             ItemMpId: 10415,
        //           },
        //           {
        //             ItemMpId: 13246,
        //           },
        //           {
        //             ItemMpId: 13247,
        //           },
        //           {
        //             ItemMpId: 10436,
        //           },
        //           {
        //             ItemMpId: 10437,
        //           },
        //           {
        //             ItemMpId: 439,
        //           },
        //           {
        //             ItemMpId: 442,
        //           },
        //           {
        //             ItemMpId: 10417,
        //           },
        //         ],
        //       },
        //     ],
        //   },
        // ]);
      }
    });
  };

  const __updatePrestation = () => {
    const response = api.updateWorks(checkedItems);
    response.then((response) => {
      if (response.data.success) {
        setAlert_message((message) => "Mise a jour effectuée avec succès.");
        setAlert_type((alert_type) => "success");
        alertRef.current.show();
      }
    });
  };

  const __updateWorkPrice = () => {
    const response = api.updateWorkPrice(price);
    response.then((response) => {
      if (response.data.success) {
        setAlert_message((message) => "Mise a jour effectuée avec succès.");
        setAlert_type((alert_type) => "success");
        alertRef.current.show();
      }
    });
  };

  const handleCheck = (id, isChecked, mainGroupId, subGroupId) => {
    if (subGroupId) {
      if (isChecked) {
        setCheckedItems([
          ...checkedItems,
          {
            MainGroupId: mainGroupId,
            SubGroups: [
              {
                SubGroupId: subGroupId,
                ItemMps: [{ ItemMpId: id }],
              },
            ],
          },
        ]);
      } else {
        let items = checkedItems.map((item) => ({
          ...item,
          SubGroups: item.SubGroups.map((sub) => ({
            ...sub,
            ItemMps: sub.ItemMps.filter((mp) => mp.ItemMpId !== id),
          })),
        }));

        items = items.map((item) => ({
          ...item,
          SubGroups: item.SubGroups.filter((sub) => sub.ItemMps.length !== 0),
        }));

        setCheckedItems(
          items.filter((item) => {
            return item.SubGroups.length !== 0;
          })
        );
      }
    } else if (mainGroupId) {
      let mainCat = categories.find(
        (category) => category.MainGroupId === mainGroupId
      );
      let subCat = mainCat.SubGroups.find(
        (subCategory) => subCategory.SubGroupId === id
      );

      if (isChecked) {
        // let isExisit = checkedItems.find(
        //   (category) =>
        //     category.MainGroupId === mainGroupId &&
        //     category.SubGroups.findIndex(
        //       (subCategory) => subCategory.SubGroupId === id
        //     )
        // );

        setCheckedItems([
          ...checkedItems,
          {
            MainGroupId: mainGroupId,
            SubGroups: [
              {
                SubGroupId: subCat.SubGroupId,
                ItemMps: subCat.ItemMps.map((itemMp) => {
                  return { ItemMpId: itemMp.ItemMpId };
                }),
              },
            ],
          },
        ]);
      } else {
        let items = checkedItems.map((item) => ({
          ...item,
          SubGroups: item.SubGroups.filter((sub) => sub.SubGroupId !== id),
        }));
        setCheckedItems(
          items.filter((item) => {
            return item.SubGroups.length !== 0;
          })
        );
      }
    } else {
      let mainCat = categories.find((category) => category.MainGroupId === id);

      if (isChecked) {
        setCheckedItems([
          ...checkedItems,
          {
            MainGroupId: id,
            SubGroups: mainCat.SubGroups.map((subGroup) => {
              return {
                SubGroupId: subGroup.SubGroupId,
                ItemMps: subGroup.ItemMps.map((itemMp) => {
                  return { ItemMpId: itemMp.ItemMpId };
                }),
              };
            }),
          },
        ]);
      } else {
        setCheckedItems(checkedItems.filter((item) => item.MainGroupId !== id));
      }
    }
  };

  return (
    <div className="dashboard-content">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <DashboardHeader />
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Tarif appliqué</h2>
        </div>

        <div className="d-flex dashboard-content-body">
          <div
            className="d-flex"
            style={{
              flexDirection: "row",
              gap: "5px",
              width: "250px",
              alignItems: "flex-end",
            }}
          >
            <input
              className="input uppercase"
              style={{ padding: "13px 10px 4px 10px" }}
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              type="text"
            />
            <span
              style={{
                fontSize: "22px",
                fontWeight: 800,
                whiteSpace: "nowrap",
              }}
            >
              € TTC / Heure
            </span>
          </div>
          <div></div>
        </div>
        <button
          className="btn "
          style={{ width: "200px", alignSelf: "self-end" }}
          onClick={() => __updateWorkPrice()}
        >
          Mettre à jour
        </button>
      </div>
      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Liste des prestations</h2>
        </div>
        <div>
          {categories.map((group) => (
            <div key={group.MainGroupId}>
              <label>
                <input
                  type="checkbox"
                  checked={
                    !!checkedItems.find(
                      (category) => category.MainGroupId === group.MainGroupId
                    )
                  }
                  onChange={(e) =>
                    handleCheck(group.MainGroupId, e.target.checked)
                  }
                />
                {group.MainGroupName}
              </label>
              {group.SubGroups.map((subGroup) => (
                <div
                  key={group.MainGroupId + "-" + subGroup.SubGroupId}
                  style={{ marginTop: "5px" }}
                >
                  <label style={{ marginLeft: "30px" }}>
                    <input
                      type="checkbox"
                      checked={
                        !!checkedItems.find(
                          (category) =>
                            category.MainGroupId === group.MainGroupId &&
                            category.SubGroups.find(
                              (subCategory) =>
                                subCategory.SubGroupId === subGroup.SubGroupId
                            )
                        )
                      }
                      onChange={(e) =>
                        handleCheck(
                          subGroup.SubGroupId,
                          e.target.checked,
                          group.MainGroupId
                        )
                      }
                    />
                    {subGroup.SubGroupName}
                  </label>
                  {subGroup.ItemMps.map((item) =>
                    item.KorId == 7 ? (
                      <div
                        key={
                          group.MainGroupId +
                          "-" +
                          subGroup.SubGroupId +
                          "-" +
                          item.ItemMpId
                        }
                        style={{ marginTop: "5px" }}
                      >
                        <label style={{ marginLeft: "80px" }}>
                          <input
                            type="checkbox"
                            checked={
                              !!checkedItems.find(
                                (category) =>
                                  category.MainGroupId === group.MainGroupId &&
                                  category.SubGroups.find(
                                    (subCategory) =>
                                      subCategory.SubGroupId ===
                                        subGroup.SubGroupId &&
                                      subCategory.ItemMps.find(
                                        (ItemMp) =>
                                          ItemMp.ItemMpId === item.ItemMpId
                                      )
                                  )
                              )
                            }
                            onChange={(e) =>
                              handleCheck(
                                item.ItemMpId,
                                e.target.checked,
                                group.MainGroupId,
                                subGroup.SubGroupId
                              )
                            }
                          />
                          <strong>
                            {group.MainGroupId +
                              "-" +
                              subGroup.SubGroupId +
                              "-" +
                              item.ItemMpId}{" "}
                          </strong>{" "}
                          * Remplacer {item.ItemMpText}
                        </label>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="form-row center" style={{ marginTop: "40px" }}>
          <button className="btn " onClick={() => __updatePrestation()}>
            Mettre à jour
          </button>
        </div>
      </div>
    </div>
  );
}

export default Services2;
