import React , {useState, useEffect} from "react";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import api from '../../../infrastructure/services/api';

const VisitorProtectedRoutes = ({children}) => {
    const [token, setToken] = useState(null);
    const [decoded, setdecoded] = useState({});

    useEffect(() => {
        setToken(api.getAuthToken());
        if (api.getAuthToken() !== null) {
            setdecoded(jwt_decode(api.getAuthToken()));
        }
    }, []);
    
    if (token === null) 
        return children;
    else if (token  && decoded.role === "admin")
        return (
        <Navigate to="/admin/dashboard" replace />
        );
    else if (token  && decoded.role === "personal")
        return (
        <Navigate to="/driver/dashboard" replace />
        );
    else  if (token && decoded.role === "business")
        return (
        <Navigate to="/workshop/dashboard" replace />
        );
    else
        return (
        <Navigate to="/" replace />
        );
}

export default VisitorProtectedRoutes;