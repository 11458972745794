import React, {useState, useEffect} from 'react';

import api from '../../../../infrastructure/services/api';

import './styles.css';
import DashboardHeader from '../../../components/Headers/DashboardHeader';
import HelpCard from '../../../components/Cards/HelpCard';
import Calendar from '../../../components/Calendar';

function CalendarAppointments () {
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        __getAppointments();
    }, []);

    // get Appointments
    const __getAppointments = () => {
        const response = api.getMyBusinessAppointments();
        response.then(response => {
            setAppointments(response.data.data.business.appointments);
        });
    }

    return(
        <div className='dashboard-content'>
            <DashboardHeader />

            <HelpCard />
            
            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Mes rendez-vous</h2>
                </div>

                <Calendar appointments={appointments} />
            </div>
        </div>
    )
}

export default CalendarAppointments;