import React, { useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";

import "./styles.css";
import DriverNavBar from "../../../components/NavBars/DriverNavBar";
import Alert from "../../../components/Alert";

import api from "../../../../infrastructure/services/api";
import { DataContext } from "../../../../application";
import { validateEmail } from "../../../utils/input-verification";
import Footer from "../../../components/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  getData,
  getObject,
  removeData,
  storeData,
  storeObject,
} from "../../../../application/helpers/local-storage";

function Login() {
  const navigate = useNavigate();
  const { dataContext } = React.useContext(DataContext);
  let location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  const __login = () => {
    if (!validateEmail(email)) {
      setAlert_message((message) => "Merci de vérifier votre addresse mail.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (password.length === 0) {
      setAlert_message((message) => "Merci de vérifier votre mot de passe.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const response = api.login(email, password);
      response.then((response) => {
        if (response.status === 200) {
          var decoded_token = jwt_decode(response.data.token);
          if (decoded_token.role === "personal") {
            dataContext.login(response.data.token);

            let has_new_appointment = JSON.parse(getData("new_appointment"));
            if (has_new_appointment) {
              let new_appointment = {
                business: has_new_appointment?.business,
                start_date_time: has_new_appointment?.start_date_time,
                end_date_time: has_new_appointment?.end_date_time,
                prestations: has_new_appointment?.prestation,
              };
              removeData("new_appointment");
              navigate("/confirm-appointment", {
                state: {
                  workshop: has_new_appointment?.business,
                  appointment: new_appointment,
                },
              });
            } else {
              navigate("/driver/dashboard");
            }
          } else {
            setAlert_message(
              (message) =>
                "Vous n'êtes pas permis de se connecter en tant q'automobiliste !"
            );
            setAlert_type((alert_type) => "error");
            alertRef.current.show();
          }
        } else if (response.status === 201) {
          setAlert_message(
            (message) => "Merci de vérifier votre mot de passe."
          );
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
      response.catch((error) => {
        if (error.response.status === 403) {
          setAlert_message((message) => "Merci de vérifier votre e-mail.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        } else {
          setAlert_message((message) => "Merci de vérifier votre connexion.");
          setAlert_type((alert_type) => "warning");
          alertRef.current.show();
        }
      });
    }
  };

  return (
    <>
      <div className="d-block min-h-700">
        <Alert type={alert_type} message={alert_message} ref={alertRef} />
        <DriverNavBar text="S'inscrire" onClick={() => navigate("/driver")} />

        <div className="login-workshop-container driver">
          <div className="login-workshop-header">
            <h3>Connectez-vous</h3>
            <p>Renforcez votre confort de travail grâce à Cars d'Heure</p>
          </div>
          <div className="login-form">
            <div className="group">
              <FontAwesomeIcon icon={faEnvelope} style={{ color: "#a5a5a5" }} />
              <input
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="group">
              <FontAwesomeIcon icon={faLock} style={{ color: "#a5a5a5" }} />
              <input
                type="password"
                value={password}
                placeholder="Mot de passe"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button className="btn" onClick={__login}>
              Se connecter
            </button>
            <span className="divider">Ou</span>
            <button
              className="btn btn_secondary"
              onClick={() => navigate("/driver")}
            >
              S'inscrire
            </button>
            <a
              href="/password-rest"
              style={{ fontSize: "13px", textAlign: "right" }}
            >
              Mot de passe oublié ?
            </a>
          </div>
        </div>
      </div>
      <div>
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

export default Login;
