import React from "react";

import "./styles.css";
import LeftQuote from "../../../assets/icons/left-quote.svg";
import RightQuote from "../../../assets/icons/right-quote.svg";
import UserPicture from "../../../assets/images/home/MarwenEssalah.jpg";

import Carousel from "react-bootstrap/Carousel";

function FeedbackCard() {
  return (
    <Carousel>
      <Carousel.Item>
        <div className="feedback-container">
          <div className="feedback-header">
            <h1>La meilleure des publicité, est un client satisfait</h1>
          </div>
          <div className="feedback-body">
            <div className="feedback-image-background"></div>
            <div className="user-card-conatiner">
              <span className="user-image">M</span>
              <h3>Marwen Essalah</h3>
              <span>27 Sept. 2022</span>
            </div>
            <div className="feedback-comment-container">
              <img alt="quote" src={LeftQuote} className="quote-icon" />

              <p className="feedback-comment">
                Une plateforme utile et simple à utiliser. Les garagiste sont
                très professionnels. Merci beaucoup, je recommande !
              </p>

              <img
                alt="quote"
                src={RightQuote}
                className="quote-icon"
                style={{ alignSelf: "flex-end" }}
              />
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default FeedbackCard;
