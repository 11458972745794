import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EnvironmentOutlined } from "@ant-design/icons";

import { DataContext } from "../../../../application";
import { ApiConfig } from "../../../../infrastructure/services/env";
import IconLike from "../../../../views/assets/icons/like.svg";
import IconCalander from "../../../../views/assets/icons/calendar-dark.svg";
import IconTimer from "../../../../views/assets/icons/timer.svg";
import IconLocation from "../../../../views/assets/icons/location-black.svg";
import "./styles.css";
import Alert from "../../Alert";
import moment from "moment";
import frLocale from "moment/locale/fr";

function ShowNextDate(schedule) {
  let nextDay = new moment().hours() > 11 ? 3 : 2;
  for (; nextDay <= 8; nextDay++) {
    if (new moment().add(nextDay, "days").day()) break;
  }
  return new moment()
    .add(nextDay, "days")
    .locale("fr", [frLocale])
    .format("dddd, DD-MM-YYYY");
}

function ConvertMinutes(num) {
  const d = Math.floor(num / 1440); // 60*24
  const h = Math.floor((num - d * 1440) / 60);
  const m = Math.round(num % 60);

  if (d > 0) {
    return d + " d " + h + " h " + m + " m";
  } else {
    return h + " h " + m + " m";
  }
}

function ServiceCard({ service, typeId }) {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  const __vistiWorkshop = () => {
    if (state.vehicule !== null) {
      navigate(`/workshop-services/${service._id}?carId=${typeId}`);
    } else {
      setAlert_message((message) => "Merci de vérifier votre immatriculation.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    }
  };
  return (
    <div className="service-card">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      {!service.cover_img ? (
        <img src={ApiConfig.IMAGES + service.cover_img} alt="service" />
      ) : (
        <img src="/carsdheur.webp" alt="service" />
      )}
      <button className="service-rate">
        <strong>{service.rate}</strong>
        <span className="total-rate"> ( {service.rate} avis)</span>
      </button>
      <span className="service-card-title">{service.workshop_name}</span>
      <span className="service-card-subTitle">
        A partir de :{" "}
        <span className="service-card-active-subTitle">
          {service.starting_price} €
          <span style={{ fontSize: "14px" }}>/ Heure</span>
        </span>
      </span>
      <span className="service-card-subTitle">
        <div className="service-info">
          <img src={IconLocation} alt="location" />
          <span
            className="service-card-active-subTitle"
            style={{ fontSize: "14px" }}
          >
            {service.address}
          </span>
        </div>
      </span>

      <div className="service-infos">
        {/* <p><EnvironmentOutlined />{service.address}</p> */}
        {/* <div className="service-info">
          <img src={IconTimer} alt="timer" />
          {ConvertMinutes(service.duration)}
        </div> */}

        <div className="service-info">
          <img src={IconCalander} alt="calander" />
          {ShowNextDate(service.schedule)}
        </div>
      </div>
      <button className="btn" onClick={() => __vistiWorkshop()}>
        Prenez rendez-vous
      </button>
    </div>
  );
}

export default ServiceCard;
