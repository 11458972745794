import React , {useState, useEffect} from "react";
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import api from '../../../infrastructure/services/api';

const ProtectedRoutes = ({children}) => {
  const [token, setToken] = useState(api.getAuthToken());
  const [decoded, setdecoded] = useState(api.getAuthToken() != null ? jwt_decode(api.getAuthToken()) : null);
  
  useEffect(() => {
    setToken(api.getAuthToken());
    setdecoded(api.getAuthToken() != null ? jwt_decode(api.getAuthToken()) : null);
  }, []);

  if (token && decoded.role === "business") 
    return children;
  else if (token  && decoded.role === "personal")
    return (
      <Navigate to="/" replace />
    );
    else  if (token && decoded.role === "admin")
    return (
      <Navigate to="/admin/dashboard" replace />
    );
  else
    return (
      <Navigate to="/" replace />
    );
}

export default ProtectedRoutes;