import React, { useState, useEffect } from "react";

import api from "../../../../infrastructure/services/api";

import "./styles.css";
import DashboardHeader from "../../../components/Headers/AdminDashboardHeader";

function WorkshopList() {
  const [workshops, setWorkshops] = useState([]);

  const __getWorkshops = () => {
    const workshops_response = api.getWorkshops();
    workshops_response.then((response) => {
      setWorkshops(response.data.data);
    });
  };

  useEffect(() => {
    __getWorkshops();
  }, []);

  // Validate Business
  const __validateBusiness = (workshop) => {
    const response = api.validateWorkshop(workshop);
    response.then((response) => {
      __getWorkshops();
    });
  };

  return (
    <div className="dashboard-content">
      <DashboardHeader />

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Liste des garagistes</h2>
        </div>

        <table>
          <thead>
            <tr>
              <th>N°</th>
              <th>Nom & Prénom</th>
              <th>Nom de l'atelier</th>
              <th>Informations</th>
              <th>Adresse</th>
              <th>Téléphone</th>
              <th>E-mail</th>
              <th>Portefeuilles</th>
              <th>Vérifier</th>
              <th>Option</th>
            </tr>
          </thead>

          <tbody>
            {workshops.map((workshop, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {workshop.lastName} {workshop.firstName}
                </td>
                <td>{workshop.business?.workshop_name ?? ""}</td>
                <td>
                  <div>
                    <strong>années d'expérience: </strong>
                    {workshop.business.business_infos?.work_years}
                  </div>
                  <div>
                    <strong>Ouverture: </strong>
                    {workshop.business.business_infos?.since}
                  </div>
                  <div>
                    <strong>nombre d'employé: </strong>
                    {workshop.business.business_infos?.number_of_workers}
                  </div>
                  <div>
                    <strong>Responsable: </strong>
                    {workshop.business.business_infos?.manager_name}
                  </div>
                  <div>
                    {workshop.business.business_infos?.means_of_access.map(
                      (e) => {
                        <div>{e}</div>;
                      }
                    )}
                  </div>
                </td>
                <td>{workshop.business?.workshop_address.label ?? ""}</td>
                <td>{workshop.business.business_infos?.phoneNumber ?? ""}</td>
                <td>
                  {workshop.local?.email ?? ""}
                  <br />
                  <span
                    style={{
                      fontSize: "10px",
                      color: workshop.activated ? "green" : "red",
                    }}
                  >
                    {workshop.activated ? "Confirmer" : "Non Confirmer"}
                  </span>
                </td>
                <td>{(workshop.business?.balance / 100).toFixed(2)} Є</td>
                <td>
                  {workshop.business.activated ? (
                    <button
                      className="action-btn"
                      style={{ color: "green" }}
                      onClick={() => __validateBusiness(workshop._id)}
                    >
                      vérifier
                    </button>
                  ) : (
                    <button
                      className="action-btn"
                      style={{ color: "red" }}
                      onClick={() => __validateBusiness(workshop._id)}
                    >
                      unvérifier
                    </button>
                  )}
                </td>
                <td>Modifier</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WorkshopList;
