import React, { useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./styles.css";
import DriverNavBar from "../../components/NavBars/DriverNavBar";
import Alert from "../../components/Alert";

import api from "../../../infrastructure/services/api";
import { DataContext } from "../../../application";
import Footer from "../../components/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { validatePassword } from "../../utils/input-verification";

function Login() {
  const navigate = useNavigate();
  const { dataContext } = React.useContext(DataContext);
  let { token } = useParams();

  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  const __changePassword = () => {
    if (
      new_password.length === 0 ||
      !validatePassword(new_password) ||
      new_password !== confirm_password
    ) {
      // alert('Merci de vérifier votre mot de passe.');
      setAlert_message((message) => "Merci de vérifier votre mot de passe.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      const response = api.changePassword(token, new_password);
      response.then((response, err) => {
        if (response.status === 202) {
          setAlert_message(
            (message) => "Votre mot de passe a été modifié avec succès"
          );
          setAlert_type((alert_type) => "success");
          alertRef.current.show();
          setTimeout(() => {
            if (response.data.user.role === "business") {
              navigate("/workshop/login");
            } else {
              navigate("/driver/login");
            }
          }, 1200);
        } else if (response.status === 204) {
          setAlert_message(
            (message) => "Token invalide ou expiré. Veuillez vous reconnecter.."
          );
          setAlert_type((alert_type) => "error");
          alertRef.current.show();
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      });
      response.catch((error) => {
        setAlert_message(
          (message) => "Token invalide ou expiré. Veuillez vous reconnecter.."
        );
        setAlert_type((alert_type) => "error");
        alertRef.current.show();
        setTimeout(() => {
          navigate("/");
        }, 3000);
      });
    }
  };

  return (
    <>
      <div className="d-block min-h-700">
        <Alert type={alert_type} message={alert_message} ref={alertRef} />
        <DriverNavBar text="S'inscrire" onClick={() => navigate("/driver")} />

        <div className="login-workshop-container driver">
          <div className="login-workshop-header">
            <h3>Modifier votre mot de passe</h3>
            <p>
              Un mot de passe forte empêche l'accès non autorise à votre compte
              Cars D'heure
            </p>
          </div>
          <div className="login-form">
            <div className="group">
              <FontAwesomeIcon icon={faLock} style={{ color: "#a5a5a5" }} />
              <input
                type="password"
                value={new_password}
                placeholder="Nouveau mot de passe"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className="group">
              <FontAwesomeIcon icon={faLock} style={{ color: "#a5a5a5" }} />
              <input
                type="password"
                value={confirm_password}
                placeholder="Confirmer le nouveau mot de passe "
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <span style={{ fontSize: "10px" }}>
              Le mot de passe doit comporter au moins{" "}
              <strong>8 caractères</strong> et doit comporter au moins{" "}
              <strong>une majuscule</strong>, <strong> une minuscule </strong>{" "}
              et <strong>un caractère numérique</strong>
            </span>
            <button className="btn" onClick={__changePassword}>
              Modifier le mot de passe
            </button>
          </div>
        </div>
      </div>
      <div>
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}

export default Login;
