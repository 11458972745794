import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import "./styles.css";
import Alert from "../Alert";
import PositionIcon from "../../assets/icons/position";
import { validateRegistrationNumber } from "../../utils/input-verification";

import api from "../../../infrastructure/services/api";
import { DataContext } from "../../../application";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

function SearchBar({ categories }) {
  const navigate = useNavigate();
  const { state, dataContext } = React.useContext(DataContext);

  const [registrationNumber, setRegistrationNumber] = useState("");
  const [city, setCity] = useState("");
  const [cityCode, setCityCode] = useState(null);
  const [cityIsValid, setCityIsValid] = useState(false);
  const [userInput, setUserInput] = useState("");

  const [category, setCategory] = useState("");
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [locationError, setLocationError] = useState(false);

  const [location, setLocation] = useState(null);

  const [alert_type, setAlert_type] = useState("");
  const [alert_message, setAlert_message] = useState("");
  const alertRef = useRef(null);

  useEffect(() => {
    if (state.vehicule !== null && state.vehicule?.PLATE !== null) {
      setRegistrationNumber(state.vehicule?.PLATE);
    }
  }, []);

  const __getPosition = async () => {
    navigator.geolocation.getCurrentPosition(async function (position) {
      let result = await fetch(
        `https://geo.api.gouv.fr/communes?lat=48.856614&lon=2.3522219`
        // `https://geo.api.gouv.fr/communes?lat=${position.coords.latitude}&lon=${position.coords.longitude}`
      );
      let data = await result.json();
      if (result.status === 200 && data.length) {
        setCity(data[0].nom);
      }
    });
  };

  const plateSearch = () => {
    const response = api.plateSearch(registrationNumber);
    response.then((response) => {
      if (response.status === 200) {
        let car = {
          carId: 8354,
        };
        const vehicule = {
          ...car,
          PLATE: registrationNumber,
        };
        dataContext.setVehicule(vehicule);
        navigate(
          `/search?city=${city}&dp=${cityCode}&category=${category.name}&im=${registrationNumber}&carId=${car.carId}`
        );
      } else {
        setAlert_message(
          (message) => "Merci de vérifier votre immatriculation."
        );
        setAlert_type((alert_type) => "warning");
      }
    });
  };

  const __search = () => {
    if (!validateRegistrationNumber(registrationNumber)) {
      setAlert_message((message) => "Merci de vérifier votre immatriculation.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (category.length === 0) {
      setAlert_message((message) => "Merci de sélectionner une prestation.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else if (city === "") {
      setAlert_message((message) => "Merci de sélectionner votre ville.");
      setAlert_type((alert_type) => "warning");
      alertRef.current.show();
    } else {
      plateSearch();
    }
  };

  function __containsOnlyNumbers(str) {
    return /^\d+$/.test(str);
  }

  const searchCitie = async (value) => {
    let result = await fetch(
      `https://geo.api.gouv.fr/communes?nom=${value}&fields=departement&boost=population&limit=3`
    );
    return result.json();
  };

  const searchPostalCode = async (value) => {
    if (value.length === 5) {
      let result = await fetch(
        `https://apicarto.ign.fr/api/codes-postaux/communes/${value}`
      );

      if (result.status === 200) {
        let data = await result.json();
        let response = data.map((e) => ({
          nom: `${e.codePostal} - ${e.libelleAcheminement}`,
          value: `${e.libelleAcheminement}`,
          code: e.codePostal.slice(0, 2),
        }));
        setLocationError(false);
        return response;
      } else {
        setLocationError(true);
      }
    }
  };

  return (
    <div className="search-container">
      <Alert type={alert_type} message={alert_message} ref={alertRef} />
      <div className="form-search">
        <div className="first-input">
          <input
            type="text"
            className="input uppercase"
            value={registrationNumber}
            placeholder="Immatriculation"
            onChange={(e) => {
              console.log(e.target.value.toUpperCase());
              setRegistrationNumber(e.target.value.toUpperCase());
            }}
          />
          <span className="form-span">l'immatriculation de votre véhicule</span>
        </div>

        <div className="separator" />

        <Select
          isMulti={false}
          isSearchable={false}
          placeholder="Prestation"
          value={category}
          defaultValue={null}
          options={categories}
          className="react-select"
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={(selectedOption) => setCategory(selectedOption)}
        />

        <div className="separator" />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faLocationDot}
            size="lg"
            style={{ color: "#4f5052" }}
          />
          <div className={locationError ? "error" : ""}>
            <input
              type="text"
              className="input"
              placeholder="Ville ou Code Postal"
              value={userInput}
              onChange={async (e) => {
                setCitiesOptions((citiesOptions) => []);
                setUserInput(e.target.value);
                if (e.target.value.length) {
                  let result = __containsOnlyNumbers(e.target.value)
                    ? await searchPostalCode(e.target.value)
                    : await searchCitie(e.target.value);
                  if (result) {
                    setCitiesOptions(result);
                  }
                }
              }}
            />
            {locationError && (
              <span className="error">Code postal invalide</span>
            )}

            {citiesOptions.length > 0 && (
              <div className="citiesOptions">
                {citiesOptions.map((option, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      setUserInput(option?.nom);
                      setCityCode(
                        option?.value ? option.code : option.departement.code
                      );
                      setCity(option?.value ? option.value : option.nom);
                      setCitiesOptions((citiesOptions) => []);
                    }}
                  >
                    {option.nom}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>

        <span>
          <PositionIcon selected={location} onClick={() => __getPosition()} />
        </span>

        <button className="btn" onClick={() => __search()}>
          Rechercher
        </button>
      </div>
    </div>
  );
}

export default SearchBar;
