import React from "react";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './styles.css';

const SideBarItem = ({ item, active }) => {


    const location = useLocation();

    return (
        (!item?.child) ?
        <Link to={item.path} className={active ? 'sidebar-item-active' : 'sidebar-item'} >
            <img 
                src={item.icon}
                alt={`icon-${item.icon}`}
                className='sidebar-item-icon' />
            <span className='sidebar-item-label'>
                {item.title}
                <div className="sidebar-item-child">
                    {
                        (item?.child) ?
                            item.child.map((el) => 
                            <li>
                                <a href={el.path}> {el.title }</a>
                            </li>
                            )
                        : ''                                            
                    }
                </div>
            </span>
        </Link>
        : 
        <div  className={active ? 'sidebar-item-active' : 'sidebar-item'} >
            <img 
                src={item.icon}
                alt={`icon-${item.icon}`}
                className='sidebar-item-icon' />
            <span className='sidebar-item-label'>
                {item.title}
                <div className="sidebar-item-child">
                    {
                        (item?.child) ?
                            item.child.map((el, index) => 
                            <li key={index}>
                                <a className={(location.pathname === el.path) ? "active" : ''} href={el.path}> {el.title }</a>
                            </li>
                            )
                        : ''                                            
                    }
                </div>
            </span>
        </div>
    )
}
export default SideBarItem;
