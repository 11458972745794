// Get item value from local
export const getData = (key) => {
    try {
        const data = localStorage.getItem(key);
        return data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

// Get object value from local
export const getObject = (key) => {
    try {
        const jsonData = JSON.parse(localStorage.getItem(key));
        return jsonData;
    } catch (e) {
        console.log(e);
        return null;
    }
}

// Store item value in local
export const storeData = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        console.log(e);
    }
}

// Store object value in local
export const storeObject = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.log(e);
    }
}

// Remove item value from local
export const removeData = (key) => {
    localStorage.removeItem(key);
}