import React from "react";
import { useNavigate } from "react-router-dom";

import "../styles.css";
import logo from "../../../assets/images/primary-logo.png";

function WorkshopNavBar() {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="navbar">
        <img
          src={logo}
          className="logo"
          alt="cas-d'heure-logo"
          onClick={() => navigate("/")}
        />

        <div className="links">
          <button className="btn btn-right" onClick={() => navigate("/workshop")}>S'inscrire</button>
        </div>
      </div>

      {/* Responsive */}
      <div className="responsive-navbar">
        <img src={logo} alt="logo" className="logo" onClick={() => navigate("/")} />
      </div>
    </div>
  );
}

export default WorkshopNavBar;
