import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-modal";

import "./style.css";

import CalendarIcon from "../../assets/icons/CalendarIcon";
import Rdv from "./Rdv";

let date = new Date();
const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Aôut",
  "September",
  "October",
  "November",
  "Décember",
];

export default function Calendar({ appointments }) {
  const [week, setWeek] = useState([]);

  function weekNumber(date = new Date()) {
    var firstJanuary = new Date(date.getFullYear(), 0, 1);
    var dayNr = Math.ceil((date - firstJanuary) / (24 * 60 * 60 * 1000));
    var weekNr = Math.ceil((dayNr + firstJanuary.getDay()) / 7);
    return weekNr;
  }

  const arrange_week_days = () => {
    let week_days = [
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
      "dimanche",
    ];
    let today = new Date().toLocaleDateString("fr-Eu", { weekday: "long" });
    let index_of_today = week_days.indexOf(today);
    let newWeek = week_days.slice(index_of_today);
    week_days = week_days.filter((day) => !newWeek.includes(day));
    let complete_week = newWeek.concat(week_days);
    complete_week = complete_week.map((slot, index) => {
      return { day: slot, date: day_and_month(index) };
    });
    return complete_week;
  };

  const is_leap = () => {
    let year = new Date().getFullYear();
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  };
  const month_type = (month) => {
    const _30_Days_months = [4, 6, 9, 11];
    const _31_Days_months = [1, 3, 5, 7, 8, 10, 12];

    if (_30_Days_months.find((element) => element === month)) return 30;
    if (_31_Days_months.find((element) => element === month)) return 31;
    return is_leap ? 29 : 28;
  };

  const day_and_month = (number) => {
    let today = new Date().getDate();
    let this_month = new Date().getMonth() + 1;

    if (!number || number < 1) {
      return today + `.${this_month > 9 ? this_month : "0" + this_month}`;
    } else {
      switch (month_type(this_month)) {
        case 31:
          if (today + number > 31) {
            return (
              today +
              number -
              31 +
              `.${this_month + 1 > 9 ? this_month + 1 : "0" + (this_month + 1)}`
            );
          }
          return (
            today +
            number +
            `.${this_month > 9 ? this_month : "0" + this_month}`
          );

        case 30:
          if (today + number > 30) {
            return (
              today +
              number -
              30 +
              `.${this_month + 1 > 9 ? this_month + 1 : "0" + (this_month + 1)}`
            );
          }
          return (
            today +
            number +
            `.${this_month > 9 ? this_month : "0" + this_month}`
          );

        case 29:
          if (today + number > 29) {
            return (
              today +
              number -
              29 +
              `.${this_month + 1 > 9 ? this_month + 1 : "0" + (this_month + 1)}`
            );
          }
          return (
            today +
            number +
            `.${this_month > 9 ? this_month : "0" + this_month}`
          );

        case 28:
          if (today + number > 28) {
            return (
              today +
              number -
              28 +
              `.${this_month + 1 > 9 ? this_month + 1 : "0" + (this_month + 1)}`
            );
          }
          return (
            today +
            number +
            `.${this_month > 9 ? this_month : "0" + this_month}`
          );
      }
    }
  };

  const check_appoinment = (index, date, hour, appointment) => {
    let w = appointment.start_date_time;
    let appointment_date = new Date(w);
    let appointment_day =
      appointment_date.getDate().length > 1
        ? "0" + appointment_date.getDate()
        : appointment_date.getDate();
    let appointment_month = appointment_date.getUTCMonth() + 1;
    let appointment_hour = appointment_date.getUTCHours();
    let appointment_minute = appointment_date.getMinutes();
    if (`${date[3]}` + `${date[4]}` != appointment_month) return;
    if (`${date[0]}` + `${date[1]}` != appointment_day) return;
    if (hour != appointment_hour) return;
    if (appointment_minute > 20)
      return <Rdv half={true} appointment={appointment} index={index + 1} />;
    return <Rdv half={false} appointment={appointment} index={index + 1} />;
  };

  useEffect(() => {
    setWeek((week) => arrange_week_days());
    console.log({ appointments });
  }, []);

  return (
    <>
      <div className="calendar_infos">
        <p className="infos_today">
          Le
          {" " +
            date.getDate() +
            " " +
            months[date.getMonth()] +
            " " +
            date.getFullYear()}
        </p>
        <p className="infos_title">Mon agenda</p>
        <p className="infos_week_number">semaine {weekNumber()}</p>
      </div>
      <div className="calendar">
        <table>
          <thead>
            <tr>
              <th>
                <CalendarIcon />
              </th>
              {week.map((day, index) => {
                return (
                  <th key={index} className={index === 0 && "today"}>
                    <p>
                      {day.day}
                      <span> {day.date} </span>
                    </p>
                  </th>
                );
              })}
            </tr>
          </thead>

          {/* body */}
          <tbody>
            <tr>
              <td>08:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "8",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "8",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "8",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "8",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "8",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "8",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "8",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>09:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "9",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "9",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "9",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "9",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "9",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "9",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "9",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>10:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "10",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "10",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "10",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "10",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "10",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "10",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "10",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>11:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "11",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "11",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "11",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "11",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "11",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "11",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "11",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>12:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "12",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "12",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "12",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "12",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "12",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "12",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "12",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>13:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "13",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "13",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "13",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "13",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "13",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "13",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "13",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>14:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "14",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "14",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "14",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "14",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "14",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "14",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "14",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>15:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "15",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "15",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "15",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "15",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "15",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "15",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "15",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>16:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "16",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "16",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "16",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "16",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "16",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "16",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "16",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>17:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "17",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "17",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "17",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "17",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "17",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "17",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "17",
                      appointment
                    );
                  })}
              </td>
            </tr>
            <tr>
              <td>18:00</td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[0]?.date,
                      "18",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[1]?.date,
                      "18",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[2]?.date,
                      "18",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[3]?.date,
                      "18",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[4]?.date,
                      "18",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[5]?.date,
                      "18",
                      appointment
                    );
                  })}
              </td>
              <td>
                {week?.length > 0 &&
                  appointments?.map((appointment, index) => {
                    return check_appoinment(
                      index,
                      week[6]?.date,
                      "18",
                      appointment
                    );
                  })}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="rendez_vous_counter">
          <p>
            <span />
            RDV Confirmé
          </p>
          <p>
            <span />
            RDV à Confirmé
          </p>
          <p>
            <span />
            RDV Annulé
          </p>
        </div>
      </div>
    </>
  );
}