import React from "react";

import "./styles.css";
import { ApiConfig } from "../../../../infrastructure/services/env";

function CategoryCard({ category, activeCategory, onClick }) {
  return (
    <div
      onClick={onClick}
      className={
        activeCategory.mainId === null
          ? category.id === activeCategory.id && category.mainId === null
            ? "selected-category-card"
            : "category-card"
          : category.id === activeCategory.id &&
            category.mainId == activeCategory.mainId
          ? "selected-category-card"
          : "category-card"
      }
    >
      <img src={ApiConfig.IMAGES + category.icon} alt={category.name} />
      <p>{category.name}</p>
    </div>
  );
}

export default CategoryCard;
