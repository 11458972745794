// export const ApiConfig = {
//     API: 'http://localhost:3001',
//     IMAGES: 'http://localhost:3001/public/img/',
//     INVOICE: 'http://localhost:3001/public/factures/facture_n',
//     DELIVERYNOTE: 'http://localhost:3001/public/deliveryNote/bon_de_livraison_n',
//     PURCHASEORDER: 'http://localhost:3001/public/purchaseOrders/bon_de_commande_n',
// }

export const TecDocConfig = {
    country: "FR",
    endCustomerPassword: "CarsdHeure",
    endCustomerUsername: "CarsdHeure",
    lang: "fr",
    provider: "23277",
    api_key: "2BeBXg6Jxaysspi2XkRMgojuqc4J13XTmjVAAASvHjxnfi9rFigJ",
    url: "https://webservice.tecalliance.services/pegasus-3-0/services/TecdocToCatDLB.jsonEndpoint?api_key=2BeBXg6Jxaysspi2XkRMgojuqc4J13XTmjVAAASvHjxnfi9rFigJ"
}



export const ApiConfig = {
    API: 'https://api.carsdheure.fr',
    IMAGES: 'https://api.carsdheure.fr/public/img/',
    INVOICE: 'https://api.carsdheure.fr/public/factures/facture_n',
    DELIVERYNOTE: 'https://api.carsdheure.fr/public/deliveryNote/bon_de_livraison_n',
    PURCHASEORDER: 'https://api.carsdheure.fr/public/purchaseOrders/bon_de_commande_n',
}