import axios from 'axios';

import {ApiConfig} from "../env";
import axiosInstance from "./axiosInstance";
import { 
    recoverURL,
    changePasswordURL,
    loginURL,
    getCitiesURL,
    getCategoriesURL,
    searchURL,
    getBusinessProfileURL,
    getServicesByBusinessURL,
    getWorkshopsURL,
    validateWorkshopURL,
    getAllCitiesURL,
    createCityURL,
    updateCityURL,
    deleteCityURL,
    getAllOrdersURL,
    getProfileURL,
    updateProfileURL,
    workshopRegisterURL,
    addPrestationURL,
    myPrestationsURL,
    getFournisseursURL,
    getPiecesURL,
    getCategoriesByBusinessURL,
    getWorkStepsUrl,
    getSubCategoriesByBusinessURL,
    getAvailableSlotsURL,
    getBannerURL,
    bookURL,
    getMyAppointmentsURL,
    deletePrestationsURL,
    updatePrestationsURL,
    getMyBusinessAppointmentsURL,
    getMyBusinessProfileURL,
    myBusinessInfoURL,
    markDoneAppointmentURL,
    createAppointemntNotAuthURL,
    updateBusinessProfileLogoURL,
    updateBusinessProfileCoverURL,
    updateBusinessPrestationImageURL,
    updateMyBusinessProfileURL,
    driverRegisterURL,
    activateAccountURL,
    confirmOrderURL,
    getMyOrdersURL,
    getAllCheckoutsURL,
    acceptCheckoutURL,
    declineCheckoutURL,
    myCheckoutRequestsURL,
    isAbleCheckoutURL,
    requestCheckoutURL,
    getAvailableCategoriesURL,
    updateMyBusinessPaiementMethodURL,
    updateMyBusinessBankInfoURL,
    sendPaymentRequestURL,
    getPaymentRequestURL,
    getMyNotificationURL,
    notificationIsOpenedURL,
    sendMesaageUrl,
    confirmPieceURL,
    donePieceURL,
    workListNameUrl,
    getTecRamiTypeIdUrl,
    updateWorksURL,
    getWorksUrl,
    getWorkShopPriceURL,
    updatetWorkShopPriceURL
} from '../endpoints';

import { TecDocConfig } from "../env";


// Get Token
function getAuthToken () {
    // let token = getData("token");
    let token = localStorage.getItem('token');
    return token;
}

/* --- Shared --- */
const recover = (email) => axiosInstance.post(recoverURL, {
    email: email,
});
const changePassword = (token, new_password) => axiosInstance.post(changePasswordURL, {
    token: token,
    password: new_password,
},{
    headers: {
        'Authorization': `Bearer ${token}` 
    }
});

const login = (email, password) => axiosInstance.post(loginURL, {
    email: email,
    password: password
});
const activateAccount = (token) => axiosInstance.get(activateAccountURL, {
    headers: {
        'Authorization': `Bearer ${token}` 
    }
});
const getCities = () => axiosInstance.get(getCitiesURL);
const getCategories = () => axiosInstance.get(getCategoriesURL);




const getProfile = () => axiosInstance.get(getProfileURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const updateProfile = (user) => axiosInstance.put(updateProfileURL, user, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

/* --- Visitor --- */

const getWorksListName = (typeId) => axiosInstance.get(workListNameUrl + '?typeId=' +  typeId);
const getTecRamiTypeId = (tcdTypeId) => axiosInstance.get(getTecRamiTypeIdUrl + '?_tcdTypeId=' +  tcdTypeId);

const plateSearch = (plate) => axios({
    method: 'POST',
    url:  TecDocConfig.url,
    data: JSON.stringify({
        "getVehiclesByKeyNumberPlates": {
            "country": TecDocConfig.country,
            "details": true,
            "endCustomerPassword": TecDocConfig.endCustomerPassword,
            "endCustomerUsername": TecDocConfig.endCustomerUsername,
            "keySystemNumber": plate,
            "keySystemType": 50,
            "lang": "fr",
            "picture": false,
            "provider": TecDocConfig.provider
        }
    }),
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Api-Key':  TecDocConfig.api_key
    }
});






const getAvailableCategories = (CODMARQ, CODTYPE) => axiosInstance.get(getAvailableCategoriesURL + CODMARQ + '/' + CODTYPE);
const search = (params) => axiosInstance.post(searchURL, params);
const getBusinessProfile = (id) => axiosInstance.get(getBusinessProfileURL + id);
const getCategoriesByBusiness = (id, typeId) => axiosInstance.get(getCategoriesByBusinessURL + id + '/' + typeId);
const getWorkSteps = (id, typeId, korId, itemMpId) => axiosInstance.get(getWorkStepsUrl + id + '/' + typeId + '/' + korId + '/' + itemMpId);

const getSubCategoriesByBusiness = (workshop, category) => axiosInstance.get(getSubCategoriesByBusinessURL + workshop + '/' + category);
const getServicesByBusiness = (id) => axiosInstance.get(getServicesByBusinessURL + id);
const getFournisseurs = (category, cicerone_family_code, brand, model) => axiosInstance.get(getFournisseursURL + category + '/' + cicerone_family_code + '/' + brand + '/' + model);
const getPieces = (fournisseur, cicerone_family_code, cicerone_criteria_code, brand, model, year, alim) => axiosInstance.get(getPiecesURL + fournisseur + '/' + cicerone_family_code + '/' + cicerone_criteria_code + '/' + brand + '/' + model + '/' + year + '/' + alim);
const getAvailableSlots = (workshop, year, month, day) => axiosInstance.get(getAvailableSlotsURL + workshop + '/' + year + '/' + month + '/' + day)

/* --- Admin --- */

// Workshop Management
const getWorkshops = () => axiosInstance.get(getWorkshopsURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const validateWorkshop = (workshop) => axiosInstance.put(validateWorkshopURL, {
        business: workshop
    }, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
// Banners
const getBanners = () => axiosInstance.get(getBannerURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
// Cities
const getAllCities = () => axiosInstance.get(getAllCitiesURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const createCity = (city) => axios({
    method: 'post',
    url: ApiConfig.API + createCityURL,
    data: city,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const updateCity = (city) => axios({
    method: 'put',
    url: ApiConfig.API + updateCityURL,
    data: city,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const deleteCity = (city) => axiosInstance.put(deleteCityURL, {
        city: city
    }, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
// Orders
const getAllOrders = () => axiosInstance.get(getAllOrdersURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const confirmOrder = (order) => axiosInstance.put(confirmOrderURL, order, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
// Checkouts
const getAllCheckouts = () => axiosInstance.get(getAllCheckoutsURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const acceptCheckout = (checkout) => axiosInstance.put(acceptCheckoutURL, checkout, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const declineCheckout = (checkout) => axiosInstance.put(declineCheckoutURL, checkout, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const confirmPiece = (id) => axiosInstance.put(confirmPieceURL, {
    id : id
}, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const donePiece = (id) => axiosInstance.put(donePieceURL, {
    id : id
}, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const getWorkShopPrice = () => axiosInstance.get(getWorkShopPriceURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const updateWorkPrice = (workPrice) => axiosInstance.post(updatetWorkShopPriceURL, {
    workPrice: workPrice
}, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});



/* --- Workshop --- */
const workshopRegister = (user) => axiosInstance.post(workshopRegisterURL, user);


const getMyBusinessAppointments = () => axiosInstance.get(getMyBusinessAppointmentsURL , {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});


const updateStatusAppointment= (appointment) => axiosInstance.post(markDoneAppointmentURL , appointment, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});


const createAppointemntNotAuth= (appointment) => axiosInstance.post(createAppointemntNotAuthURL , appointment, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});


const getMyOrders = () => axiosInstance.get(getMyOrdersURL , {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const addPrestation = (service) => axiosInstance.post(addPrestationURL, service, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});


const updatePrestationImage = (image) => axios({
    method: 'put',
    url: ApiConfig.API + updateBusinessPrestationImageURL,
    data: image,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const updatePrestation = (service) => axiosInstance.put(updatePrestationsURL, service, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const deletePrestation = (service) => axiosInstance.delete(deletePrestationsURL + service, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const myPrestations = () => axiosInstance.get(myPrestationsURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const getMyBusinessProfile = () => axiosInstance.get(getMyBusinessProfileURL , {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const getMyBusinessInfos = () => axiosInstance.get(myBusinessInfoURL , {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const updateMyBusinessInfos = (profile) => axiosInstance.put(myBusinessInfoURL, profile, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const updateMyBusinessProfile = (profile) => axiosInstance.put(updateMyBusinessProfileURL, profile, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const updateMyBusinessPaiementMethod = (data) => axiosInstance.put(updateMyBusinessPaiementMethodURL, data, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const updateMyBusinessBankInfo = (data) => axiosInstance.put(updateMyBusinessBankInfoURL, data, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const updateBusinessProfileLogo = (logo) => axios({
    method: 'put',
    url: ApiConfig.API + updateBusinessProfileLogoURL,
    data: logo,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const updateBusinessProfileCover = (cover) => axios({
    method: 'put',
    url: ApiConfig.API + updateBusinessProfileCoverURL,
    data: cover,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const isAbleCheckout = () => axiosInstance.get(isAbleCheckoutURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const myCheckoutRequests = () => axiosInstance.get(myCheckoutRequestsURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const requestCheckout = (checkout) => axiosInstance.post(requestCheckoutURL, checkout, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});


const updateWorks = (categories) => axiosInstance.post(updateWorksURL, categories, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const getWorks = () => axiosInstance.get(getWorksUrl, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});


/* --- Driver --- */
const driverRegister = (user) => axiosInstance.post(driverRegisterURL, user);
const book = (appointment) => axiosInstance.post(bookURL, appointment, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const getMyAppointments = () => axiosInstance.get(getMyAppointmentsURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});
const getPaymentRequest = () => axiosInstance.get(getPaymentRequestURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const sendPaymentRequest = (data) => axiosInstance.post(sendPaymentRequestURL, data, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

const getMyNotification = () => axiosInstance.get(getMyNotificationURL, {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});


const notificationIsOpened = () => axiosInstance.put(notificationIsOpenedURL, [],  {
    headers: {
        'Authorization': `Bearer ${getAuthToken()}` 
    }
});

// Contact

const sendMesaage = (message) => axiosInstance.post(sendMesaageUrl, message);



const api = {
    getAuthToken,
    changePassword,
    login,
    recover,
    activateAccount,
    getCities,
    getCategories,
    getProfile,
    updateProfile,
    plateSearch,
    getAvailableCategories,
    search,
    getBusinessProfile,
    getCategoriesByBusiness,
    getWorkSteps,
    getSubCategoriesByBusiness,
    getServicesByBusiness,
    getFournisseurs,
    getPieces,
    getAvailableSlots,
    getBanners,

    getWorksListName,
    getTecRamiTypeId,
    // Admin
    getWorkshops,
    validateWorkshop,
    getAllCities,
    createCity,
    updateCity,
    deleteCity,
    getAllOrders,
    confirmOrder,
    getAllCheckouts,
    acceptCheckout,
    declineCheckout,
    confirmPiece,
    donePiece,
    // Workshop
    workshopRegister,
    getMyBusinessAppointments,
    updateStatusAppointment,
    createAppointemntNotAuth,
    getMyOrders,
    addPrestation,
    updatePrestation,
    deletePrestation,
    myPrestations,
    updatePrestationImage,
    getMyBusinessProfile,
    updateMyBusinessProfile,
    updateBusinessProfileLogo,
    updateBusinessProfileCover,
    isAbleCheckout,
    myCheckoutRequests,
    requestCheckout,
    updateMyBusinessPaiementMethod,
    updateMyBusinessBankInfo,
    getMyBusinessInfos,
    updateMyBusinessInfos,
    sendPaymentRequest,
    getPaymentRequest,
    getMyNotification,
    notificationIsOpened,
    updateWorks,
    getWorks,
    getWorkShopPrice,
    updateWorkPrice,
    // Driver
    driverRegister,
    book,
    getMyAppointments,
    sendMesaage
};

export default api;