/* --- Shared --- */

export const recoverURL = `users/rest-password`;
export const changePasswordURL = `/users/change-password`;
export const loginURL = `/users/login`;
export const activateAccountURL = `/users/activate`;
export const getCitiesURL = `/cities/activated`;
export const getCategoriesURL = `/appointments/categories`;
export const getProfileURL = `/users/account/my`;
export const updateProfileURL = `/users/account/update`;

/* --- Visitor --- */

export const workListNameUrl = '/api/worklistname';
export const getTecRamiTypeIdUrl = '/api/getTecRmiTypeId';

export const plateURL = `/appointments/plateSearch/`;
export const getAvailableCategoriesURL = `/appointments/availableCategories/`;
export const searchURL = `/prestations/search`;
export const getBusinessProfileURL = `users/workshop/`;
export const getCategoriesByBusinessURL = `/api/workShopWorksList/`;
export const getWorkStepsUrl = `/api/work-steps/`;
export const getSubCategoriesByBusinessURL = `/prestations/workshop/subCategories/`;
export const getServicesByBusinessURL = `/prestations/getByBusiness/`;
export const getFournisseursURL = `/appointments/fournisseurs/`;
export const getPiecesURL = `/appointments/pieces/`;
export const getAvailableSlotsURL = `/users/booking/availableSlots/`;
export const getBannerURL = `/banners/all/`;
export const sendMesaageUrl = `/contact/message`;


/* --- Admin --- */

export const getWorkshopsURL = `/users/getBusiness`;
export const validateWorkshopURL = `/users/validateBusiness`;

export const confirmPieceURL = `/pieceOrder/confirm`;
export const donePieceURL = `/pieceOrder/done`;

// Cities
export const getAllCitiesURL = `/cities/all`;
export const createCityURL = `/cities/new`;
export const updateCityURL = `/cities/editCity`;
export const deleteCityURL = `/cities/deleteCity`;
// Categories
export const getAllCategoriesURL = `/categories/all`;
export const createCategoryURL = `/categories/createCategory`;
export const updateCategoryURL = `/categories/updateCategory`;
export const deleteCategoryURL = `/categories/deleteCategory`;
// Orders
export const getAllOrdersURL = `/pieceOrder/all`;
export const confirmOrderURL = `/pieceOrder/confirm`;
// Checkouts
export const getAllCheckoutsURL = `/checkout/all`;
export const acceptCheckoutURL = `/checkout/accept`;
export const declineCheckoutURL = `/checkout/decline`;

/* --- Workshop --- */

export const workshopRegisterURL = `/users/business/signup`;
export const getMyBusinessAppointmentsURL = `/appointments/business/myAppointments`;
export const markDoneAppointmentURL = `/appointments/markDone`;
export const createAppointemntNotAuthURL = `/appointments/createNotAuth`;
export const getMyOrdersURL = `/pieceOrder/my`;
export const addPrestationURL = `/prestations/new`;
export const updatePrestationsURL = ``;
export const deletePrestationsURL = `/prestations/delete/`;
export const myPrestationsURL = `/prestations/my`;
export const updateBusinessPrestationImageURL = `/prestations/image/update`;
export const getMyBusinessProfileURL = `/users/business/info/my`;

export const myBusinessInfoURL = `/users/business/info/myBusinessInfo`;

export const sendPaymentRequestURL = `/users/business/payment/request`;
export const getPaymentRequestURL = `/users/business/payment/requests`;


export const getMyNotificationURL = `/notification/myNotifications`;
export const notificationIsOpenedURL = `/notification/isOpened`;

export const updateMyBusinessProfileURL = `/users/business/info/update`;
export const updateBusinessProfileCoverURL = `/users/business/cover/update`;
export const updateBusinessProfileLogoURL = `/users/business/logo/update`;
export const myCheckoutRequestsURL = `/checkout/myRequests`;
export const requestCheckoutURL = `/checkout/request`;
export const isAbleCheckoutURL = `/checkout/isAble`;
export const updateMyBusinessPaiementMethodURL = `/users/business/paiement/update`;
export const updateMyBusinessBankInfoURL = `/users/business/bankInfo/update`;

export const updateWorksURL = `/users/business/work/update`
export const getWorksUrl = `/users/business/work`

export const getWorkShopPriceURL = `/users/business/price`
export const updatetWorkShopPriceURL = `/users/business/price/update`

/* --- Driver --- */

export const driverRegisterURL = `/users/personal/signup`;
export const bookURL = `/appointments/book`;
export const getMyAppointmentsURL = `/appointments/myAppointments`;