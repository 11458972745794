import React from 'react';

import './styles.css';

function AccordionCard ({item, active, onClick}) {
    return(
        <div 
            onClick={() => onClick(item.id)}
            className={active === item.id ? 'selected-accordion' : 'accordion'}>                 
                <div className='accordion-header'>
                    <h2>{item.id}. {item.title}</h2>
                    {active === item.id ?
                        <h2>-</h2>
                    :
                        <h2>+</h2>
                    }
                </div>
                {active === item.id && <p>{item.description}</p>}
        </div>
    )
}

export default AccordionCard;