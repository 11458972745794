import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import "./styles.css";
import driver_menu from "../../../constants/driver-menu";
import SideBar from "../../../components/SideBar";
import Appointments from "./Appointments";
import Profile from "../../Profile";
import Payment from "./payment";

function Dashboard() {
  const location = useLocation();

  const [active, setActive] = useState(1);

  useEffect(() => {
    driver_menu.forEach((element) => {
      if (location.pathname === element.path) {
        setActive(element.id);
      }
    });
  }, []);

  const __navigate = (id) => {
    setActive(id);
  };

  return (
    <div className="dashboard-container">
      <SideBar menu={driver_menu} active={active} navigate={__navigate} />

      <div className="dashboard-body">
        <Routes>
          <Route path="*" element={<Appointments />} />
          <Route exact path="/appointments" element={<Appointments />} />
          <Route exact path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;
